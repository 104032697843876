import { useGetProjectsQuery } from '../../api/projects/projects.api'
import { Project } from '../../api/projects/projects.def'
import { useMemo } from 'react'

export const useGetProject = (projectId: number) => {
    const { data: projects } = useGetProjectsQuery({})

    const project = useMemo(() => {
        if ([!projectId, !projects, !projects?.data].includes(true)) return null

        return projects?.data.find((project: Project) => project.id === projectId) ?? null
    }, [projectId, projects])

    return project
}
