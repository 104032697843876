import { ArrowBack } from '@mui/icons-material'
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp'
import { Box, CircularProgress, Drawer, Modal, Typography } from '@mui/material'
import { BodyScrollEndEvent, GetRowIdParams, RowClassParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import isEqual from 'lodash/isEqual'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { useGetHeadersCPGQuery, useSetTagMutation } from '../../api/ksg/ksg.api'
import { Work, WorkOrWorkPosition } from '../../api/ksg/ksg.def'
import { Project } from '../../api/projects/projects.def'
import { useDeleteWorkMutation } from '../../api/works/works.api'
import AddLevelKsg from '../../components/AddLevelKsg/AddLevelKsg'
import CheckboxPopover from '../../components/CheckboxPopover/CheckboxPopover'
import GanttRelations from '../../components/GanttRelations/GanttRelations'
import { useToggleSearchParam } from '../../hooks/useToggleSearchParam'
import { useVerticalPagination } from '../../hooks/useVerticalPagination'
import { useGetProject } from '../../layouts/TableLayout/KsgLayout.model'
import EmptyPlaceholder from '../../shared/components/EmptyPlaceholder/EmptyPlaceholder'
import { IntegrationWithOfferSearchParam } from '../../shared/constants/integration-with-offer'
import useProjectUploadProgress from '../../shared/hooks/useProjectUploadProgress'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { modalStyles } from '../../shared/utils'
import {
    agGridKsgSelector,
    agGridListMode,
    onCheckTagKsgAg,
    onDeleteRowKsgAg,
} from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { IGetParamsKSG, getWorksAgGrid, getWorksAgGridOrphan } from '../../store/slices/agGridKsgMsgSlices/ksgThunks'
import { authSelector } from '../../store/slices/authSlice'
import { drawersSelector, setAgGrid, toggleUploadKsg, toggleUploadProgress } from '../../store/slices/drawersSlice'
import { filtersSelector, setWorkGroupSpecialFilter } from '../../store/slices/filtersSlice'
import { ksgTemplatesSelector } from '../../store/slices/ksgTemplatesSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { getSettings } from '../../store/slices/settings/settingsViewAgGrid'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { KSGColDefs } from '../AgGrid/AgGrid.colDef'
import { submitCellData, transformWorkPositionToWork } from '../AgGrid/AgGrid.service'
import { hasParam } from '../KsgTable/Ksg.service'
import { FlexColumnWrapper, FlexRowWrapper } from '../NewExecutorView/components/components.styles'
import { req } from '../WorkManagment/api/api'
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { IAutocompleteOption } from '../WorkManagment/components/CellEditors/CellEditor/CellEditor.def'
import { GanttTable } from './DHTGantOnlyTable'
import './configTwoGrids/styles.scss'
import { gantt } from './dhtmlxgantt'

/* константа лимита для запросов к базе */
export const LIMIT = 1000

export default function DHTMLXGanttWithAgGrid() {
    const [hiddenRowsIds, setHiddenRowsIds] = useImmer<{ id: number; code: string }[]>([])
    const [mutationsLoading, setMutationsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isOpenAddLevel, setIsOpenAddLevel] = useState(false)
    const [addLevelData, setAddLevelData] = useState<{
        id: number | null
        index: number
        level: number | null
    }>({
        id: 0,
        index: 0,
        level: null,
    })
    const [relationsOpened, setRelationsOpened] = useState<boolean>(false)
    const [relationData, setRelationData] = useState<null | {
        workName: string
        startDate: string | null
        endDate: string | null
        id: number
    }>(null)
    const { pageName } = useTypedSelector(projectsSelector)
    // const { AgGrid } = useTypedSelector(drawersSelector)
    const { filters, queryTerm } = useTypedSelector(filtersSelector)
    const { templateCpgState } = useTypedSelector(ksgTemplatesSelector)
    const { worksList, isKSGUpdatedByExcel, isKSGUploaded } = useTypedSelector(agGridKsgSelector)
    const { token } = useTypedSelector(authSelector)
    const { profile } = useTypedSelector(profileSelector)
    const navigate = useNavigate()
    const listMode = useTypedSelector(agGridListMode)
    const location = useLocation()

    /* Данные с стора для базового плана */
    const { basicPlan, costDoneMode } = useTypedSelector(getSettings)

    const { syncModuleData, triggerIfBackupRestored, uploadKsg, updateKSG, AgGrid } = useTypedSelector(drawersSelector)

    const dispatch = useAppDispatch()

    const gridRef = useRef<AgGridReact>(null)

    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current))
    }

    const { projectId } = useParams()
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam)
    const project = useGetProject(Number(projectId))
    const isOfferActive = project?.isImportFromOffer && isActive

    const [urlSearchParams, setUrlSearchParams] = useSearchParams()

    const [projectList, setProjectList] = useState<IAutocompleteOption[]>([])
    const [activeProjectID, setActiveProjectID] = useState<number>(Number(projectId))

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')
    const { t: tCPG } = useTranslation('cpg')

    const [indexClick, setIndexClick] = useImmer<{ month: number; work: number } | null>(null)

    const [anchorElTemplateTable, setAnchorElTemplateTable] = useState<null | HTMLElement>(null)

    const [deleteReq] = useDeleteWorkMutation()
    const [setTagReq] = useSetTagMutation()

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    })

    const isVisibleGantt = gridRef.current && urlSearchParams.has('g')

    const isKSGCreated = !!headersCPG?.data?.isCreatedKSG

    const { activeTask, setTriggerUpdateStatus, result } = useProjectUploadProgress({
        supressOnClose: !isKSGCreated,
        supressWatchBtn: !isKSGCreated,
    })

    useEffect(() => {
        !activeTask && setTriggerUpdateStatus(true)
    }, [activeTask, headersCPG?.data])

    useEffect(() => {
        setTriggerUpdateStatus(!uploadKsg)
    }, [uploadKsg])

    useEffect(() => {
        setTriggerUpdateStatus(!updateKSG)
    }, [updateKSG])

    // useEffect(() => {
    //     if (!result && !!activeTask && headersCPG?.data) {
    //         dispatch(
    //             toggleUploadProgress({
    //                 openDialog: !!activeTask,
    //                 supressOnClose: !headersCPG?.data.isCreatedKSG,
    //                 supressWatchBtn: !headersCPG?.data.isCreatedKSG,
    //             })
    //         )
    //     }
    //     if (!!result) {
    //         dispatch(
    //             toggleUploadProgress({
    //                 openDialog: false,
    //                 supressOnClose: false,
    //                 supressWatchBtn: false,
    //             })
    //         )
    //         dispatch(onKSGUpload())
    //     }
    // }, [activeTask, result, headersCPG?.data])

    useEffect(() => {
        return () => {
            setTriggerUpdateStatus(false)
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            )
            dispatch(setAgGrid(null))
        }
    }, [location.pathname, projectId])

    useEffect(() => {
        mutationsLoading && console.info('loading')
    }, [mutationsLoading])

    /* возвращает сразу массив данных учитывая фильтры либо null */
    const [filteredWorks, setFilterWorks] = useState<Work[] | null>([])

    const rowData = useMemo(() => {
        if (!isOfferActive || listMode) return filteredWorks

        return filteredWorks?.flatMap((item) => {
            if (!item.workPosition) return item

            if (!hiddenRowsIds.some((v) => isEqual(v, { id: item.id, code: item.code }))) {
                return item
            }

            return [item, ...item?.workPosition?.map((position) => transformWorkPositionToWork(position))]
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredWorks, listMode, isOfferActive]) as WorkOrWorkPosition[]

    useEffect(() => {
        setFilterWorks(() => {
            if (worksList) {
                const filtArr = worksList?.data.reduce((acc: Work[], prev) => {
                    const idx = hiddenRowsIds.every((filItem) => {
                        if (prev.code === filItem.code) {
                            return true
                        }

                        return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v))
                    })

                    return idx ? [...acc, prev] : acc
                }, [])
                // if (!filtArr?.length || !worksList.data.length) {
                //     gridRef.current?.api?.showNoRowsOverlay()
                // }
                return filtArr?.length ? filtArr : worksList.data
            } else {
                return null
            }
        })
        return () => {
            setFilterWorks(null)
        }
    }, [hiddenRowsIds, urlSearchParams, worksList])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getHiddenIds = (): { id: number; code: string }[] | undefined => {
        return worksList?.data
            .filter((work) => !filteredWorks?.find((v) => v.id === work.id))
            ?.map((w) => {
                return {
                    id: w.id,
                    code: w.code,
                }
            })
    }

    // PAGINATION
    /**
     * устанавливается нужный экшен по условию, для диспатча события внутри useVerticalPagination
     */
    const getData = useCallback(
        (params: IGetParamsKSG) => {
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid
            gridRef.current?.api?.showLoadingOverlay()
            return sendingFunc(params)
        },
        [listMode]
    )

    /**
     * общее количество записей в БД
     */
    const [totalRows, setTotalRows] = useState(0)

    /**
     * Эффект следит за изменением projectId
     * и используя Api AgGrid управляет оверлэем таблицы
     * если workList не null отрисовывает таблицу,
     * в ином случае показывает что идет загрузка
     */
    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total)
            // worksList?.activeUpload && setTriggerUpdateStatus(true)
        }
    }, [worksList])

    /**
     *  добавил в массив resetToInitialDeps зависимость от projectId
     *  чтобы при смене проекта был перезапрос за данными
     */

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!)
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow

    const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsKSG>({
        initialParams: {
            limit: lastRenderedRow ? Math.ceil(lastRenderedRow / LIMIT) * LIMIT : LIMIT,
            offset: 0,
            id: Number(projectId),
        },
        sendFn: getData,
        thenFn: () => {
            gridRef.current?.api?.hideOverlay()
        },
        catchFn: () => {
            gridRef.current?.api?.hideOverlay()
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            filters,
            listMode,
            projectId,
            isKSGUpdatedByExcel,
            isKSGUploaded,
            syncModuleData,
            triggerIfBackupRestored,
        ],
    })

    const onBodyScrollEnd = (e: BodyScrollEndEvent<any, any>) => {
        if (worksList?.total === worksList?.data?.length) return
        scrollHandlerFoAG(e)
    }

    //==========================

    const onDeleteRow = (id: number) => {
        deleteReq({
            id: Number(projectId),
            workId: id,
        })
            .unwrap()
            .then((res) => {
                enqueueSnackbar(t('work_deleted'), {
                    variant: 'success',
                })

                dispatch(onDeleteRowKsgAg({ id, works: res.data }))
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
            })
    }

    const checkTag = (bool: boolean, workId: number | undefined) => {
        dispatch(onCheckTagKsgAg({ bool, workId }))

        if (!workId) return

        setTagReq({
            id: Number(projectId),
            workId: workId,
            body: {
                tag: bool,
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('tag_set'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    // /**
    //  * Эффект следит за сменой проекта
    //  * при смене очищаются workList
    //  * и очищаются примененные фильтры
    //  */
    // useEffect(() => {
    //     // if (!projectId) {
    //         dispatch(refreshFilters())
    //         dispatch(clearWorks())
    //         dispatch(changeListMode(false))
    //     // }
    //     // }
    //     //eslint-disable-next-line
    // }, [projectId])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params?.data?.id
    }, [])

    const locationSearch = location.search

    // TODO:
    // 1. Удалить
    /**
     * TODO
     * Старая функция перенесенная со старой таблицы ганта
     * Требует пересмотра и прокачки
     */
    // eslint-disable-next-line
    const handleClickChart = useCallback(
        (idx: number, work: Work) => {
            if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINE_GANT_SMR')) {
                return
            }
            const newWork = { ...work, monthlyCharts: [...work.monthlyCharts] }
            if (work.volumeTotal === null && profile.role !== 'client' && hasParam('g', locationSearch)) {
                return
            }
            if (indexClick === null) {
                setIndexClick({ month: idx, work: work.id })
            } else {
                setIndexClick(null)
                const min = idx < indexClick.month ? idx : indexClick.month
                const max = idx > indexClick.month ? idx : indexClick.month
                for (let i = min; i <= max; i++) {
                    setIndexClick(null)
                    // if (!newWork.monthlyCharts) return
                    if (newWork.monthlyCharts[indexClick.month].drawPlan || newWork.monthlyCharts[idx].drawPlan) {
                        setFilterWorks((prevState) => {
                            if (!prevState) return prevState
                            return prevState.map((_) =>
                                _.id === work.id
                                    ? {
                                          ..._,
                                          monthlyCharts: _.monthlyCharts.map((month, monthIndex) =>
                                              monthIndex === i ? { ...month, drawPlan: false } : month
                                          ),
                                      }
                                    : _
                            )
                        })
                    } else {
                        setFilterWorks((prevState) => {
                            if (!prevState) return prevState
                            return prevState.map((_) =>
                                _.id === work.id
                                    ? {
                                          ..._,
                                          monthlyCharts: _.monthlyCharts.map((month, monthIndex) =>
                                              monthIndex === i ? { ...month, drawPlan: true } : month
                                          ),
                                      }
                                    : _
                            )
                        })
                    }
                }
            }
        },
        //eslint-disable-next-line
        [indexClick, profile.role, locationSearch]
    )
    const getRowClass = useCallback((params: RowClassParams<Work, any>) => {
        const stylesByLevel = {
            0: 'level_zero',
            1: 'level_one',
            2: 'level_two',
            3: 'level_three',
            4: 'level_four',
            5: 'level_five',
            6: 'level_six',
            7: 'level_seven',
        }

        return params.data?.hasChildren
            ? stylesByLevel[params.data?.level as keyof typeof stylesByLevel]
            : stylesByLevel[7]
    }, [])

    const [changeableArray, setChangeableArray] = useImmer<
        { month: number; year: number; fill: boolean; fact: number | null }[] | undefined
    >([])

    useEffect(() => {
        headersCPG.refetch()
    }, [isKSGUploaded])

    useEffect(() => {
        req.get(`/projects/list`, { limit: 9999, offset: 0 }).then(({ data }) => {
            const projectListOptions = data.data.map((project: Project) => ({
                label: project.title,
                value: project.id,
            }))
            setProjectList(() => projectListOptions)
            setActiveProjectID(
                () =>
                    projectListOptions?.find((project: IAutocompleteOption) => project.value === Number(projectId))
                        .value
            )
        })
    }, [])

    if (!headersCPG.data?.isCreatedKSG) {
        if (headersCPG.isFetching) {
            return <CircularProgress />
        }
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_SMR')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText="Назад"
                />
            )
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}, у вас еще нет целевого план-графика реализации проекта.
Давайте создадим ваш первый КСГ`}
                onClick={() => dispatch(toggleUploadKsg())}
                icon={<DownloadingSharpIcon />}
                buttonText="загрузить"
            />
        )
    }

    return (
        <>
            <Drawer anchor="right" open={isOpenAddLevel} onClose={() => setIsOpenAddLevel(false)}>
                <AddLevelKsg addLevelData={addLevelData} setIsOpenAddLevel={setIsOpenAddLevel} />
            </Drawer>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ ...modalStyles, width: 373 }}>
                    <CheckboxPopover
                        showEmptyParams={{
                            show: queryTerm === 'workGroup',
                            checked: filters.workGroupSpecial,
                            onChange: (checked: boolean) => dispatch(setWorkGroupSpecialFilter(checked)),
                        }}
                    />
                </Box>
            </Modal>
            <Modal
                open={relationsOpened}
                onClose={() => {
                    setRelationsOpened(false)
                    setRelationData(null)
                }}
            >
                <span>
                    <GanttRelations
                        setRelationsOpened={setRelationsOpened}
                        increaseRelationsCount={() => {}}
                        initialWorkData={relationData!}
                    />
                </span>
            </Modal>

            <FlexColumnWrapper>
                <FlexRowWrapper
                    className="table-wrapper"
                    style={
                        {
                            '--ag-body-vertical-scroll-display': `${isVisibleGantt ? 'none' : 'flex'}`,
                        } as React.CSSProperties
                    }
                    height={'100%'}
                    width={'100%'}
                    gap={0}
                    p={2}
                >
                    <div
                        className="ag-theme-alpine"
                        style={{ flex: '1 1 0', height: '100%', paddingRight: '0.5rem' }}
                        onClickCapture={(e) => {
                            if (!!activeTask) {
                                e.stopPropagation()
                                e.preventDefault()
                            }
                        }}
                    >
                        {gantt && (
                            <AgGridReact
                                ref={gridRef}
                                onBodyScrollEnd={onBodyScrollEnd}
                                getRowId={getRowId}
                                getRowClass={getRowClass}
                                rowData={rowData}
                                columnDefs={KSGColDefs({
                                    projectId: projectId as string,
                                    rowData: rowData,
                                    setAddLevelData: setAddLevelData,
                                    setIsOpenAddLevel: setIsOpenAddLevel,
                                    location: location,
                                    profile: profile,
                                    onDeleteRow: onDeleteRow,
                                    checkTag: checkTag,
                                    hiddenRowsIds: hiddenRowsIds,
                                    setHiddenRowsIds: setHiddenRowsIds,
                                    gridRef: gridRef,
                                    changeableArray: changeableArray,
                                    setChangeableArray: setChangeableArray,
                                    setRelationsOpened: setRelationsOpened,
                                    setRelationData: setRelationData,
                                    urlSearchParams: urlSearchParams,
                                    anchorElTemplateTable: anchorElTemplateTable,
                                    setAnchorElTemplateTable: setAnchorElTemplateTable,
                                    indexClick: indexClick,
                                    setIndexClick: setIndexClick,
                                    dispatch: dispatch,
                                    headersCPG: headersCPG,
                                    basicPlan: basicPlan,
                                    costDoneMode: costDoneMode,
                                    setMutationsLoading: setMutationsLoading,
                                    setOpenModal: setOpenModal,
                                    handleClickChart: handleClickChart,
                                    isOfferActive: isOfferActive,
                                })}
                                {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                                onCellValueChanged={(params) => {
                                    submitCellData({
                                        params: params,
                                        projectID: projectId as string,
                                        enqueueSnackbar: enqueueSnackbar,
                                        translate: tCPG,
                                        dispatch: dispatch,
                                    })
                                }}
                                onCellClicked={(params) => {
                                    params.event?.preventDefault()
                                    params.event?.stopImmediatePropagation()
                                }}
                                {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') ||
                                    !!activeTask) && {
                                    suppressClickEdit: true,
                                })}
                                onBodyScroll={({ direction, left, top }) => {
                                    gantt.scrollTo(null, top)
                                }}

                                // suppressAsyncEvents
                                // defaultColDef={{
                                //     onCellClicked(event) {
                                //         event.event?.stopPropagation()
                                //         event.event?.preventDefault()
                                //         console.log(event.event)
                                //     },
                                // }}
                            />
                        )}
                    </div>
                    {isVisibleGantt && <GanttTable filteredWorks={filteredWorks} grid={gridRef.current} />}
                </FlexRowWrapper>
            </FlexColumnWrapper>
        </>
    )
}
