import { FlexRowWrapper } from '../../../../../pages/NewExecutorView/components/components.styles'
import { CloudSyncIcon } from '../../../../../shared/SVG/Svg'
import { ISyncStatusProps } from './ControlPanel.def'
import { StatusIconContainer, StatusText } from './ControlPanel.styles'
import React from 'react'

export const SyncStatus: React.FC<ISyncStatusProps> = () => {
    return (
        <FlexRowWrapper gap={1}>
            <StatusIconContainer>
                <CloudSyncIcon />
            </StatusIconContainer>
            <StatusText>Синхронизован с Offer</StatusText>
        </FlexRowWrapper>
    )
}
