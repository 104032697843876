import { HtmlTooltip } from '../../../shared/components/HtmlTooltip'
import React, { useCallback } from 'react'
import { queryTermsType } from '../../../api/filters/filters.api'
import g from '../../../shared/stylesheets/TableGrid.module.scss'
import { FaFilter } from 'react-icons/fa'
import { filtersSelector, levels, setQueryTerm } from '../../../store/slices/filtersSlice'
import { useAppDispatch, useTypedSelector } from '../../../store/store'

export const HeaderNameEl = ({ width, name }: { width?: number; name: string }) => {
    return (
        <div
            style={{
                width: (width || 32) - 32,
            }}
            className="text_ellipsis"
        >
            <HtmlTooltip title={name}>
                <p>{name}</p>
            </HtmlTooltip>
        </div>
    )
}

export const HeaderFilter = ({
    width,
    name,
    term,
    onFilterClick,
}: {
    width?: number
    name: string
    term: queryTermsType
    onFilterClick: () => void
}) => {
    const dispatch = useAppDispatch()

    const { filters } = useTypedSelector(filtersSelector)

    const isFilterApplied = useCallback(
        (queryTerm: queryTermsType) => {
            let arrToCheck: 1[] = []

            Object.keys(filters[queryTerm]).forEach((key) => {
                filters[queryTerm][key as levels]?.forEach((t) => {
                    if (!!t.length) {
                        arrToCheck.push(1)
                    }
                })
            })

            return arrToCheck.some((v) => v) || (filters.workGroupSpecial && queryTerm === "workGroup")
        },
        [filters]
    )
    return (
        <div
            style={{
                width: (width || 32) - 32,
            }}
        >
            <p>{name}</p>
            <span
                onClick={() => {
                    onFilterClick()
                    dispatch(setQueryTerm(term))
                }}
                className={g.abs_right_center + ' pointer'}
            >
                <FaFilter color={isFilterApplied(term) ? '#6FCCBC' : '#fff'} />
            </span>
        </div>
    )
}
