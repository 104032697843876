import { ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { monthsList } from '../../layouts/TableLayout/components/ChangeMonthTableTop'
import { dailyChart, IGetGantDependenciesRes, WorkMsg } from '../../api/msg/msg.def'
import { store } from '../../store/store'
import { ReactElement } from 'react'
import { Work } from '../../api/ksg/ksg.def'
import { req } from '../WorkManagment/api/api'
import { Updater } from 'use-immer'
import { IGetTargetRequestFuncArgs, ISubmitParams } from '../../shared/def'
import { onSubmitRejected, onSubmitSuccess } from '../../shared/utils'
import { AgGridReact } from 'ag-grid-react'
import {
    createPathArrowV2,
    getDateWithLeadingZero,
    getGantDepId,
    getIndicatorCoords,
} from './components/GantCell/GantCell.service'
import { onEditAgMSG } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'

export const CANVAS_ID = 'gantRelationsCanvas'

export const getHeadersMonthsMsg = (
    daysArr: dailyChart[] | undefined,
    callback: (data: ICellRendererParams<WorkMsg, any, any>, index: number) => ReactElement
) => {
    const header = monthsList[store.getState().monthMsg.month] + ', ' + store.getState().monthMsg.year

    const finalObj = {
        headerName: header,
        suppressMovable: true,
        children: [] as ColDef[],
    }
    daysArr?.forEach((obj, index) => {
        finalObj.children.push({
            field: 'dailyCharts',
            width: 120,
            headerName: index + 1 + '',
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0',
                    }}
                >
                    {data.displayName}
                </div>
            ),
            suppressMovable: true,
            cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => callback(data, index),
            headerClass: (params) => {
                return index + 1 === new Date().getDate() && store.getState().monthMsg.month === new Date().getMonth()
                    ? 'ag-header-current-chart'
                    : ''
            },
        })
    })
    return finalObj
}

export function getTargetRequestByColDefFieldName({ params, projectID }: IGetTargetRequestFuncArgs) {
    switch (params.colDef.field) {
        case 'operationalStartDate':
        case 'operationalEndDate':
            return req.patch(`/projects/${projectID}/works/${params.data?.id}/update`, {
                [params.colDef.field as string]: params.newValue,
            })
        case 'executorName':
            return req.post(
                `/projects/${projectID}/works/msg/set-executor?year=${params.data.year}&month=${params.data.month}`,
                {
                    contractorCompany: params.newValue?.company || null,
                    executorId: params.newValue?.employeeID || null,
                    workID: params.data.id,
                }
            )
        case 'brigade':
            return req.post(
                `/projects/${projectID}/works/msg/set-brigade?year=${params.data.year}&month=${params.data.month}`,
                {
                    brigadeId: params.newValue?.value || null,
                    workID: params.data.id,
                }
            )
        default:
            return new Promise<any>((res, rej) => res({ data: { data: [] } }))
    }
}

export function updateRowDataMSG(data: WorkMsg[]) {
    store.dispatch(onEditAgMSG(data))
}

export function submitCellData(
    args: ISubmitParams
) {
    const { params, projectID } = args
    getTargetRequestByColDefFieldName({
        params: params,
        projectID: projectID as string,
    })
        .then(({ data }) => {
            const excludeRowUpdateFields = ['operationalStartDate', 'operationalEndDate']
            // params.api.applyTransaction({ update: [data.data] })
            onSubmitSuccess(args)
            if (excludeRowUpdateFields.includes(params.colDef?.field as string)) {
                return
            }
            updateRowDataMSG(data.data)
        })
        .catch((e) => {
            onSubmitRejected({
                error: e,
                ...args,
            })
        })
}

export function createGantCanvasV2(canvasID: string, width: number, height: number) {
    const viewPortNode = document.querySelector('.ag-center-cols-container')

    const canvasWrapper = document.createElement('div')
    canvasWrapper.setAttribute('id', canvasID + 'wrapper')
    canvasWrapper.style.position = 'absolute'
    canvasWrapper.style.right = '0px'
    canvasWrapper.style.top = '0px'
    canvasWrapper.style.pointerEvents = 'none'
    canvasWrapper.style.zIndex = '3'

    const canvasSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    canvasSVG.setAttribute('id', canvasID)
    canvasSVG.setAttribute('width', width + '')
    canvasSVG.setAttribute('height', height.toString())
    canvasSVG.style.zIndex = '3'

    canvasWrapper.appendChild(canvasSVG)

    viewPortNode?.append(canvasWrapper)
}

export function drawDefaultArrowsFromServer(AgGrid: AgGridReact, deps: IGetGantDependenciesRes['data']) {
    deps.map((dep) => {
        dep.bonds.forEach((bond) => {
            const workFromID = bond.key.workId
            const workFromRowIndex = AgGrid.api.getRowNode(workFromID.toString())?.rowIndex || 0
            const workFromChartIndex = Number(bond.key.date.split('.')[0]) || 0 - 1
            const workFromSide = bond.key.side
            bond.to.map((workTo) => {
                const workToID = workTo.workId
                const workToRowIndex = AgGrid.api.getRowNode(workToID.toString())?.rowIndex || 0
                const workToChartIndex = Number(workTo.date.split('.')[0]) || 0 - 1

                workFromRowIndex &&
                    workToRowIndex &&
                    createPathArrowV2({
                        pathID: getGantDepId({
                            workFromID: workFromID.toString(),
                            workToID: workTo?.workId?.toString() || '',
                            workFromDate: bond.key.date,
                            workToDate: workTo.date,
                        }),
                        from: getIndicatorCoords(workFromRowIndex, workFromChartIndex, workFromSide),
                        to: getIndicatorCoords(workToRowIndex, workToChartIndex, 'start'),
                        color: AgGrid.api?.getRowNode(workToID.toString())?.data?.brigade?.color,
                        canvasID: CANVAS_ID,
                    })
            })
        })
    })
}
