import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Rect = styled('div')<{ backgroundColor: string }>`
    border-radius: 16px;
    width: 32px;
    height: 16px;
    background-color: ${(props) => props.backgroundColor};
`

export const Text = styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2b3648;
`
