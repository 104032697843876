import s from '../AddLevelKsg.module.scss'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { ru } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'
import { calendarStylesAddWorkAg } from '../AddLevel.service'
import { FormError } from '../../../pages/Projects/component/FormikFormC'
import React from 'react'

export default function DateLine({
    errorTitle,
    touched,
    title,
    onChange,
    onBlur,
    value,
}: {
    errorTitle?: string
    touched?: boolean
    title: string
    onChange: (date: Date | null) => void
    onBlur: () => void
    value: Date | null
}) {
    return (
        <div className={s.input_line}>
            <p>{title}:</p>
            <div>
                <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        value={value}
                        onChange={onChange}
                        inputFormat="dd.MM.yyyy"
                        renderInput={(params) => {
                            if (params.inputProps) params.inputProps.placeholder = 'дд.мм.гггг'

                            return (
                                <TextField
                                    data-testid={'dateStart'}
                                    {...params}
                                    onBlur={onBlur}
                                    error={!!errorTitle?.length}
                                    sx={calendarStylesAddWorkAg}
                                />
                            )
                        }}
                    />
                </LocalizationProvider>
                <FormError isTouched={touched} error={errorTitle} right />
            </div>
        </div>
    )
}
