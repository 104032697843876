import { IConfigureTemplateListBtnProps } from './ConfigureAgGridColDefTemplate.def'
import { ColDef, ColumnState } from 'ag-grid-community'

/**
 * На основе AgGrid columnsDef собирает массив для списка доступных колонок, игнорируя колонки без colId,
 * или где colId число в строке по типу "10" и т.п.
 * @param name - имя свойства объекта
 */

function isPartialyIncluded(array: string[], searchValue: string) {
    if (array.length === 0) return false
    return (
        array?.filter(
            (arrayItem) =>
                arrayItem?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                searchValue?.toLowerCase().includes(arrayItem?.toLowerCase())
        )?.length > 0
    )
}

export function getFilteredColumns(
    columns: Array<ColDef | ColumnState | undefined>,
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    if (!columns) return []
    return (
        columns?.filter((column) => {
            if (excludeColIds && isPartialyIncluded(excludeColIds, (column?.colId as string) || '')) return false
            return !!column?.colId && isNaN(Number(column?.colId))
        }) || []
    )
}

export function getCurrentColDefsState(
    AgGrid: IConfigureTemplateListBtnProps['AgGrid'],
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    // console.log(AgGrid)
    if (!AgGrid) return []
    return getFilteredColumns((AgGrid?.api?.getColumnDefs() as ColDef[]) || [], excludeColIds)
}

export function getFilteredColumnsState(
    AgGrid: IConfigureTemplateListBtnProps['AgGrid'],
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    if (!AgGrid) return []
    return getFilteredColumns(AgGrid?.columnApi?.getColumnState(), excludeColIds) || []
}
