import AddIcon from '@mui/icons-material/Add'
import { TabContext, TabList } from '@mui/lab'
import { Button, CircularProgress, Tab } from '@mui/material'
import { SyntheticEvent, useMemo, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetProjectsQuery } from '../../api/projects/projects.api'
import { SvgAnalytics, SvgWorker } from '../../shared/SVG/Svg'
import EmptyPlaceholder from '../../shared/components/EmptyPlaceholder/EmptyPlaceholder'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { toggleAddProject } from '../../store/slices/drawersSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { useTypedSelector } from '../../store/store'
import s from './Projects.module.scss'
import { projectsTerms } from './Projects.service'
import AdminCard from './component/AdminCard'
import { Dot } from './component/Dot'
import UserCard from './component/UserCard'

export default function Projects({ charts }: { charts?: boolean }) {
    const [value, setValue] = useState<projectsTerms>('Активный')

    const { headerSearch } = useTypedSelector(projectsSelector)
    const { profile } = useTypedSelector(profileSelector)
    const { data: projects, isLoading } = useGetProjectsQuery({})

    const dispatch = useDispatch()
    const navigate = useNavigate()

    function disableEmptyTab(term: string) {
        return !projects?.data?.some((v) => v.status === term)
    }

    const filterData = useMemo(() => {
        const data = projects?.data?.filter((v) => v.title.toLowerCase().includes(headerSearch.toLowerCase()))

        const termForPlaceholder = {
            Активный: 'активных',
            Приостановлен: 'пристановленных',
            Завершенный: 'завершенных',
        }

        const text = 'Ничего не найдено, измените текст поиска.'

        if (value !== 'Все') {
            return {
                data: data?.filter((v) => v?.status === value),
                placeholder: data?.length
                    ? `Нет ${termForPlaceholder[value]} проектов, используйте другую вкладку`
                    : text,
            }
        }
        return { data, placeholder: text }
    }, [headerSearch, projects?.data, value])

    if (isLoading) {
        return (
            <div className="progress">
                <CircularProgress />
            </div>
        )
    }

    if (!projects?.data?.length) {
        if (DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_PROJECT')) {
            return (
                <EmptyPlaceholder
                    text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.
                            Давайте создадим ваш первый проект.`}
                    onClick={() => dispatch(toggleAddProject())}
                    icon={<AiOutlinePlus />}
                    buttonText={
                        profile?.role === 'executor' || profile?.role === 'none' || profile.role === 'client'
                            ? undefined
                            : 'добавить проект'
                    }
                />
            )
        } else {
            return (
                <EmptyPlaceholder text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.`} />
            )
        }
    }

    return (
        <main className={s.main}>
            <section className={s.top_bar}>
                <TabContext value={value}>
                    <TabList
                        sx={{
                            '.MuiButtonBase-root': {
                                fontWeight: 600,
                                fontSize: 14,
                            },
                        }}
                        onChange={(event: SyntheticEvent, newValue: projectsTerms) => setValue(newValue)}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Все проекты" value="Все" />
                        <Tab label="Активные" disabled={disableEmptyTab('Активный')} value="Активный" />
                        <Tab
                            label="Приостановленные"
                            disabled={disableEmptyTab('Приостановлен')}
                            value="Приостановлен"
                        />
                        <Tab label="Завершенные" disabled={disableEmptyTab('Завершенный')} value="Завершенный" />
                    </TabList>
                </TabContext>

                <div className={s.top_right}>
                    {charts && (
                        <div className={s.dots}>
                            <Dot color="#FFB1B1" title="факт" />
                            <Dot color="#6D9ADC" title="план" />
                            <Dot color="#d4dce7" title="базовый план" />
                        </div>
                    )}
                    <div className={s.buttons_container}>
                        <button
                            className={`${s.left_view_button}  ${!charts && s.active_button_view}`}
                            onClick={() => navigate('/')}
                        >
                            <SvgWorker fill={charts ? '#0044b4' : '#fff'} />
                        </button>
                        <button
                            className={`${s.right_view_button}  ${charts && s.active_button_view}`}
                            onClick={() => navigate('/charts')}
                        >
                            <SvgAnalytics fill={charts ? '#fff' : '#0044b4'} />
                        </button>
                    </div>

                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_PROJECT') && (
                        <Button
                            onClick={() => dispatch(toggleAddProject())}
                            variant="outlined"
                            sx={{
                                padding: '5px 10px',
                                borderRadius: '6px',
                                backgroundColor: '#dce5f1',
                                letterSpacing: 0.1,
                                '&:hover': {
                                    backgroundColor: '#d4e1f3',
                                },
                            }}
                            startIcon={
                                <AddIcon
                                    sx={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                    }}
                                />
                            }
                        >
                            добавить
                        </Button>
                    )}
                </div>
            </section>

            {filterData?.data?.length ? (
                <section className={charts ? s.user_cards : s.admin_cards}>
                    {filterData?.data?.map((v) => (
                        <div key={v.id}>{charts ? <UserCard v={v} /> : <AdminCard v={v} />}</div>
                    ))}
                </section>
            ) : (
                <EmptyPlaceholder text={filterData?.placeholder} />
            )}
        </main>
    )
}
