/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { Work } from '../../../../api/ksg/ksg.def'
import { RootState, store } from '../../../store'
import { deployLinks } from '../../../../shared/config/config'
import { axiosRefetchToken } from '../../../../shared/utils'
import { toggleUploadProgress } from '../../drawersSlice'
import { IActiveUploadTaskShortly } from '../../../../shared/def'
export interface IGetParamsKSG {
    id: number
    limit?: number
    offset?: number
}
export const getWorksAgGrid = createAsyncThunk<
    { data: Work[]; total: number },
    IGetParamsKSG,
    {
        state: RootState
    }
>('ag_grid_ksg/worksList', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData
        const { data } = await axios<{ data: Work[]; total: number, activeUpload: null | IActiveUploadTaskShortly }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/cpg-list-next-generation`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: { filter: getState().project.headerSearch.toLowerCase(), ...getState().filters.filters },
        })
        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.ksg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})

export const getWorksAgGridOrphan = createAsyncThunk<
    { data: Work[]; total: number },
    IGetParamsKSG,
    {
        state: RootState
    }
>('ag_grid_ksg/worksListOrphan', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData
        const { data } = await axios<{ data: Work[]; total: number, activeUpload: null | IActiveUploadTaskShortly }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/cpg-orphan`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: { filter: getState().project.headerSearch.toLowerCase(), ...getState().filters.filters },
        })
        let returnPayload = { ...data }
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.ksg.worksList
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            }
        }
        return returnPayload
    } catch (e: any) {
        console.error(e)
        axiosRefetchToken(e?.response?.status)
        return rejectWithValue(e)
    }
})
