import { Box } from '@mui/material'
import { ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import React from 'react'
import { CPGHeader, TPreparedAct, UpdatedWorkPosition, Work, WorkPosition, monthlyChart } from '../../api/ksg/ksg.def'
import s from '../../components/AddLevelKsg/AddLevelKsg.module.scss'
import { monthsList } from '../../layouts/TableLayout/components/ChangeMonthTableTop'
import { IGetTargetRequestFuncArgs, ISubmitParams } from '../../shared/def'
import { onSubmitRejected, onSubmitSuccess } from '../../shared/utils'
import { clearWorks, onEditAgKsg } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import {
    IMonthPlanFilter,
    handleChangeMonthPlanfilter,
    handleResetMonthPlanfilter,
} from '../../store/slices/filtersSlice'
import { AppDispatch, store } from '../../store/store'
import { req } from '../WorkManagment/api/api'
import { OFFER_COL_DEFS } from './AgGrid.config'
import { ColumnFilter } from './components/ColumnFilter/ColumnFilter'
import { IColumnFilterProps } from './components/ColumnFilter/ColumnFilter.def'

export const getOfferColDefs = (key: 'lsrCiphers' | 'reportingPeriodInfo', isOfferActive?: boolean) => {
    if (!isOfferActive) return []
    return OFFER_COL_DEFS[key]
}

export const transformWorkPositionToWork = (workPosition: WorkPosition): UpdatedWorkPosition => {
    return {
        ...workPosition,
        workName: workPosition.name,
        isWorkPosition: true,
    }
}

/**
 * TODO
 * В дальнейшем здесь будет настраиваться клик по ячейкам ганта
 * после переноса диаграммы Ганта в AgGrid
 */

const COLUMN_FILTER_OPTIONS = [
    { label: 'Оперативный план есть', value: 'exist' },
    { label: 'Оперативного план нет', value: 'not_exist' },
]

const COLUMN_FILTER_PROPS = (month: CPGHeader) =>
    ({
        title: 'Фильтр по опер. плану',
        options: COLUMN_FILTER_OPTIONS,
        IconButtonProps: { disableRipple: true },
        defaultValue:
            store.getState().filters.filters.planFilter.filter((planFilter: IMonthPlanFilter) => {
                return planFilter.date.first === month.year && planFilter.date.second === month.month
            })[0]?.status || null,
        onChange: (value: string) => {
            if (value) {
                store.dispatch(clearWorks())
                store.dispatch(
                    handleChangeMonthPlanfilter({
                        date: {
                            first: month.year,
                            second: month.month,
                        },
                        status: value as IMonthPlanFilter['status'],
                    })
                )
            }
        },
        onReset: (value: string) => {
            store.dispatch(clearWorks())
            store.dispatch(
                handleResetMonthPlanfilter({
                    first: month.year,
                    second: month.month,
                })
            )
        },
    } as IColumnFilterProps)

// TODO:
// 1. Очистить от старого ганта
export const getHeadersMonths = (
    monthsArr: CPGHeader[] | null,
    callback: (data: ICellRendererParams<Work, any, any>, index: number) => React.ReactNode,
    isGant?: boolean,
    click?: any
) => {
    const overflow = isGant ? { cellStyle: { overflow: 'visible' } } : { cellStyle: { overflow: 'hidden' } }
    return (
        monthsArr?.reduce((acc: any[], month, idx) => {
            const header = acc.find((f) => f.headerName === month.year)
            const isCurrentMonth = new Date().getMonth() === month.month - 1
            const isCurrentYear = month.year === new Date().getFullYear()
            const doesThisMonthCurrent = isCurrentMonth && isCurrentYear
            if (header) {
                header.children.push({
                    field: 'monthlyCharts',
                    width: 130,
                    suppressMovable: true,
                    ...overflow,
                    onCellClicked: (event: any) => click && click(idx, event.data),
                    headerName: monthsList[month.month - 1],
                    headerClass: doesThisMonthCurrent && 'ag-cell-header-current-month',
                    headerComponent: (data: IHeaderParams<Work, any>) => {
                        return (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {data.displayName}
                                <ColumnFilter {...COLUMN_FILTER_PROPS(month)} />
                            </Box>
                        )
                    },
                    cellRenderer: (data: ICellRendererParams<Work, any, any>) => callback(data, idx),
                    cellClass: 'overflowable',
                } as ColDef)
            } else {
                acc.push({
                    headerName: month.year,
                    suppressMovable: true,
                    ...overflow,
                    children: [
                        {
                            field: 'monthlyCharts',
                            width: 130,
                            onCellClicked: (event: any) => click && click(idx, event.data),
                            headerName: monthsList[month.month - 1],
                            ...overflow,
                            headerClass: doesThisMonthCurrent && 'ag-cell-header-current-month',
                            headerComponent: (data: IHeaderParams<Work, any>) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    {data.displayName}
                                    <ColumnFilter {...COLUMN_FILTER_PROPS(month)} />
                                </Box>
                            ),
                            cellRenderer: (data: ICellRendererParams<Work, any, any>) => callback(data, idx),
                            cellClass: 'overflowable',
                            // headerClass: (params) => {
                            //     return (
                            //         month.month - 1 === new Date().getMonth() &&
                            //         month.year === new Date().getFullYear() &&
                            //         'ag-header-current-chart'
                            //     )
                            // },
                        } as ColDef,
                    ],
                })
            }
            return acc
        }, []) || []
    )
}

export function stylesLevelAg(lev: number | null) {
    const stylesByLevel = {
        0: s.level0,
        1: s.level1,
        2: s.level2,
        3: s.level3,
        4: s.level4,
        5: s.level5,
        6: s.level6,
        7: s.level7,
    }
    return stylesByLevel[lev as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7]
}

export const RD_STATUS_OPTIONS = [
    {
        label: 'Согласовано',
        value: 'Согласовано',
    },
    {
        label: 'На согласовании',
        value: 'На согласовании',
    },
    {
        label: 'Корректировка',
        value: 'Корректировка',
    },
    {
        label: 'Разработка',
        value: 'Разработка',
    },
    {
        label: 'В производстве',
        value: 'В производстве',
    },
    {
        label: 'Не в работе',
        value: 'Не в работе',
    },
]

export const VOLUME_TOTAL_CELL_EDITOR_PARAMS = (params: ICellRendererParams<Work>) => {
    let totalMonthlyChartValue = 0
    params.data?.monthlyCharts.forEach((monthlyChart: monthlyChart) => {
        totalMonthlyChartValue += monthlyChart.plan || 0
    })
    const maxValue = 999999999
    return {
        validation: {
            validate: {
                isLessThanTotalMonthlyChartValue: (v: number) => {
                    // console.log(totalMonthlyChartValue)
                    return (
                        v >= totalMonthlyChartValue ||
                        `Общий объем не может быть меньше плана по месяцам. Мин. значение ${totalMonthlyChartValue}`
                    )
                },
                maxValue: (v: number) => v <= maxValue || `Макс. ${maxValue}`,
            },
        },
    }
}

export function getTargetRequestByColDefFieldName({ params, projectID }: IGetTargetRequestFuncArgs) {
    switch (params.colDef.field) {
        case 'mainGroup':
            return req.post(`/projects/${projectID}/works/${params.data?.id}/set-main-group`, {
                mainGroup: params.newValue,
            })
        case 'rdStatus':
            return req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                [params.colDef.field as string]: params.newValue?.value || null,
            })
        case 'costDone':
            return params.data.hasChildren
                ? req.post(`/projects/${projectID}/works/${params.data.id}/update-cost-done-manually`, {
                      costDoneManually: params.newValue || null,
                  })
                : req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                      [params.colDef.field as string]: params.newValue,
                  })
        case 'toClose':
            const preparedAct = params.data.preparedAct as TPreparedAct
            return req.post(`/projects/works/save-executed-to-close`, {
                actId: preparedAct.actId,
                offerId: preparedAct.offerId,
                volume: params.newValue,
            })
        default:
            return req.patch(`/projects/${projectID}/works/${params.data.id}/update`, {
                [params.colDef.field as string]: params.newValue,
            })
    }
}

export function submitCellData(args: ISubmitParams & { dispatch: AppDispatch }) {
    const { params, projectID, dispatch } = args
    getTargetRequestByColDefFieldName({
        params: params,
        projectID: projectID as string,
    })
        .then(({ data }) => {
            const updatedData: Work[] =
                params.colDef.field === 'mainGroup' || params.colDef.field === 'toClose' ? [params.data] : data.data
            dispatch(onEditAgKsg(updatedData))
            params.api.applyTransaction({ update: data.data as Work[] })
            onSubmitSuccess(args)
        })
        .catch((e) => {
            console.log('e', e)

            onSubmitRejected({
                error: e,
                ...args,
            })
        })
}

export function ganttColor(work: Work | undefined) {
    if (work?.level === 1) return { backgroundColor: '#FFE08F' }
    if (work?.hasChildren) return { backgroundColor: '#6D9ADC' }
    return { backgroundColor: '#8DD4C8' }
}
