import { ColDef, ColGroupDef } from 'ag-grid-community'
import { isColGroupDef } from './typeGuards'

export const extractColIds = (colDef: ColGroupDef | ColDef, acc: string[] = []) => {
    if (isColGroupDef(colDef)) {
        colDef.children.forEach((v) => extractColIds(v, acc))
        return acc
    }

    colDef?.colId && acc.push(colDef.colId)
    return acc
}
