import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { api } from '../api/api'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './slices/authSlice'
import projectReducer from './slices/projectsSlice'
import cpgReducer from './slices/cpgSlice'
import drawersReducer from './slices/drawersSlice'
import msgReducer from './slices/executorSlice'
import cpgViewReducer from './slices/cpgViewSlice'

import filtersReducer from './slices/filtersSlice'
import paginationReducer from './slices/paginationSlice'
import msgTemplatesReducer from './slices/msgTemplatesSlice'
import ksgTemplatesReducer from './slices/ksgTemplatesSlice'
import monthMsgReducer from './slices/monthMsgSlice'
import templatesSharedReducer from './slices/templatesSharedSlice'
import filtersWorkersMimReducer from './slices/filtersWorkersMimSlice'
import profileReducer from './slices/profileSlice'
import basePlanReducer from './slices/basePlan'
import agGridKsgReducer from './slices/agGridKsgMsgSlices/agGridKsgSlice'
import filtersSomReducer from './slices/filtersSomSlice'
import { settingViewAgGridReducer } from './slices/settings/settingsViewAgGrid'
import priceSettingsReducer from './slices/priceSettings'
import msgGantReducer from './slices/msgGantSlice'

const settingsCombine = combineReducers({
    viewAgGrid:settingViewAgGridReducer
})

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        project: projectReducer,
        cpg: cpgReducer,
        drawers: drawersReducer,
        msg: msgReducer,
        cpgView: cpgViewReducer,
        filters: filtersReducer,
        pagination: paginationReducer,
        msgTemplates: msgTemplatesReducer,
        ksgTemplates: ksgTemplatesReducer,
        monthMsg: monthMsgReducer,
        templatesShared: templatesSharedReducer,
        filtersWorkersMim: filtersWorkersMimReducer,
        profile: profileReducer,
        basePlan: basePlanReducer,
        ag_grid_ksg: agGridKsgReducer,
        filters_som: filtersSomReducer,
        settings:settingsCombine,
        priceSettings: priceSettingsReducer,
        msgGant: msgGantReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
