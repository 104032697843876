import { Box } from '@mui/material'
import styled from 'styled-components'

export const ProgressContainer = styled(Box)<{ minHeight: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${(props) => props.minHeight}px;
`

export const Wrapper = styled(Box)`
    display: grid;
    grid-template-rows: repeat(4, max-content);
    gap: 1rem;
`

export const Description = styled(Box).attrs(() => ({
    component: 'p',
}))`
    margin: 1.5rem 0 0 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 140%;
    color: #9aa2b0;
`
