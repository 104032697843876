import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { monthlyChart, Work } from '../../../api/ksg/ksg.def'
import { WorkMsg } from '../../../api/msg/msg.def'
import { updateLevelById } from '../../../pages/KsgTable/Ksg.service'
import { updateLevelByIdMsg } from '../../../pages/MsgTable/Msg.service'
import { IActiveUploadTaskShortly } from '../../../shared/def'
import { RootState } from '../../store'
import { getWorksAgGrid, getWorksAgGridOrphan } from './ksgThunks'
import { getWorksAgGrid as getWorksAgGridMSG, getWorksAgGridOrphan as getWorksAgGridOrphanMSG } from './msgThunks'

interface IWorksState {
    ksg: {
        worksList: { data: Work[]; total: number; activeUpload?: IActiveUploadTaskShortly } | null
        trigger: boolean
        isKSGUpdatedByExcel: boolean
        isKSGUploaded: boolean
    }
    msg: {
        worksList: { data: WorkMsg[]; total: number } | null
        trigger: boolean
        isKSGUploaded: boolean
    }
    listMode: boolean
}

// interface initialStateI {
//     ksg: IWorksState
//     msg:Omit<IWorksState,'isKSGUpdatedByExcel'>
// }

const initialState: IWorksState = {
    ksg: {
        worksList: null,
        trigger: false,
        isKSGUpdatedByExcel: false,
        isKSGUploaded: false,
    },
    msg: {
        worksList: null,
        trigger: false,
        isKSGUploaded: false,
    },
    listMode: false,
}

const agGridKsgSlice = createSlice({
    name: 'ag_grid',
    initialState,
    reducers: {
        /* common */

        changeListMode: (state, action: PayloadAction<boolean | undefined>) => {
            if (action.payload !== undefined) {
                state.listMode = action.payload
            } else {
                state.listMode = !state.listMode
            }
        },
        /* добавил action для очистки workList */
        clearWorks: (state) => {
            state.msg.worksList = { data: [], total: 0 }
            state.ksg.worksList = { ...state.ksg.worksList, data: [], total: 0 }
        },

        /* KSG */

        onEditAgKsg: (state, action: PayloadAction<Work[]>) => {
            state.ksg.trigger = !state.ksg.trigger
            action.payload.forEach((wor) => {
                if (!state.ksg.worksList) return
                updateLevelById(state.ksg.worksList.data, wor)
            })
        },
        onDeleteRowKsgAg: (state, action: PayloadAction<{ id: number; works: Work[] }>) => {
            state.ksg.trigger = !state.ksg.trigger
            if (!state.ksg.worksList) return

            action.payload.works.forEach((wor) => {
                if (!state.ksg.worksList) return
                updateLevelById(state.ksg.worksList.data, wor)
            })

            const findItem = state.ksg.worksList.data.find((v) => v.id === action.payload.id)
            if (!findItem) return

            const result = state.ksg.worksList.data.filter((item) => {
                return !findItem.code.split('-').every((v) => item.code.split('-').includes(v))
            })
            state.ksg.worksList.data = result
            state.ksg.worksList.total = result.length
        },

        onCheckTagKsgAg: (state, action: PayloadAction<{ bool: boolean; workId: number | undefined }>) => {
            if (!state.ksg.worksList || !action.payload.workId) return
            const index = state.ksg.worksList.data.findIndex((f) => f.id === action.payload.workId)
            state.ksg.worksList.data[index].tag = action.payload.bool
        },

        onAddWorkKsgAg: (state, action: PayloadAction<{ works: Work[]; id: number | null }>) => {
            state.ksg.trigger = !state.ksg.trigger
            if (!state.ksg.worksList || !action.payload.works) return

            const workToAdd = action.payload.works.filter((work) => {
                return (
                    state.ksg.worksList &&
                    !state.ksg.worksList.data.find((w) => {
                        return work.id === w.id
                    })
                )
            })

            state.ksg.worksList.data.splice(action.payload?.id || 0, 0, workToAdd[0])
            state.ksg.worksList.total += 1

            action.payload.works.forEach((wor) => {
                if (!state.ksg.worksList) return
                updateLevelById(state.ksg.worksList.data, wor)
            })
        },

        updateKs3KsgAg: (state, action: PayloadAction<monthlyChart[]>) => {
            state.ksg.trigger = !state.ksg.trigger
            if (!state.ksg.worksList) return

            state.ksg.worksList.data[0].monthlyCharts = state.ksg.worksList.data[0].monthlyCharts.map((v, index) => {
                return { ...v, kc3: action.payload[index].kc3 }
            })
        },
        toggleTrigger: (state) => {
            state.ksg.trigger = !state.ksg.trigger
        },
        onKSGUpdate: (state) => {
            state.ksg.isKSGUpdatedByExcel = !state.ksg.isKSGUpdatedByExcel
        },
        onKSGUpload: (state) => {
            state.ksg.isKSGUploaded = !state.ksg.isKSGUploaded
        },

        /* --- */

        /* MSG */

        onEditAgMSG: (state, action: PayloadAction<WorkMsg[]>) => {
            state.msg.trigger = !state.msg.trigger
            action.payload.forEach((wor) => {
                if (!state.ksg.worksList) return
                updateLevelByIdMsg(state.msg.worksList!.data, wor)
            })
        },

        onDeleteRowMSGAg: (state, action: PayloadAction<{ id: number; works: WorkMsg[] }>) => {
            state.msg.trigger = !state.msg.trigger
            if (!state.msg.worksList) return

            action.payload.works.forEach((wor) => {
                if (!state.msg.worksList) return
                updateLevelByIdMsg(state.msg.worksList.data, wor)
            })

            const findItem = state.msg.worksList.data.find((v) => v.id === action.payload.id)
            if (!findItem) return

            const result = state.msg.worksList.data.filter((item) => {
                return !findItem.code.split('-').every((v) => item.code.split('-').includes(v))
            })
            state.msg.worksList.data = result
            state.msg.worksList.total = result.length
        },

        onCheckTagMSGAg: (state, action: PayloadAction<{ bool: boolean; workId: number | undefined }>) => {
            // if (!state.msg.worksList || !action.payload.workId) return
            // const index = state.msg.worksList.data.findIndex((f) => f.id === action.payload.workId)
            // state.msg.worksList.data[index].tag = action.payload.bool
        },

        onAddWorkMSGAg: (state, action: PayloadAction<{ works: WorkMsg[]; id: number | null }>) => {
            state.msg.trigger = !state.msg.trigger
            if (!state.msg.worksList || !action.payload.works) return

            const workToAdd = action.payload.works.filter((work) => {
                return (
                    state.msg.worksList &&
                    !state.msg.worksList.data.find((w) => {
                        return work.id === w.id
                    })
                )
            })

            state.msg.worksList.data.splice(action.payload?.id || 0, 0, workToAdd[0])
            state.msg.worksList.total += 1

            action.payload.works.forEach((wor) => {
                if (!state.msg.worksList) return
                updateLevelByIdMsg(state.msg.worksList.data, wor)
            })
        },

        updateKs3MSGAg: (state, action: PayloadAction<monthlyChart[]>) => {
            state.msg.trigger = !state.msg.trigger
            if (!state.msg.worksList) return

            state.msg.worksList.data[0].dailyCharts = state.msg.worksList.data[0].dailyCharts.map((v, index) => {
                return { ...v, kc3: action.payload[index].kc3 }
            })
        },
        toggleTriggerMSG: (state) => {
            state.msg.trigger = !state.msg.trigger
        },
        onMSGUpdate: (state) => {
            // state.msg.isKSGUpdatedByExcel = !state.msg.isKSGUpdatedByExcel
        },
        onMSGUpload: (state) => {
            state.msg.isKSGUploaded = !state.msg.isKSGUploaded
        },
        /* --- */
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorksAgGrid.fulfilled, (state, action) => {
                state.ksg.worksList = action.payload
            })
            .addCase(getWorksAgGridOrphan.fulfilled, (state, action) => {
                state.ksg.worksList = action.payload
            })
            .addCase(getWorksAgGridMSG.fulfilled, (state, action) => {
                state.msg.worksList = action.payload
            })
            .addCase(getWorksAgGridOrphanMSG.fulfilled, (state, action) => {
                state.msg.worksList = action.payload
            })
    },
})

export const {
    onEditAgKsg,
    changeListMode,
    clearWorks,
    onDeleteRowKsgAg,
    onCheckTagKsgAg,
    onAddWorkKsgAg,
    updateKs3KsgAg,
    toggleTrigger,
    onKSGUpdate,
    onKSGUpload,
    onEditAgMSG,
} = agGridKsgSlice.actions
export default agGridKsgSlice.reducer

export const agGridKsgSelector = (state: RootState) => state.ag_grid_ksg.ksg
export const agGridMsgSelector = (state: RootState) => state.ag_grid_ksg.msg
export const agGridListMode = (state: RootState) => state.ag_grid_ksg.listMode
