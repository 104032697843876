import { WorkMsg } from '../../api/msg/msg.def'
import { PublicUserProfile } from '../../api/users/users.def'
import { OverflowableTypographyWithTooltip } from '../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { areAllValuesNull } from '../../shared/utils'
import { setBrigades } from '../../store/slices/filtersSlice'
import { store } from '../../store/store'
import { HeaderFilter } from '../AgGrid/components/HeaderRenderer'
import { MainTableFilter } from '../AgGrid/components/MainTableFilter/MainTableFilter'
import RowMonthHeaderRenderer from '../AgGrid/components/RowMonthHeaderRenderer'
import { SpreadPlanBtn } from '../AgGrid/components/SpreadPlanBtn/SpreadPlanBtn'
import { VolumeTotalNotificator } from '../AgGrid/components/VolumeTotalNotificator/VolumeTotalNotificator'
import { applyStylesPercent, hasParam } from '../KsgTable/Ksg.service'
import { req } from '../WorkManagment/api/api'
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { getHeadersMonthsMsg } from './AgGridMsg.service'
import LevelRendererAgGridMsg from './components/LevelRendererAgGridMsg'
import MonthRendererMsg from './components/MonthRendererMSG/MonthRendererMsg'
import { ColGroupDef, EditableCallbackParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ProviderContext } from 'notistack'
import { Dispatch, RefObject, SetStateAction } from 'react'
import { Link, Location } from 'react-router-dom'
import { Updater } from 'use-immer'

interface IGetColDefMSGArgs {
    projectId: string
    hiddenRowsIds: {
        id: number
        code: string
    }[]
    setHiddenRowsIds: Updater<
        {
            id: number
            code: string
        }[]
    >
    location: Location
    setOpenModal: Dispatch<SetStateAction<boolean>>
    getExecutors: any
    getBrigades: any
    gridRef: RefObject<AgGridReact<any>>
    worksList: {
        data: WorkMsg[]
        total: number
    } | null
    profile: PublicUserProfile
    month: number
    year: number
    enqueueSnackbar: ProviderContext['enqueueSnackbar']
}

export const getMSGColDefs = ({
    projectId,
    hiddenRowsIds,
    setHiddenRowsIds,
    location,
    setOpenModal,
    getExecutors,
    getBrigades,
    gridRef,
    worksList,
    profile,
    month,
    year,
    enqueueSnackbar,
}: IGetColDefMSGArgs) => {
    return [
        {
            field: 'level',
            colId: 'level',
            headerName: 'Уровень',
            width: 130,
            spanHeaderHeight: true,
            cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => {
                if (!data.data) return <></>

                return (
                    <LevelRendererAgGridMsg
                        hasChildren={data.data.hasChildren}
                        id={data.data.id}
                        code={data.data.code}
                        level={data.data.level}
                        hiddenRowsIds={hiddenRowsIds}
                        setHiddenRowsIds={setHiddenRowsIds}
                    />
                )
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: 100,
            spanHeaderHeight: true,
            headerName: '№',
        },
        {
            field: 'code',
            colId: 'code',
            width: 130,
            spanHeaderHeight: true,
            headerName: 'ID',
            cellRenderer: (data: ICellRendererParams<WorkMsg, any, any>) => {
                return (
                    <Link
                        to={`/workManagment/${projectId}/${data.data?.id}/estimate/resources`}
                        state={{ prevPath: location.pathname }}
                    >
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1, cursor: 'pointer' } }}
                        >
                            {data.value}
                        </OverflowableTypographyWithTooltip>
                    </Link>
                )
            },
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: 260,
            spanHeaderHeight: true,
            headerName: 'Титул',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="objTitle"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'objName',
            colId: 'objName',
            width: 300,
            spanHeaderHeight: true,
            headerName: 'Объект',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="objName"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'rdGroup',
            colId: 'rdGroup',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Раздел РД',
        },
        {
            field: 'rdCode',
            colId: 'rdCode',
            width: 160,
            spanHeaderHeight: true,
            headerName: 'Шифр РД',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="rdCode"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'rdStatus',
            colId: 'rdStatus',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Статус выдачи РД',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="rdStatus"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'rdDateApproval',
            colId: 'rdDateApproval',
            width: 140,
            spanHeaderHeight: true,
            headerName: 'Дата согласования РД',
        },
        {
            field: 'rdVersion',
            colId: 'rdVersion',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Версия РД',
        },
        {
            field: 'rdDate',
            colId: 'rdDate',
            width: 140,

            spanHeaderHeight: true,
            headerName: 'Дата выдачи РД',
        },
        {
            field: 'typeOfWorks',
            colId: 'typeOfWorks',
            width: 150,
            headerName: 'Конструктив (вид работ)',
            spanHeaderHeight: true,
        },
        {
            field: 'workGroup',
            colId: 'workGroup',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Группа работ',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="workGroup"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'contractorCompany',
            colId: 'contractorCompany',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Подрядчик',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="contractorCompany"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'executorName',
            colId: 'executorName',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Исполнитель',
            editable: (params: EditableCallbackParams) => !params.data?.hasChildren,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
            cellEditorParams: {
                dataType: 'autocomplete',
                options: getExecutors.data?.data.map((executor: any) => ({
                    label: executor.name,
                    value: executor.employeeID,
                    ...executor,
                })),
                AutocompleteProps: {
                    disableClearable: false,
                },
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('executorName'),
        },
        {
            colId: 'brigade',
            field: 'brigade',
            width: 225,
            spanHeaderHeight: true,
            headerName: 'Бригада',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <MainTableFilter
                    title="Бригады"
                    onFilterOpen={(setOptions, setIsLoading) => {
                        req.post(
                            `/projects/${projectId}/works/filter/msg/brigades`,
                            {
                                filter: '',
                                ...store.getState().filters.filters,
                            },
                            {
                                year: year,
                                month: month + 1,
                            }
                        )
                            .then(({ data }) => {
                                setOptions(() =>
                                    data.data.map((brigadeName: string) => ({
                                        id: brigadeName,
                                        label: brigadeName,
                                        value: brigadeName,
                                        checked: store.getState().filters.filters.brigades.includes(brigadeName),
                                    }))
                                )
                                setIsLoading(false)
                            })
                            .catch((e) => {
                                setIsLoading(false)
                                console.log(e)
                                enqueueSnackbar('Ошибка', { variant: 'error' })
                            })
                    }}
                    onFilterApply={(options) => {
                        const brigades = options
                            .filter((option) => option.checked)
                            .map((option) => option.label.toString())
                        store.dispatch(setBrigades(brigades))
                    }}
                    onFilterReset={(options) => {
                        store.dispatch(setBrigades([]))
                    }}
                />
            ),
            editable: (params: EditableCallbackParams) => !params.data?.hasChildren,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
            cellEditorParams: {
                dataType: 'autocomplete',
                options: getBrigades.data?.data.map((brigade: any) => ({
                    label: brigade.name,
                    value: brigade.id,
                    ...brigade,
                })),
                AutocompleteProps: {
                    disableClearable: false,
                },
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('brigade', 'name'),
        },
        {
            field: 'workName',
            colId: 'workName',
            width: 300,
            spanHeaderHeight: true,
            headerName: 'Наименование работы',
            headerComponent: (data: IHeaderParams<WorkMsg, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="workName"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'unit',
            colId: 'unit',
            width: 130,
            spanHeaderHeight: true,
            headerName: 'Единица измерения',
            headerTooltip: 'Единица измерения',
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий объем на месяц',
            cellRenderer: (params: ICellRendererParams<WorkMsg>) => {
                return (
                    <>
                        {params.data?.volumeDistributeIndicator &&
                            !areAllValuesNull(params.data.volumeDistributeIndicator) && (
                                <VolumeTotalNotificator
                                    data={params.data?.volumeDistributeIndicator}
                                    options={{
                                        volumePlanLabel: 'ОПМ:',
                                    }}
                                    hideFields={['volumeBasePlan']}
                                />
                            )}
                        <NumberCellRenderer {...params} />
                    </>
                )
            },
        },
        {
            field: 'volumeDoneFactKsg',
            colId: 'volumeDoneFactKsg',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий факт',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'volumeTotalKsg',
            colId: 'volumeTotalKsg',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общий объем',
            cellRenderer: NumberCellRenderer,
        },
        {
            headerName: 'Выполненный объём работ',
            colId: 'volumeDone',
            children: [
                {
                    field: 'volumeDonePlan',
                    colId: 'volumeDonePlan',
                    width: 100,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'volumeDoneForecast',
                    colId: 'volumeDoneForecast',
                    width: 100,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'volumeDoneFact',
                    colId: 'volumeDoneFact',
                    width: 100,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Базовый план',
            colId: 'basePlanDates',
            children: [
                {
                    field: 'startDate',
                    colId: 'startDate',
                    width: 140,
                    spanHeaderHeight: true,
                    headerName: 'Дата начала',
                },
                {
                    field: 'endDate',
                    colId: 'endDate',
                    width: 140,
                    spanHeaderHeight: true,
                    headerName: 'Дата окончания',
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            headerName: 'Оперативный план',
            colId: 'operPlanDates',
            children: [
                {
                    field: 'operationalStartDate',
                    colId: 'operationalStartDate',
                    width: 140,
                    editable: (params) => !params.data?.hasChildren,
                    spanHeaderHeight: true,
                    headerName: 'Дата начала',
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalStartDate',
                        compareWithEndDate: 'operationalEndDate',
                        gridApi: gridRef?.current?.api,
                    }),
                },
                {
                    field: 'operationalEndDate',
                    colId: 'operationalEndDate',
                    width: 140,
                    editable: (params) => !params.data?.hasChildren,
                    spanHeaderHeight: true,
                    headerName: 'Дата окончания',
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalEndDate',
                        compareWithStartDate: 'operationalStartDate',
                        gridApi: gridRef?.current?.api,
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                {params.data?.operationalEndDate}
                                {params.data?.hasChildren && params?.data?.level === 0 && (
                                    <SpreadPlanBtn
                                        typeOfPlan="operational"
                                        typeOfReport="msg"
                                        cellRendererParams={params}
                                    />
                                )}
                                {params.data?.hasChildren === false &&
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_OPER_PLAN') && (
                                        <SpreadPlanBtn
                                            typeOfPlan="operational"
                                            typeOfReport="msg"
                                            cellRendererParams={params}
                                        />
                                    )}
                            </>
                        )
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'humanHourCost',
            colId: 'humanHourCost',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Трудозатраты на ед. изм., чел-час',
            headerTooltip: 'Трудозатраты на ед. изм., чел-час',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursTotal',
            colId: 'humanHoursTotal',
            width: 180,

            spanHeaderHeight: true,
            headerName: 'Трудозатраты общие, чел-час',
            headerTooltip: 'Трудозатраты общие, чел-час',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursProgressTZ',
            colId: 'humanHoursProgressTZ',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Прогресс ТЗ',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс трудозатрат нарастающим итогом, чел-час',
            colId: 'humanHoursProgress',
            children: [
                {
                    field: 'humanHoursProgressPlan',
                    colId: 'humanHoursProgressPlan',
                    width: 100,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressForecast',
                    colId: 'humanHoursProgressForecast',
                    width: 100,
                    headerName: 'Прогноз',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressFact',
                    colId: 'humanHoursProgressFact',
                    width: 100,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressPercent',
                    colId: 'humanHoursProgressPercent',
                    width: 130,
                    headerName: 'Процент выполнения',
                    headerTooltip: 'Процент выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        } as ColGroupDef<WorkMsg>,
        {
            field: 'costER',
            colId: 'costER',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Дог.+ДР), руб.',
            headerTooltip: 'Стоимость ЕР (Дог.+ДР), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotal',
            colId: 'costTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Дог.+ДР), рублей',
            headerTooltip: 'Общая стоимость (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgress',
            colId: 'costTotalProgress',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Дог.+ДР)',
            headerTooltip: 'Общий прогресс по стоимости (Дог.+ДР)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости (Дог.+ДР) нарастающим итогом, руб',
            colId: 'costProgress',
            children: [
                {
                    field: 'costProgressPlan',
                    colId: 'costProgressPlan',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFact',
                    colId: 'costProgressFact',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercent',
                    colId: 'costProgressPercent',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costERGK',
            colId: 'costERGK',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Договор), руб.',
            headerTooltip: 'Стоимость ЕР (Договор), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalGK',
            colId: 'costTotalGK',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Договор), рублей',
            headerTooltip: 'Общая стоимость (Договор), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressGK',
            colId: 'costTotalProgressGK',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Договор)',
            headerTooltip: 'Общий прогресс по стоимости (Договор)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Договор), руб',
            colId: 'costProgressGK',
            children: [
                {
                    field: 'costProgressPlanGK',
                    colId: 'costProgressPlanGK',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactGK',
                    colId: 'costProgressFactGK',
                    width: 180,
                    headerName: 'Факт',
                    сellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentGK',
                    colId: 'costProgressPercentGK',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costERSub',
            colId: 'costERSub',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Стоимость ЕР (Расчет), руб.',
            headerTooltip: 'Стоимость ЕР (Расчет), руб.',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalSub',
            colId: 'costTotalSub',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Общая стоимость (Расчет), рублей',
            headerTooltip: 'Общая стоимость (Расчет), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressSub',
            colId: 'costTotalProgressSub',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Расчет)',
            headerTooltip: 'Общий прогресс по стоимости (Расчет)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости нарастающим итогом (Расчет), руб',
            colId: 'costProgressSub',
            children: [
                {
                    field: 'costProgressPlanSub',
                    colId: 'costProgressPlanSub',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactSub',
                    colId: 'costProgressFactSub',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentSub',
                    colId: 'costProgressPercentSub',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costRemaining',
            colId: 'costRemaining',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Остаток стоимости, рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            headerClass: 'ag-verFact',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: '',
            width: 120,
            spanHeaderHeight: true,
            headerName: 'План/Факт',
            cellRenderer: (params: ICellRendererParams<WorkMsg, any, any>) => (
                <RowMonthHeaderRenderer
                    hasChildren={params.data?.hasChildren}
                    cellRendererParams={params}
                    isMSG={true}
                    isGant={hasParam('gant', location.search)}
                />
            ),
        },
        getHeadersMonthsMsg(worksList?.data[0]?.dailyCharts, (data, index) => {
            if (!data.data) return <></>
            return <MonthRendererMsg cellRendererParams={data} chartIndex={index} />
        }),
    ]
}
