import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import { modalStyles } from '../../shared/utils'
import { Updater, useImmer } from 'use-immer'
import { store, useAppDispatch, useTypedSelector } from '../../store/store'
import { authSelector } from '../../store/slices/authSlice'
import { filtersSelector, setWorkGroupSpecialFilter } from '../../store/slices/filtersSlice'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { useLocation, useOutletContext, useParams, useSearchParams } from 'react-router-dom'
import { GetRowIdParams, RowClassParams } from 'ag-grid-community'
import { WorkMsg } from '../../api/msg/msg.def'
import { msgTemplatesSelector } from '../../store/slices/msgTemplatesSlice'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { CANVAS_ID, submitCellData, createGantCanvasV2, drawDefaultArrowsFromServer } from './AgGridMsg.service'
import { useGetBrigadesQuery, useGetExecutorsQuery } from '../../api/company/company.api'
import { daysInMonth, getRowWidthMsg } from '../MsgTable/Msg.service'
import { useGetHeadersCPGQuery } from '../../api/ksg/ksg.api'
import { Box, Dialog, LinearProgress, Modal } from '@mui/material'
import ShowMsgDialog from '../../components/ShowMsgDialog/ShowMsgDialog'
import CheckboxPopover from '../../components/CheckboxPopover/CheckboxPopover'
import { getWorksAgGrid, getWorksAgGridOrphan, IGetParamsMSG } from '../../store/slices/agGridKsgMsgSlices/msgThunks'
import { useVerticalPagination } from '../../hooks/useVerticalPagination'
import { agGridListMode, agGridMsgSelector } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import '../AgGrid/AgGrid.scss'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { setAgGrid } from '../../store/slices/drawersSlice'
import { getMSGColDefs } from './AgGridMsg.colDef'
import { req } from '../WorkManagment/api/api'
import { useDidMountEffect } from '../../hooks/useDidMountEffect'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'

const LIMIT = 1000

export default function AgGridMsg() {
    const [mutationsLoading, setMutationsLoading] = useState(false)
    // const [worksList, setWorksList] = useImmer<{ data: WorkMsg[]; total: number } | null>(null)

    // const [hiddenRowsIds, setHiddenRowsIds] = useImmer<{ id: number; code: string }[]>([])
    const { hiddenRowsIds, setHiddenRowsIds } = useOutletContext<{
        hiddenRowsIds: {
            id: number
            code: string
        }[]
        setHiddenRowsIds: Updater<
            {
                id: number
                code: string
            }[]
        >
    }>()
    const [triggerRerender, setTriggerRerender] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [listModeOn, setListModeOn] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const { templateMsgState } = useTypedSelector(msgTemplatesSelector)
    const { month, year } = useTypedSelector(monthMsgSelector)
    const { token } = useTypedSelector(authSelector)
    const { filters, queryTerm } = useTypedSelector(filtersSelector)
    const { profile } = useTypedSelector(profileSelector)
    const { headerSearch } = useTypedSelector(projectsSelector)
    const { worksList } = useTypedSelector(agGridMsgSelector)
    const [displayedRowCount, setDisplayedRowCount] = useState(0)
    const listMode = useTypedSelector(agGridListMode)
    const { t } = useTranslation('chartKeys')
    const { enqueueSnackbar } = useSnackbar()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const { projectId } = useParams()

    const gridRef = useRef<AgGridReact>(null)

    const filtersJsonLength = JSON.stringify(filters).length

    // useEffect(() => {
    //     function cancelContextMenu(e: Event) {
    //         e?.cancelable && e.preventDefault()
    //     }
    //     document.addEventListener('contextmenu', cancelContextMenu)
    //     return () => {
    //         document.removeEventListener('contextmenu', cancelContextMenu)
    //     }
    // }, [])

    // useEffect(() => {
    //     gridRef?.current && dispatch(setAgGrid(gridRef?.current))
    //     return () => {
    //         dispatch(setAgGrid(null))
    //     }
    // }, [gridRef?.current])

    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current))
    }

    useEffect(() => {
        return () => {
            dispatch(setAgGrid(null))
        }
    }, [location.pathname, projectId])

    const getExecutors = useGetExecutorsQuery(
        {
            companyID: profile?.company?.companyID,
        },
        {
            skip: !profile?.company?.companyID,
        }
    )

    const getBrigades = useGetBrigadesQuery({
        projectID: projectId,
    })

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    })

    const isCreatedMsg = useMemo(() => {
        return headersCPG.data?.allHeaders.filter((header) => {
            if (header.isCreatedMSG) {
                return header.month === month + 1 && header.year === year
            }
        })
    }, [headersCPG.data?.allHeaders, month])

    const getRowClass = useCallback((params: RowClassParams<WorkMsg, any>) => {
        const stylesByLevel = {
            0: 'level_zero',
            1: 'level_one',
            2: 'level_two',
            3: 'level_three',
            4: 'level_four',
            5: 'level_five',
            6: 'level_six',
            7: 'level_seven',
        }

        return params.data?.hasChildren
            ? stylesByLevel[params.data?.level as keyof typeof stylesByLevel]
            : stylesByLevel[7]
    }, [])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id
    }, [])

    const filteredWorks = useMemo(() => {
        const filtArr = worksList?.data.reduce((acc: WorkMsg[], prev) => {
            const idx = hiddenRowsIds.every((filItem) => {
                if (prev.code === filItem.code) {
                    return true
                }

                return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v))
            })
            return idx ? [...acc, prev] : acc
        }, [])
        if (!filtArr?.length || !worksList?.data.length) {
            gridRef.current?.api?.showNoRowsOverlay()
        }
        return filtArr?.length ? { total: filtArr.length || 1, data: filtArr } : worksList
    }, [hiddenRowsIds, worksList])

    // PAGINATION
    /**
     * устанавливается нужный экшен по условию, для диспатча события внутри useVerticalPagination
     */
    const getData = useCallback(
        (params: IGetParamsMSG) => {
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid
            return sendingFunc(params)
        },
        [listMode]
    )

    /**
     * общее количество записей в БД
     */
    const [totalRows, setTotalRows] = useState(0)

    /**
     * Эффект следит за изменением projectId
     * и используя Api AgGrid управляет оверлэем таблицы
     * если workList не null отрисовывает таблицу,
     * в ином случае показывает что идет загрузка
     */
    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total)
            gridRef.current?.api?.hideOverlay()
        } else {
            // gridRef.current?.api?.showLoadingOverlay()
        }
    }, [worksList])
    /**
     *  добавил в массив resetToInitialDeps зависимость от projectId
     *  чтобы при смене проекта был перезапрос за данными
     */

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!)
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow

    const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsMSG>({
        initialParams: {
            limit: lastRenderedRow ? Math.ceil(lastRenderedRow / LIMIT) * LIMIT : LIMIT,
            offset: 0,
            id: Number(projectId),
            year: year,
            month: month + 1,
        },
        sendFn: getData,
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            filters,
            listMode,
            projectId,
            month,
            headerSearch,
            projectId,
            filtersJsonLength,
            token,
            triggerRerender,
            listModeOn,
        ],
    })

    const getChartViewportDimensions = useCallback(
        (rowHeight: number, colWidth: number, AgGrid: AgGridReact | null) => {
            let width = 0
            let height = (filteredWorks?.data.length || 0) * rowHeight
            if (AgGrid) {
                AgGrid.columnApi?.getColumnState().map((columnState) => {
                    if (columnState.colId.includes('dailyCharts')) {
                        width += columnState?.width || colWidth
                    }
                })
            }

            return {
                chartsViewportWidth: width,
                chartsViewportHeight: height,
            }
        },
        [filteredWorks?.data.length]
    )

    const drawInitialArrows = useCallback(() => {
        const { chartsViewportWidth, chartsViewportHeight } = getChartViewportDimensions(80, 120, gridRef.current)
        req.post(`/projects/${projectId}/dependencies/works/msg/info`, {
            month: month + 1,
            year: year,
        })
            .then(({ data }) => {
                if (gridRef.current) {
                    document.getElementById(CANVAS_ID + 'wrapper')?.remove()
                    createGantCanvasV2(CANVAS_ID, chartsViewportWidth, chartsViewportHeight)
                    drawDefaultArrowsFromServer(gridRef.current, data?.data)
                }
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', { variant: 'error' })
            })
    }, [getChartViewportDimensions, projectId, month, year, enqueueSnackbar])

    useEffect(() => {
        if (searchParams.get('gant') && !document.getElementById(CANVAS_ID + 'wrapper')) {
            drawInitialArrows()
        }
        if (!searchParams.get('gant')) {
            document.getElementById(CANVAS_ID + 'wrapper')?.remove()
        }
    }, [searchParams])

    if (isCreatedMsg?.length === 0 && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')) {
        return (
            <Dialog open={true} onClose={() => {}}>
                <ShowMsgDialog setTriggerRerender={setTriggerRerender} />
            </Dialog>
        )
    }
    if (!filteredWorks) {
        return (
            <LinearProgress
                sx={{
                    width: getRowWidthMsg(daysInMonth(month, new Date().getFullYear()), templateMsgState) || 500,
                }}
            />
        )
    }

    return (
        <>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ ...modalStyles, width: 373 }}>
                    <CheckboxPopover
                        msg
                        showEmptyParams={{
                            show: queryTerm === 'workGroup',
                            checked: filters.workGroupSpecial,
                            onChange: (checked: boolean) => dispatch(setWorkGroupSpecialFilter(checked)),
                        }}
                    />
                </Box>
            </Modal>

            <div
                className="ag-theme-alpine"
                style={{
                    height: '100%',
                    width: '100%',
                    paddingRight: '0.5rem',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                {filteredWorks.data && (
                    <AgGridReact
                        ref={gridRef}
                        getRowId={getRowId}
                        onBodyScrollEnd={scrollHandlerFoAG}
                        getRowClass={getRowClass}
                        rowData={filteredWorks.data}
                        columnDefs={getMSGColDefs({
                            projectId: projectId as string,
                            hiddenRowsIds: hiddenRowsIds,
                            setHiddenRowsIds: setHiddenRowsIds,
                            location: location,
                            setOpenModal: setOpenModal,
                            getExecutors: getExecutors,
                            getBrigades: getBrigades,
                            gridRef: gridRef,
                            worksList: worksList,
                            profile: profile,
                            month: month,
                            year: year,
                            enqueueSnackbar: enqueueSnackbar
                        })}
                        {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                        onCellValueChanged={(params) => {
                            submitCellData({
                                params: params,
                                projectID: projectId as string,
                                enqueueSnackbar: enqueueSnackbar,
                                translate: t,
                            })
                            params.api.setSuppressModelUpdateAfterUpdateTransaction(true)
                        }}
                        onRowDataUpdated={(params) => {
                            searchParams.get('gant') && drawInitialArrows()
                        }}
                        {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MSG') && {
                            suppressClickEdit: true,
                        })}
                    ></AgGridReact>
                )}
            </div>
        </>
    )
}
