import { Typography } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import type { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { Fragment, RefObject, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Work } from '../../api/ksg/ksg.def'
import { TGanttLinkModificationResponse } from '../../api/relations/relations.def'
import { isTooManyRequests } from '../../shared/utils'
import { FlexRowWrapper } from '../NewExecutorView/components/components.styles'
import { req } from '../WorkManagment/api/api'
import { LoadingOverlay } from '../WorkManagment/components/AgGridService/components/LoadingOverlay'
import { ILibGanttLink, ITask, TGanttLinkPayload, TLocalGanttLinkId } from './DHTGant.def'
import { useGetGanttEntities, useGetProjectDates } from './DHTGantOnlyTable.model'
import {
    addLayers,
    batchUpdateTasksDates,
    deleteLinkPermanently,
    deleteLinksBetweenTasks,
    formatDateToString,
} from './DHTGantOnlyTable.utils'
import { GanttLinkDialog } from './components/GanttLinkDialog'
import { setGanttConfig } from './configTwoGrids/ganttConfig'
import { getGanttEventHandlers } from './configTwoGrids/ganttEvents'
import './customStylesOverrides.scss'
import { gantt } from './dhtmlxgantt'
import './dhtmlxgantt.css'
import { Task } from './dhtmlxgantt.js'

interface IGanttTableProps {
    filteredWorks: Work[] | null
    grid: AgGridReact
}

export const GanttTable: React.FC<IGanttTableProps> = ({ filteredWorks, grid }) => {
    const { projectId } = useParams()
    const [urlSearchParams] = useSearchParams()
    const ganttRef = useRef(null) as RefObject<HTMLDivElement>
    const [overlayTitle, setOverlayTitle] = useState<string | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const [selectedTask, setSelectedTask] = useState<ITask | null>(null)

    const isCriticalPathEnabled = urlSearchParams.has('criticalPath')
    const isVisibleGanttFact = urlSearchParams.has('gf')

    const projectDates = useGetProjectDates(projectId as string)
    const { tasks, links } = useGetGanttEntities(filteredWorks, projectId as string)

    const isGanttInitialized = useRef(false)

    // Инициализация ганта
    useEffect(() => {
        if (!ganttRef?.current) return
        if (!projectDates) return

        const events = getGanttEventHandlers(gantt, {
            onBeforeLinkAdd: handleBeforeLinkAdd,
            onBeforeLinkDelete: handleBeforeLinkDelete,
            onAfterTaskDrag: handleAfterTaskDrag,
            onTaskDblClick: handleTaskDblClick,
        })
        setGanttConfig(gantt, { projectDates, highlightCriticalPath: isCriticalPathEnabled })
        gantt.init('gantt', projectDates.start, projectDates.end)
        addLayers(gantt, projectDates)
        gantt.parse({
            tasks,
            links,
        })
        isGanttInitialized.current = true

        return () => {
            gantt.clearAll()
            gantt.resetLayout()

            for (const event of events) {
                gantt.detachEvent(event)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, projectDates, ganttRef, isCriticalPathEnabled, urlSearchParams])

    useEffect(() => {
        if (!isGanttInitialized.current) return

        gantt.clearAll()
        gantt.parse({
            tasks,
            links,
        })
        setGanttScrollTop()
    }, [tasks, links])

    const setGanttScrollTop = () => {
        const verticalRange = grid.api.getVerticalPixelRange()
        gantt.scrollTo(null, verticalRange.top)
    }

    const updateTaskOperationDates = async (task: Task) => {
        const operationalStartDate = formatDateToString(task.start_date)
        const operationalEndDate = formatDateToString(task.end_date)

        if (operationalStartDate === null || operationalEndDate === null) {
            setTimeout(gantt.undo, 500)
            return
        }

        try {
            setOverlayTitle(() => 'Сохраняем новые даты оперативного плана...')
            const { data } = await req.post(`/projects/${projectId}/dependencies/works/ksg/${task.id}/move`, {
                operationalStartDate,
                operationalEndDate,
            })

            const works = data.data as Work[]
            batchUpdateTasksDates(gantt)(works)
            grid.api.applyTransaction({ update: works })
        } catch (error: AxiosError | unknown) {
            if (isTooManyRequests(error)) return
            console.log('error', error)
            enqueueSnackbar('Ошибка при изменение дат оперативного плана', {
                variant: 'error',
            })
            setTimeout(gantt.undo, 500)
        } finally {
            setOverlayTitle(() => null)
        }
    }

    const handleTaskDblClick = (taskId: TLocalGanttLinkId) => {
        const task = gantt.getTask(taskId) as ITask
        if (!task) return false
        if (task?.hasChildren) return false
        setSelectedTask(() => task)
    }

    const handleGanttLinkDialogClose = () => setSelectedTask(null)

    const handleAfterTaskDrag = (taskId: number) => {
        const task = gantt.getTask(taskId)
        if (!task) return

        updateTaskOperationDates(task)
    }

    const handleBeforeLinkAdd = (linkId: TLocalGanttLinkId, link: ILibGanttLink & { canAdd?: boolean }): boolean => {
        // Почему-то отсутствует метод isCircularLink в сущности
        // const isCircular = gantt.isCircularLink(link)

        const targetTask = gantt.getTask(link.target)
        if (targetTask && targetTask?.hasChildren) return false

        if (link?.canAdd) return true

        const payload: TGanttLinkPayload = {
            source: Number(link.source),
            target: Number(link.target),
            type: link.type,
            lag: link.lag ? link.lag : 0,
        }
        bindLink(linkId, payload)
        return false
    }

    const handleBeforeLinkDelete = (
        linkId: TLocalGanttLinkId,
        link: ILibGanttLink & { canDelete?: boolean }
    ): boolean => {
        if (link?.canDelete) return true

        const payload: TGanttLinkPayload = {
            source: Number(link.source),
            target: Number(link.target),
            type: link.type,
            lag: link.lag ? link.lag : 0,
        }
        unbindLink(linkId, payload)
        return false
    }

    const bindLink = async (linkId: TLocalGanttLinkId, payload: TGanttLinkPayload) => {
        setOverlayTitle(() => 'Устанавливаем связи')
        try {
            const response = await req.post(`/projects/${projectId}/dependencies/works/ksg/bind/v2`, payload)
            const data = response.data as TGanttLinkModificationResponse
            deleteLinksBetweenTasks(gantt)({
                source: payload.source,
                target: payload.target,
            })
            gantt.addLink({
                id: linkId,
                canAdd: true,
                ...payload,
            })

            if (data.updatedWorks.length > 0) {
                batchUpdateTasksDates(gantt)(data.updatedWorks)
                grid.api.applyTransaction({ update: data.updatedWorks })
            }

            return data
        } catch (error) {
            if (isTooManyRequests(error)) return

            console.log('error', error)
            enqueueSnackbar('Произошла ошибка при добавлении связи', {
                variant: 'error',
            })
        } finally {
            setOverlayTitle(() => '')
        }
    }

    const unbindLink = async (linkId: TLocalGanttLinkId, payload: TGanttLinkPayload) => {
        setOverlayTitle(() => 'Удаляем связь')
        try {
            const response = await req.post(`/projects/${projectId}/dependencies/works/ksg/unbind`, payload)
            const data = response.data as TGanttLinkModificationResponse
            deleteLinkPermanently(gantt, linkId)

            if (data.updatedWorks.length > 0) {
                batchUpdateTasksDates(gantt)(data.updatedWorks)
                grid.api.applyTransaction({ update: data.updatedWorks })
            }
            return data
        } catch (error) {
            if (isTooManyRequests(error)) return
            console.log('error', error)
            enqueueSnackbar('Произошла ошибка при удалении связи', {
                variant: 'error',
            })
        } finally {
            setOverlayTitle(() => '')
        }
    }

    return (
        <Fragment>
            <GanttLinkDialog
                tasks={tasks}
                gantt={gantt}
                grid={grid}
                selectedTaskId={selectedTask?.id ?? null}
                taskName={selectedTask?.workName}
                setOverlayTitle={setOverlayTitle}
                onClose={handleGanttLinkDialogClose}
            />

            <LoadingOverlay
                key="overlay"
                open={!!overlayTitle}
                transitionDuration={0}
                sx={{
                    zIndex: 9999,
                    height: 'calc(100% - 125px)',
                    background: 'rgba(255,255,255, 0.4)',
                }}
            >
                <FlexRowWrapper gap={1} bottom={20} right={20} position={'absolute'} bgcolor={'white'} px={2} py={1}>
                    <span className="loader" style={{ width: 24, height: 24, zIndex: 999 }}></span>
                    <Typography>{overlayTitle && overlayTitle}</Typography>
                </FlexRowWrapper>
            </LoadingOverlay>
            {filteredWorks?.length === 0 && 'Нет данных'}
            {filteredWorks === null && 'Идет загрузка'}
            <div
                id="gantt"
                ref={ganttRef}
                style={
                    {
                        '--task-progress-color': `${isVisibleGanttFact ? '#00000026' : 'transparent'}`,
                        height: '100%',
                        width: 800,
                        minWidth: '20%',
                        maxWidth: '70%',
                    } as React.CSSProperties
                }
            />
        </Fragment>
    )
}
