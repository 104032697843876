import { ArrowBack } from '@mui/icons-material'
import { Box, CircularProgress, Dialog, LinearProgress, Paper, Typography } from '@mui/material'
import { FlexColumnWrapper, FlexRowWrapper } from '../../pages/NewExecutorView/components/components.styles'
import { StyledBtn } from '../../pages/WorkManagment/components/components.styles'
import { IUploadProgressDialogProps } from './UploadProgressDialog.def'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import { drawersSelector, toggleUploadProgress } from '../../store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'

export function UploadProgressDialog({ open, onClose, supressWatchBtn }: IUploadProgressDialogProps) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const drawers = useTypedSelector(drawersSelector)
    const doesDialogWrapped = drawers?.uploadProgress?.openDialog === 'wrapped'
    const progress = drawers.uploadProgress.activeTask?.progress || 0

    if (doesDialogWrapped) {
        return (
            <FlexRowWrapper
                position={'absolute'}
                right="30px"
                bottom="30px"
                bgcolor="white"
                borderRadius={1}
                p={1}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() => dispatch(toggleUploadProgress({ openDialog: true }))}
            >
                <span className="loader" style={{ width: 24, height: 24 }}></span>

                <FlexColumnWrapper gap={0}>
                    <Typography variant="h6" fontSize={16} fontWeight={400}>
                        Файл обрабатывается...
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} />
                </FlexColumnWrapper>
            </FlexRowWrapper>
        )
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <FlexColumnWrapper gap={2.5} width={350} p={4} alignItems={'center'}>
                <FlexRowWrapper
                    sx={{
                        width: 90,
                        height: 90,
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(246, 247, 251, 1)',
                        borderRadius: 100,
                    }}
                >
                    <span className="loader"></span>
                </FlexRowWrapper>
                <FlexColumnWrapper gap={1} textAlign={'center'}>
                    <Typography variant="h6" fontSize={16}>
                        Файл обрабатывается ({progress}%)...
                    </Typography>
                    <Typography variant="body2" color={'rgba(92, 110, 140, 1)'}>
                        В зависимости от размера файла <br />
                        процесс обработки может составить <br />
                        от 1 до 60 минут. <br />
                        {!supressWatchBtn && (
                            <>
                                Вы можете посмотреть проект, <br />
                                но не изменять его.
                            </>
                        )}
                    </Typography>
                </FlexColumnWrapper>
                {!supressWatchBtn && (
                    <StyledBtn
                        variant="outlined"
                        fullWidth
                        sx={{ bgcolor: 'rgba(25, 118, 210, 0.08)' }}
                        onClick={() => {
                            dispatch(
                                toggleUploadProgress({
                                    openDialog: 'wrapped',
                                })
                            )
                        }}
                    >
                        Посмотреть проект
                    </StyledBtn>
                )}
                <StyledBtn
                    variant="outlined"
                    fullWidth
                    sx={{ bgcolor: 'rgba(25, 118, 210, 0.08)' }}
                    startIcon={<ArrowBack />}
                    onClick={() => {
                        navigate('/')
                        onClose && onClose({}, 'backdropClick')
                        dispatch(
                            toggleUploadProgress({
                                openDialog: false,
                            })
                        )
                    }}
                >
                    Перейти к списку проектов
                </StyledBtn>
            </FlexColumnWrapper>
        </Dialog>
    )
}
