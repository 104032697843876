import { Stack } from '@mui/material'
import { CellValueChangedEvent, RowNode } from 'ag-grid-community'
import { useCallback, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TGanttLinkType } from '../../../../api/relations/relations.def'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { IAutocompleteOption } from '../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.def'
import { TGanttLinkPayload, TLocalGanttLinkId } from '../../DHTGant.def'
import { GanttLinkDialogColDefs } from './GanttLinkDialog.colDef'
import { LINK_TYPES } from './GanttLinkDialog.config'
import { IGanttLinkDialogProps } from './GanttLinkDialog.def'
import { useGetRowData } from './GanttLinkDialog.model'
import {
    AddLinkButton,
    CloseButton,
    Container,
    DialogWindow,
    PlusIcon,
    RemoveButton,
    StyledAgGrid,
    Title,
    WorkName,
    WorkNameContainer,
} from './GanttLinkDialog.styles'
import {
    addLinkDialog,
    deleteLinkDialog,
    deleteMassLinkDialog,
    fetchIndependentTasks,
    formatLinkType,
    getDeletePayload,
} from './GanttLinkDialog.utils'
import { useSnackbar } from 'notistack'

export const GanttLinkDialog: React.FC<IGanttLinkDialogProps> = ({
    gantt,
    grid,
    tasks,
    selectedTaskId,
    taskName,
    onClose,
    setOverlayTitle,
}) => {
    const { projectId } = useParams()
    const [targets, setTargets] = useState<Set<TLocalGanttLinkId>>(new Set())
    const [independentTasks, setIndependentTasks] = useState<IAutocompleteOption[]>([])
    const [type, setType] = useState<TGanttLinkType>(LINK_TYPES[0].type)
    const isValueChangedRef = useRef<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()
    const { rowData, setRowData, refreshToggle, setRefreshToggle } = useGetRowData(
        tasks,
        projectId as string,
        selectedTaskId as TLocalGanttLinkId
    )

    const handleLinkDelete = async () => {
        if (!selectedTaskId || !projectId) return
        setOverlayTitle(() => 'Удаляем связи')
        try {
            await deleteMassLinkDialog(gantt, targets, selectedTaskId, projectId, grid)
            setRefreshToggle(!refreshToggle)
        } catch (error) {
            console.log('error', error)
        } finally {
            setOverlayTitle(() => '')
        }
    }

    const handleAddEmptyRow = () => {
        const newRow = { id: '', number: null, name: '', linkType: '', lag: 0 }
        setRowData([...rowData, newRow])
    }

    const resetState = () => {
        setTargets((prevTargets) => {
            const newTargets = new Set(prevTargets)
            newTargets.clear()
            return newTargets
        })
        setType(LINK_TYPES[0].type)
        onClose()
    }

    const getTasksAsAutocompleteOptions = useCallback(async (): Promise<IAutocompleteOption[]> => {
        const tasks = await fetchIndependentTasks(projectId as string, selectedTaskId as TLocalGanttLinkId)
        return tasks
            .filter((task: any) => task.id !== selectedTaskId)
            .map((task: any) => ({
                id: task.id,
                label: task.numOrder + ' ' + task.workName,
                value: task.workName,
            }))
    }, [projectId, selectedTaskId])

    const onRowEditingStopped = async (event: CellValueChangedEvent) => {
        if (isValueChangedRef.current) {
            if (!selectedTaskId || !projectId || targets.size) return
            const type = typeof event.data.linkType == 'object' ? event.data.linkType.value : event.data.linkType
            const targetName = typeof event.data.name == 'object' ? event.data.name.value : event.data.name

            if (type && targetName) {
                const payload: TGanttLinkPayload = {
                    source: Number(selectedTaskId),
                    target: Number(event.data.name.id || event.data.id),
                    type: formatLinkType(type, false) as TGanttLinkType,
                    lag: event.data.lag,
                }
                if (event.data.id) {
                    let isError = false
                    setOverlayTitle(() => 'Редактируем связь')
                    const { deletePayload, linkId }: { deletePayload: TGanttLinkPayload; linkId: TLocalGanttLinkId } =
                        getDeletePayload(gantt, selectedTaskId, event.data.id)
                    try {
                        await deleteLinkDialog(gantt, projectId, grid, deletePayload, linkId)
                    } catch (error) {
                        isError = true
                        console.log('error', error)
                        enqueueSnackbar('Работа не может быть изменена', {
                            variant: 'error',
                        })
                        setOverlayTitle(() => '')
                        setRefreshToggle(!refreshToggle)
                    }
                    if (isError) return
                    try {
                        await addLinkDialog(gantt, projectId, grid, payload)
                    } catch (error) {
                        console.log('error', error)
                        await addLinkDialog(gantt, projectId, grid, deletePayload)
                        enqueueSnackbar('Работа выходит за рамки проекта', {
                            variant: 'error',
                        })
                    } finally {
                        setOverlayTitle(() => '')
                        setRefreshToggle(!refreshToggle)
                    }
                } else {
                    setOverlayTitle(() => 'Добавляем связь')
                    try {
                        await addLinkDialog(gantt, projectId, grid, payload)
                    } catch (error) {
                        console.log('error', error)
                        enqueueSnackbar('Работа выходит за рамки проекта', {
                            variant: 'error',
                        })
                    } finally {
                        setOverlayTitle(() => '')
                        setRefreshToggle(!refreshToggle)
                    }
                }
            }
            isValueChangedRef.current = false
        }
    }

    const onCellValueChanged = async (event: CellValueChangedEvent) => {
        isValueChangedRef.current = true
    }

    return (
        <DialogWindow open={Boolean(selectedTaskId)} onClose={onClose}>
            <Container>
                <Stack gap={2.5}>
                    <Title>Управление связями</Title>
                    <WorkNameContainer>
                        <WorkName>{taskName}</WorkName>
                    </WorkNameContainer>
                    {/* Придумать что-то с высотой. Нужна динамичность  */}
                    <FlexRowWrapper
                        className="table-wrapper"
                        height={Math.max((rowData.length + 1) * 40, 6)}
                        width={'100%'}
                        gap={0}
                    >
                        <div style={{ flex: '1 1 0', height: '100%' }}>
                            <StyledAgGrid
                                editType="fullRow"
                                columnDefs={GanttLinkDialogColDefs({
                                    rowData,
                                    targets,
                                    setTargets,
                                    selectedTaskId,
                                    independentTasks,
                                    getTasksAsAutocompleteOptions,
                                })}
                                rowData={rowData}
                                gridOptions={{
                                    rowHeight: 40,
                                    singleClickEdit: true,
                                    rowSelection: 'multiple',
                                    stopEditingWhenCellsLoseFocus: true,
                                }}
                                headerHeight={40}
                                suppressMovableColumns
                                onCellValueChanged={onCellValueChanged}
                                onRowEditingStopped={onRowEditingStopped}
                            />
                        </div>
                    </FlexRowWrapper>
                </Stack>

                <Stack gap={2.5}>
                    <AddLinkButton onClick={handleAddEmptyRow}>
                        <PlusIcon />
                        Добавить связь
                    </AddLinkButton>
                    <Stack direction={'row'} gap={2} justifyContent="space-around">
                        <RemoveButton onClick={handleLinkDelete} disabled={!targets.size}>
                            Удалить
                        </RemoveButton>
                        <CloseButton onClick={resetState}>Закрыть</CloseButton>
                    </Stack>
                </Stack>
            </Container>
        </DialogWindow>
    )
}
