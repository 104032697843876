import { EditableCallbackParams, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { Work } from '../../api/ksg/ksg.def'
import { OverflowableTypographyWithTooltip } from '../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'

export const OFFER_COL_DEFS = {
    lsrCiphers: [
        {
            headerName: 'Шифры ЛСР',
            colId: 'lsrCiphers',
            children: [
                {
                    field: 'lsr',
                    width: 180,
                    headerName: 'Шифр ЛСР',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={3}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
                {
                    field: 'chapter',
                    width: 180,
                    headerName: 'Раздел',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={3}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
                {
                    field: 'header',
                    width: 180,
                    headerName: 'Заголовок',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: (params: ICellRendererParams) => (
                        <OverflowableTypographyWithTooltip maxRows={3}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    ),
                },
            ],
        },
    ],
    reportingPeriodInfo: [
        {
            headerName: 'На начало отчетного периода',
            colId: 'atStartOfReportingPeriod',
            children: [
                {
                    headerName: 'Выполнено',
                    colId: 'atStartOfReportingPeriodExecuted',
                    children: [
                        {
                            field: 'executedAtStart',
                            valueGetter: (params: ValueGetterParams<Work>) => params.data?.preparedAct?.executedAtStart,
                            width: 120,
                            headerName: 'Выполнено',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                        },
                        {
                            field: 'remainingAtStart',
                            valueGetter: (params: ValueGetterParams<Work>) =>
                                params.data?.preparedAct?.remainingAtStart,
                            width: 120,
                            headerName: 'Остаток',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                        },
                    ],
                },
                {
                    headerName: 'Закрыто',
                    colId: 'atStartOfReportingPeriodClosed',
                    children: [
                        {
                            field: 'closedAtStart',
                            valueGetter: (params: ValueGetterParams<Work>) => params.data?.preparedAct?.closedAtStart,
                            width: 120,
                            headerName: 'Закрыто',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                        },
                        {
                            field: 'closedRemainingAtStart',
                            valueGetter: (params: ValueGetterParams<Work>) =>
                                params.data?.preparedAct?.closedRemainingAtStart,
                            width: 120,
                            headerName: 'Остаток',
                            editable: false,
                            cellRenderer: NumberCellRenderer,
                        },
                    ],
                },
                {
                    field: 'nzp',
                    width: 120,
                    headerName: 'НЗП',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<Work>) => params.data?.preparedAct?.nzp,
                },
            ],
        },
        {
            field: 'executedAtPeriod',
            width: 120,
            headerName: 'Выполнено за период',
            editable: false,
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            valueGetter: (params: ValueGetterParams<Work>) => params.data?.preparedAct?.executedAtPeriod,
        },
        {
            headerName: 'На конец отчетного периода',
            colId: 'atEndOfReportingPeriod',
            children: [
                {
                    field: 'availableToClose',
                    valueGetter: (params: ValueGetterParams<Work>) => params.data?.preparedAct?.availableToClose,
                    width: 170,
                    headerName: 'Доступно к закрытию',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'toClose',
                    width: 170,
                    headerName: 'Выполнено',
                    editable: (params: EditableCallbackParams<Work>) => {
                        if (!params.data?.preparedAct) return false
                        return true
                    },
                    spanHeaderHeight: true,
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('toClose'),
                    ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('preparedAct', 'toClose'),
                },
            ],
        },
    ],
}
