import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const GanttSVG: React.FC<SvgIconProps & { isActive?: boolean }> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 3H9V6H2V3ZM2 18H18V21H2V18ZM13 8H8V11H13V8ZM12 13H22V16H12V13Z"
                    fill={props.isActive ? 'white' : '#0044B4'}
                />
            </svg>
        </SvgIcon>
    )
}

export default GanttSVG
