import { ICellRendererParams } from 'ag-grid-community'
import { monthlyChart } from '../../../../api/ksg/ksg.def'
import { onEditAgKsg } from '../../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { req } from '../../../WorkManagment/api/api'
import {
    MONTH_NAME_TO_MONTH_YEAR_ORDER,
    onSubmitRejected,
    onSubmitSuccess,
    parseStringToNumber,
} from '../../../../components/EditChartCell/EditChartCell.service'
import {
    IEditChartCellProps,
    IOnSubmitChartDataArgs,
    IUpdateChartBodyReq,
} from '../../../../components/EditChartCell/EditChartCell.def'

function getTargetURLByValueType(chartValueKey: IEditChartCellProps['chartValueKey'], projectID: string) {
    const baseURL = `/projects/${projectID}/works/update-charts`
    switch (chartValueKey) {
        case 'basePlan':
            return baseURL + '-bp'
        case 'verifiedFact':
            return baseURL + '-vf'
        case 'plan':
            return baseURL
    }
}

export function getSaldoBetweenMonthlyChartValueAndVolumeTotal(
    value: number | string | null | undefined,
    cellRendererParams: ICellRendererParams,
    chartValueKey: IEditChartCellProps['chartValueKey']
) {
    const year = cellRendererParams?.column?.getOriginalParent()?.getColGroupDef()?.headerName
    const monthName = cellRendererParams?.colDef?.headerName as keyof typeof MONTH_NAME_TO_MONTH_YEAR_ORDER
    const month = MONTH_NAME_TO_MONTH_YEAR_ORDER[monthName]
    const volumeTotal = cellRendererParams?.data?.volumeTotal || 0
    const currentValue =
        typeof value == 'string' ? Number(value?.replaceAll(' ', '')?.replaceAll(',', '.')) || 0 : value || 0

    let totalVolumeExcludeEditingMonth = 0

    cellRendererParams?.api.getValue('monthlyCharts', cellRendererParams.node).forEach((chart: monthlyChart) => {
        if (chart.year === Number(year) && chart.month === month) {
            return
        } else {
            totalVolumeExcludeEditingMonth += (chart[chartValueKey as keyof monthlyChart] as null | number) || 0
        }
    })
    const saldo = Number((totalVolumeExcludeEditingMonth + currentValue - volumeTotal).toFixed(6))

    return isNaN(saldo) ? 0 : saldo
}

export const submitKSGMonthlyChart = (args: IOnSubmitChartDataArgs) => {
    const { cellRendererParams, formData, chartValueKey, chartIndex, projectID, dispatch } = args

    const targetMonthlyChart = cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[
        chartIndex as number
    ]

    let updatedChart = {
        [chartValueKey]: parseStringToNumber(formData[chartValueKey]),
        ...(chartValueKey === 'plan' && {
            fact: targetMonthlyChart.fact,
        }),
        month: targetMonthlyChart?.month,
        year: targetMonthlyChart?.year,
    } as Partial<monthlyChart>

    if (chartValueKey === 'verifiedFact') {
        delete updatedChart.fact
        delete updatedChart.verifiedFact
        updatedChart.verFact = parseStringToNumber(formData[chartValueKey])
    }

    const chartForReqBody = {
        toUpdate: [
            {
                workID: cellRendererParams?.data?.id,
                charts: [updatedChart],
            },
        ],
    } as IUpdateChartBodyReq

    req.post(getTargetURLByValueType(chartValueKey, projectID?.toString() || '') as string, chartForReqBody)
        .then(({ data }) => {
            cellRendererParams.api.applyTransaction({ update: data.data })
            dispatch(onEditAgKsg(data.data))
            onSubmitSuccess(args)
        })
        .catch((e) => {
            onSubmitRejected(args)
        })
}
