import { Typography, Tab, Box, Button } from '@mui/material'
import { ProgressBar } from '../components/ProgressBar/ProgressBar'
import { COLOR_STATUS, RDStatus, getRDColorStatus } from '../components/RDStatus/RDStatus'
import { StatItem } from '../components/StatItem/StatItem'
import { WorkStatus } from '../components/StatItem/StatItem.styles'
import {
    PageWrapper,
    CardWrapper,
    CardTitle,
    CardItemsGroup,
    StatItemGroup,
    SubPageWrapper,
    TabsContained,
    StyledBtn,
    TextTabs,
    OverflowableTypography,
} from '../components/components.styles'
import { Link, Outlet, useLocation, useNavigate, useNavigation, useParams, useSearchParams } from 'react-router-dom'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { ArrowBack, CloudDownload, Delete, Downloading } from '@mui/icons-material'
import financeStats from '../static/finance.svg'
import { KsgAPI } from '../api/KsgAPI'
import { Work } from '../../../api/ksg/ksg.def'
import { COLORS } from '../components/PercentStatusValue/PercentStatusValue.style'
import { EMPTY_STRING_PLUG } from '../global/variables'
import { Plug } from '../components/Plug/Plug'
import { IKsgWorkResponse } from '../api/api.types'
import { OverflowableTypographyWithTooltip } from '../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { FlexColumnWrapper, FlexRowWrapper } from '../../NewExecutorView/components/components.styles'
import { getWorkPeriod, getExecutorFullName } from './WorkManagmentLayout.service'
import { TWorkManagmentSubPages, TEstimateSubPages } from './WorkManagmentLayout.def'
import { CopyResourcesDrawer } from '../components/CopyResourcesDrawer/CopyResourcesDrawer'
import { TCopyResourceMode } from '../components/CopyResourcesDrawer/CopyResourcesDrawer.def'
import { drawersSelector } from '../../../store/slices/drawersSlice'
import { useTypedSelector } from '../../../store/store'
import { useSnackbar } from 'notistack'
import { req } from '../api/api'
import { Buffer } from 'buffer'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../components/ConfirmDialog/ConfirmDialog'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'
import { profileSelector } from '../../../store/slices/profileSlice'

export function WorkManagmentLayout() {
    const location = useLocation()
    const navigate = useNavigate()
    const { projectId, workID } = useParams()
    const { profile } = useTypedSelector(profileSelector)

    const [activeSubPage, setActiveSubPage] = useState<TWorkManagmentSubPages>('stats')
    const [activeEstimateSubPage, setActiveEstimateSubPage] = useState<TEstimateSubPages>('resources')
    const [workInfo, setWorkInfo] = useState<Work>()
    const [executor, setExecutor] = useState<IKsgWorkResponse['executor'] | null>(null)
    const [copyDrawerMode, setCopyDrawerMode] = useState<TCopyResourceMode | false>(false)
    const {
        workManagmentResourcesGridRefs: { staff, mim, mto, setTriggerRefresh },
    } = useTypedSelector(drawersSelector)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('common')
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const subPage = (location.pathname.split('/')[4] as TWorkManagmentSubPages) || ''

    function downloadMobilizationSchedule() {
        req.get(`/projects/${projectId}/works/${workID}/cabinet/mobilization/export?${searchParams.toString()}`)
            .then(({ data }) => {
                const a = document.createElement('a')
                a.href = URL.createObjectURL(
                    new Blob([Buffer.from(data as string, 'base64')], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                )
                a.download = `График мобилизации ${
                    searchParams.get('month') ? searchParams.get('month') + '.' : ''
                }${searchParams.get('year')}.xlsx`
                a.click()
            })
            .catch((e) => {
                enqueueSnackbar(e?.response?.data ? t('errors.' + e?.response?.data) : 'Ошибка', { variant: 'error' })
            })
    }

    function deleteAllResources() {
        req.post(`/projects/${projectId}/works/${workID}/cabinet/resources/delete-all`)
            .then(() => setTriggerRefresh && setTriggerRefresh((prevState) => !prevState))
            .catch((e) => enqueueSnackbar('Ошибка', { variant: 'error' }))
    }

    function handleCopy(copyType: TCopyResourceMode) {
        let errorText = ''
        let isCopyAvailable = false
        switch (copyType) {
            case 'from': {
                errorText = 'Чтобы скопировать данные из других работ, удалите ресуры'
                isCopyAvailable =
                    (staff?.api?.getDisplayedRowCount() || 0) === 0 && (mim?.api?.getDisplayedRowCount() || 0) === 0
                break
            }
            case 'into': {
                errorText = 'Чтобы скопировать данные в другую работу, внесите ресурсы'
                isCopyAvailable =
                    (staff?.api?.getDisplayedRowCount() || 0) > 0 || (mim?.api?.getDisplayedRowCount() || 0) > 0
                break
            }
            case 'delete': {
                errorText = 'У Вас пока нет кабинетов работ, заполненных ресурсами'
                isCopyAvailable =
                    (staff?.api?.getDisplayedRowCount() || 0) === 0 || (mim?.api?.getDisplayedRowCount() || 0) === 0
                break
            }
        }
        if (!isCopyAvailable) {
            enqueueSnackbar(errorText, {
                variant: 'error',
                autoHideDuration: 3500,
            })
            return
        }
        setCopyDrawerMode(() => copyType)
    }

    useEffect(() => {
        KsgAPI.get({
            projectID: projectId,
            workID: workID,
        }).then(({ data, executor }) => {
            setWorkInfo((prevValue) => data)
            setExecutor((prevValue) => executor)
            localStorage.setItem('work', JSON.stringify(data))
        })
    }, [])

    useEffect(() => {
        return () => {
            localStorage.removeItem('work')
        }
    }, [])

    useEffect(() => {
        setActiveSubPage((prev) => subPage || '')
        subPage == 'estimate' &&
            setActiveEstimateSubPage((prev) => (location.pathname.split('/')[5] as TEstimateSubPages) || '')
    }, [location])

    return (
        <PageWrapper>
            <CardWrapper overflow={'auto'}>
                <Box display={'flex'} alignItems={'center'} gap={2}>
                    <Link
                        to={location.state?.prevPath || `/ksg/${projectId}`}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowBack fontSize="medium" color="primary" sx={{ cursor: 'pointer' }} />
                    </Link>
                    <CardTitle size="large">
                        <OverflowableTypographyWithTooltip maxRows={2} TypographyProps={{ color: 'primary' }}>
                            {`№${workInfo?.numOrder || ''}. ${workInfo?.workName || ''}`}
                        </OverflowableTypographyWithTooltip>
                    </CardTitle>
                </Box>
                <CardItemsGroup>
                    <StatItemGroup>
                        <StatItem variant={'column'} title="Группа работ" value={workInfo?.workGroup} />
                        <StatItem variant={'column'} title={'Титул'} value={workInfo?.objTitle} />
                    </StatItemGroup>
                    <StatItem variant={'row'} title={'Период работ'} value={workInfo && getWorkPeriod(workInfo)} />
                    <StatItem
                        variant={'row'}
                        title={'Статус работы'}
                        customValueComponent={<WorkStatus>Выполняется</WorkStatus>}
                    />
                    <StatItem
                        variant={'row'}
                        title={'Прогресс'}
                        customValueComponent={
                            <ProgressBar
                                currentValue={workInfo?.volumeDoneFact || 0}
                                targetValue={workInfo?.volumeTotal || 0}
                            />
                        }
                    />
                    <StatItem variant={'column'} title={'Единица измерения'} value={workInfo?.unit} />
                    <StatItem
                        variant={'column'}
                        title={'Объект'}
                        value={workInfo?.objName}
                        // customValueComponent={<OverflowableTypography color={"primary"}>{workInfo?.objName}</OverflowableTypography>}
                    />
                </CardItemsGroup>
                {/* <Plug
                    sx={{
                        '#plug': {
                            bottom: 1,
                            right: 1,
                            padding: 2,
                        },
                    }}
                >
                    <CardItemsGroup marginY={'auto'}>
                        <img src={financeStats} alt="График финансов" />
                    </CardItemsGroup>
                </Plug> */}
                <CardItemsGroup
                    bgcolor={
                        COLORS[getRDColorStatus(workInfo?.rdStatus || 'Не в работе', COLOR_STATUS)].backgroundColor
                    }
                    p={2}
                >
                    <StatItem
                        variant={'row'}
                        title={`РД версия (${workInfo?.rdVersion})`}
                        customValueComponent={<RDStatus rdStatus={workInfo?.rdStatus || 'Не в работе'} />}
                    />
                    <StatItemGroup>
                        <StatItem variant={'column'} title="Раздел" value={workInfo?.rdGroup} />
                        <StatItem variant={'column'} title={'Шифр'} value={workInfo?.rdCode} />
                    </StatItemGroup>
                    <StatItemGroup>
                        <StatItem variant={'column'} title="Дата выдачи" value={workInfo?.rdDate} />
                        <StatItem variant={'column'} title={'Дата согласования'} value={workInfo?.rdDateApproval} />
                    </StatItemGroup>
                </CardItemsGroup>
                <StatItemGroup>
                    <StatItem variant={'column'} title="Подрядчик" value={workInfo?.contractorCompany} />
                    <StatItem variant={'column'} title={'Исполнитель'} value={getExecutorFullName(executor)} />
                </StatItemGroup>
            </CardWrapper>

            <SubPageWrapper>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <TextTabs
                        value={activeSubPage}
                        onChange={(e, value: TWorkManagmentSubPages) => {
                            setActiveSubPage((prevValue) => value)
                            navigate(
                                `/workManagment/${projectId}/${workID}/${
                                    value + (value == 'estimate' ? '/resources' : '')
                                }`,
                                {
                                    state: { prevPath: location.state?.prevPath },
                                }
                            )
                        }}
                    >
                        {/* <Tab value={'stats'} label={'ПОКАЗАТЕЛИ'} /> */}
                        <Tab value={'estimate'} label={'СМЕТА'} />
                        <Tab value={'resources'} label={'РЕСУРСЫ'} />
                        <Tab value={'mobilization'} label={'ГРАФИК МОБИЛИЗАЦИИ'} />
                    </TextTabs>
                    {activeSubPage == 'estimate' && (
                        <>
                            <TabsContained
                                value={activeEstimateSubPage}
                                onChange={(e, value: TEstimateSubPages) => {
                                    setActiveEstimateSubPage((prevValue) => value)
                                    navigate(`/workManagment/${projectId}/${workID}/estimate/${value}`)
                                }}
                            >
                                <Tab value={'resources'} label="Ресурсы"></Tab>
                                <Tab value={'positions'} label="Позиции"></Tab>
                            </TabsContained>

                            <Box display="flex" gap={2}>
                                <StyledBtn
                                    size="small"
                                    color={'secondary'}
                                    variant="outlined"
                                    startIcon={<CloudDownload />}
                                ></StyledBtn>
                                <StyledBtn
                                    size="small"
                                    color={'secondary'}
                                    variant="outlined"
                                    startIcon={<Downloading />}
                                >
                                    Загрузить .XLS
                                </StyledBtn>
                            </Box>
                        </>
                    )}
                    {activeSubPage === 'resources' &&
                        !workInfo?.hasChildren &&
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') && (
                            <FlexRowWrapper>
                                <StyledBtn
                                    size="small"
                                    color={'error'}
                                    variant="outlined"
                                    startIcon={<Delete />}
                                    onClick={() => setCopyDrawerMode(() => 'delete')}
                                    sx={{ lineHeight: '100%', height: '30.75px' }}
                                >
                                    Удалить ресурсы
                                </StyledBtn>
                                <StyledBtn
                                    size="small"
                                    color={'secondary'}
                                    variant="outlined"
                                    onClick={(e) => handleCopy('from')}
                                >
                                    Скопировать из
                                </StyledBtn>
                                <StyledBtn
                                    size="small"
                                    color={'secondary'}
                                    variant="outlined"
                                    onClick={(e) => handleCopy('into')}
                                >
                                    Скопировать в
                                </StyledBtn>
                            </FlexRowWrapper>
                        )}
                    {activeSubPage === 'mobilization' && (
                        <FlexRowWrapper>
                            <StyledBtn
                                size="small"
                                color={'secondary'}
                                variant="outlined"
                                startIcon={<Downloading />}
                                onClick={downloadMobilizationSchedule}
                            >
                                Скачать .XLS
                            </StyledBtn>
                        </FlexRowWrapper>
                    )}
                </Box>
                <FlexColumnWrapper width={'100%'} overflow={'auto'}>
                    <Outlet />
                </FlexColumnWrapper>
                <CopyResourcesDrawer
                    DrawerProps={{
                        open: !!copyDrawerMode,
                        onClose: () => setCopyDrawerMode(() => false),
                    }}
                    copyMode={copyDrawerMode || 'from'}
                />
            </SubPageWrapper>
            <ConfirmDialog
                open={isConfirmDialogOpen}
                title={'Вы уверены, что хотите удалить ресурсы?'}
                message={''}
                onYes={{
                    action: (e) => {
                        deleteAllResources()
                        setIsConfirmDialogOpen(() => false)
                    },
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDialogOpen(() => false)
                    },
                }}
            />
        </PageWrapper>
    )
}
