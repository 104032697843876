import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    queryTermsRequestType,
    queryTermsType,
    useGetFilterListMsgUnlimitedQuery,
    useGetFilterListUnlimitedQuery,
} from '../../api/filters/filters.api'
import { useTypedSelector } from '../../store/store'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { DebounceInput } from 'react-debounce-input'
import s from './CheckboxPopover.module.scss'
import { FaFilter } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import {
    filtersSelector,
    levels,
    levelsUnlimitedI,
    refreshFilters,
} from '../../store/slices/filtersSlice'
import CheckboxesPart, { customPropertiesByLevelFilters } from './CheckboxexPart'
import { newDate } from '../../store/slices/cpgViewSlice'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { agGridListMode, changeListMode } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { FlexColumnWrapper, FlexRowWrapper } from '../../pages/NewExecutorView/components/components.styles'
import InfoSvg from '../../shared/SVG/InfoSvg'
import { StyledTooltip } from '../../pages/WorkManagment/components/components.styles'

export default function CheckboxPopover({
    msg,
    showEmptyParams,
}: {
    msg?: boolean
    showEmptyParams?: {
        label?: string
        show: boolean
        checked: boolean
        onChange: (checked: boolean) => void
    }
}) {
    const [inputValue, setInputValue] = useState('')
    const { queryTerm, filters } = useTypedSelector(filtersSelector)
    const { month } = useTypedSelector(monthMsgSelector)
    const listMode = useTypedSelector(agGridListMode)

    const { projectId } = useParams()

    const termToReqTerm: Record<queryTermsType, queryTermsRequestType> = {
        contractorCompany: 'contractor',
        objName: 'obj',
        objTitle: 'title',
        rdCode: 'rd',
        rdStatus: 'status',
        workName: 'work',
        workGroup: 'group',
    }

    const filtersQueryKsgUnlimited = useGetFilterListUnlimitedQuery(
        {
            id: Number(projectId),
            term: termToReqTerm[queryTerm],
            filters: filters,
        },
        {
            skip: msg,
        }
    )

    const filtersQueryMsgUnlimited = useGetFilterListMsgUnlimitedQuery(
        {
            id: Number(projectId),
            term: termToReqTerm[queryTerm],
            filters: filters,
            year: newDate(month).getFullYear(),
            month: newDate(month).getMonth() + 1,
        },
        {
            skip: !msg,
        }
    )

    const filtersQuery = msg ? filtersQueryMsgUnlimited : filtersQueryKsgUnlimited

    const filteredObject = useMemo(() => {
        const obj = filtersQuery.data?.data
        if (!obj) return

        return Object.entries(obj).reduce((acc: levelsUnlimitedI, [key, valuesArr]) => {
            const filteredArray = (valuesArr as string[]).filter((filt) =>
                filt.toLowerCase().includes(inputValue.toLowerCase())
            )
            if (!!filteredArray.length) {
                acc[key as levels] = filteredArray
            }
            return acc
        }, {})
    }, [filtersQuery.data?.data, inputValue])

    const getTitle = (term: queryTermsType) => {
        const titlesRus: { [key: string]: string } = {
            objTitle: 'Титул',
            objName: 'Название объекта',
            rdCode: 'Шифр РД',
            rdStatus: 'Статус выдачи РД',
            contractorCompany: 'Подрядчик',
            workName: 'Наименование ',
            workGroup: 'Группа работ',
            brigades: 'Бригада'
        }
        return titlesRus[term]
    }

    const dispatch = useDispatch()
    if (filtersQuery.isLoading) {
        return <CircularProgress />
    }

    return (
        <main className={s.main}>
            <div className={s.top_section}>
                <div className={s.top}>
                    <div className={s.filterTitle}>
                        <FaFilter color="#0044b4" />
                        <h4>{getTitle(queryTerm)}</h4>
                    </div>
                    <Button
                        sx={{
                            color: '#7890B2',
                            padding: '0.1rem 0.5rem',
                            fontWeight: '400',
                        }}
                        onClick={() => dispatch(refreshFilters())}
                    >
                        Сбросить фильтр
                    </Button>
                </div>

                <div className={s.input}>
                    <DebounceInput
                        value={inputValue}
                        placeholder="Поиск"
                        debounceTimeout={300}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>
                        <SearchOutlinedIcon
                            sx={{
                                color: '#7890B2',
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={s.checkboxes}>
                {showEmptyParams?.show && (
                    <FlexRowWrapper justifyContent={'space-between'}>
                        <FormControlLabel
                            label={showEmptyParams.label || 'Показать пустые'}
                            sx={{
                                '.MuiSvgIcon-root': {
                                    fill: '#0044B4',
                                },
                            }}
                            control={
                                <Checkbox
                                    checked={showEmptyParams.checked}
                                    onChange={(e) => showEmptyParams.onChange(e?.target?.checked)}
                                />
                            }
                        />

                        <StyledTooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: 'rgba(151, 162, 181,0.85)',
                                        maxWidth: 220,
                                        fontSize: 12,
                                    },
                                },
                            }}
                            title={
                                <Typography fontSize={12} color={'white'}>
                                    Будут показаны работы <br />
                                    с незаполненной группой работ <br /> и базовыми датами
                                </Typography>
                            }
                        >
                            <div>
                                <InfoSvg
                                    viewBox="0 0 20 20"
                                    sx={{ color: '#668FD2', fontSize: '20px', cursor: 'pointer' }}
                                />
                            </div>
                        </StyledTooltip>
                    </FlexRowWrapper>
                )}
                <FormControlLabel
                    label={'Списком'}
                    sx={{
                        '.MuiSvgIcon-root': {
                            fill: '#0044B4',
                        },
                    }}
                    control={
                        <Checkbox checked={listMode} onChange={(e) => dispatch(changeListMode(e.target.checked))} />
                    }
                />

                {filteredObject && (
                    <FlexColumnWrapper
                        gap={0}
                        {...(showEmptyParams &&
                            showEmptyParams?.checked && {
                                sx: {
                                    pointerEvents: 'none',
                                    opacity: 0.3,
                                    filter: 'brightness(0.1)',
                                },
                                onClickCapture: (e) => {
                                    e?.stopPropagation()
                                    e.preventDefault()
                                },
                            })}
                    >
                        {Object.entries(filteredObject).map(([key, value]) => (
                            <CheckboxesPart
                                key={`${key} ${value}`}
                                level={key as levels}
                                arr={value}
                                color={customPropertiesByLevelFilters[key as levels].color}
                                closeAllCondition={showEmptyParams?.checked}
                            />
                        ))}
                    </FlexColumnWrapper>
                )}
            </div>
        </main>
    )
}
