import {
    CellClassParams,
    CellValueChangedEvent,
    ColDef,
    GridReadyEvent,
    GridSizeChangedEvent,
    ICellRendererParams,
    IHeaderGroupParams,
    IHeaderParams,
    SuppressKeyboardEventParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community'
import { CellEditor } from '../CellEditors/CellEditor/CellEditor'
import { NumberCellRenderer } from '../CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { ResourceAPI } from '../../api/ResourcesAPI'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { IconButton, TextareaAutosizeProps } from '@mui/material'
import { Add } from '@mui/icons-material'
import { DeleteRowRenderer } from '../CellRenderers/DeleteRowRenderer/DeleteRowRenderer'
import { EstimateAPI } from '../../api/EstimateAPI'
import { differenceInDays, parse } from 'date-fns'
import { IEditableCellDateParams } from './AgGrid.def'
import { NoRowsOverlayComponent } from 'ag-grid-community/dist/lib/rendering/overlays/noRowsOverlayComponent'
import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { LoadingOverlay } from './components/LoadingOverlay'
import { ILoadingOverlayParams } from 'ag-grid-community/dist/lib/rendering/overlays/loadingOverlayComponent'
import { preserveScroll } from '../../../../shared/utils'
import { Location } from 'react-router-dom'

export const COL_DEF_TEMPLATE = {
    EDITABLE_CELLS: {
        NUMBER: (key?: string, maxValue?: number) => ({
            cellEditor: CellEditor,
            cellRenderer: NumberCellRenderer,
            ...(maxValue && {
                cellEditorParams: {
                    validation: {
                        validate: {
                            maxValue: (v: string) => parseFloat(v) <= maxValue || `Макс. ${maxValue}`,
                        },
                    },
                },
            }),
            cellClass: (params: CellClassParams) => (IS_EDITABLE(params) && 'ag-cell-editable') || '',
        }),
        STRING: {
            cellRenderer: (params: ICellRendererParams) => (
                <OverflowableTypographyWithTooltip maxRows={3}>{params.value}</OverflowableTypographyWithTooltip>
            ),
            cellEditor: CellEditor,
            cellEditorParams: {
                dataType: 'text',
            },
            cellClass: (params: CellClassParams) => (IS_EDITABLE(params) && 'ag-cell-editable') || '',
        },
        AUTOCOMPLETE: {
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <OverflowableTypographyWithTooltip maxRows={3}>
                        {typeof params.value == 'string' ? params.value : params.value?.label}
                    </OverflowableTypographyWithTooltip>
                )
            },
            cellEditor: CellEditor,
            cellEditorParams: {
                dataType: 'autocomplete',
            },
            cellClass: (params: CellClassParams) => (IS_EDITABLE(params) && 'ag-cell-editable') || '',
            suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
                if (document.getElementsByClassName('MuiAutocomplete-popper')[0]) {
                    return true
                } else {
                    return false
                }
            },
        },
        DATE: ({ field, compareWithStartDate, compareWithEndDate, gridApi }: IEditableCellDateParams): ColDef => {
            return {
                cellEditor: CellEditor,
                cellEditorParams: {
                    dataType: 'date',
                    validation: {
                        validate: {
                            ...(compareWithEndDate && {
                                isBeforeEndDate: (v: Date) => {
                                    const endDate = gridApi?.getDisplayedRowAtIndex(
                                        gridApi?.getEditingCells()[0]?.rowIndex
                                    )?.data[compareWithEndDate]
                                    return endDate
                                        ? differenceInDays(parse(endDate, 'dd.MM.yyyy', new Date()), v) >= 0 ||
                                              'Дата начала не должна быть позже даты окончания'
                                        : true
                                },
                            }),
                            ...(compareWithStartDate && {
                                isAfterStartDate: (v: Date) => {
                                    const startDate = gridApi?.getDisplayedRowAtIndex(
                                        gridApi?.getEditingCells()[0]?.rowIndex
                                    )?.data[compareWithStartDate]
                                    return startDate
                                        ? differenceInDays(v, parse(startDate, 'dd.MM.yyyy', new Date())) >= 0 ||
                                              'Дата окончания не должна быть раньше даты окончания'
                                        : true
                                },
                            }),
                        },
                    },
                },
                cellClass: (params: CellClassParams) => (IS_EDITABLE(params) && 'ag-cell-editable') || '',
                valueGetter: (params: ValueGetterParams) => {
                    return params.data[field]
                },
                valueSetter: (params: ValueSetterParams) => {
                    params.api.applyTransaction({
                        update: [{ ...params.data, [field]: params.newValue }],
                    })
                    return true
                },
            } as ColDef
        },
        TEXTAREA: {
            cellRenderer: (params: ICellRendererParams) => (
                <OverflowableTypographyWithTooltip maxRows={3}>{params.value}</OverflowableTypographyWithTooltip>
            ),
            cellEditor: CellEditor,
            cellEditorParams: {
                dataType: 'textarea',
                TextFieldProps: {
                    multiline: true,
                    minRows: 1,
                    maxRows: 3,
                    sx: {
                        textarea: {
                            padding: '0px 8px !important',
                            textAlign: 'center',
                        },
                    },
                } as TextareaAutosizeProps,
            },
            cellClass: (params: CellClassParams) => (IS_EDITABLE(params) && 'ag-cell-editable') || '',
        },
    },
    WITHOUT_DECIMALS: {
        cellRendererParams: {
            NumericFormatProps: {
                fixedDecimalScale: false,
                decimalScale: 0,
            },
        },
        cellEditorParams: {
            NumericFormatProps: {
                fixedDecimalScale: false,
                decimalScale: 0,
            },
        },
    },
    WITH_GETTER_AND_SETTER: (field: string, subField?: string) => ({
        valueGetter: (params: ValueGetterParams) => {
            return !subField
                ? params.data && params.data[field]
                : params.data && params.data![field] && params.data![field][subField]
        },
        valueSetter: (params: ValueSetterParams) => {
            !subField
                ? params.api.applyTransaction({
                      update: [{ ...params.data, [field]: params.newValue }],
                  })
                : params.api.applyTransaction({
                      update: [
                          {
                              ...params.data,
                              [field]: {
                                  ...params.data[field],
                                  [subField]: params.newValue,
                              },
                          },
                      ],
                  })
            return true
        },
    }),
    SUFFIX_VALUE: (suffix = 'р', maxValue = 999999) => {
        return {
            cellEditorParams: {
                NumericFormatProps: {
                    suffix: ` ${suffix}`,
                },
                validation: {
                    validate: (v: string) => parseFloat(v) <= maxValue || `Макс. ${maxValue}`,
                },
            },
            cellRendererParams: {
                NumericFormatProps: {
                    suffix: ` ${suffix}`,
                },
            },
        }
    },
    ADD_NEW_ROW_TABLE_COL: (callBack: (params: IHeaderParams<any, any>) => void) => ({
        headerName: 'addNewRow',
        field: undefined,
        maxWidth: 40,
        editable: false,
        headerComponent: (params: IHeaderParams) => {
            return (
                <IconButton
                    disabled={params.api.isAnyFilterPresent()}
                    onClick={(e) => callBack(params)}
                    disableRipple
                    sx={{
                        p: 0,
                    }}
                >
                    <Add fontSize="small" />
                </IconButton>
            )
        },
        cellRenderer: (params: any) => {
            return params?.data?.id !== 'totalRow' && params?.rowIndex + 1
        },
    }),
    DELETE_ROW_TABLE_COL: (callBack: (params: ICellRendererParams<any, any>) => void) => ({
        headerName: 'deleteRow',
        field: undefined,
        maxWidth: 40,
        editable: false,
        headerComponent: (params: IHeaderParams) => <></>,
        cellRenderer: (params: ICellRendererParams) =>
            params?.data?.id !== 'totalRow' && <DeleteRowRenderer params={params} deleteCallBack={callBack} />,
    }),
}

export function AG_GRID_DEFAULT_PARAMS() {
    return {
        rowHeight: 40,
        headerHeight: 40,
        groupHeaderHeight: 40,
        gridOptions: {
            defaultColDef: {
                // suppressAutoSize: true,
                cellRenderer: COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING.cellRenderer,
                cellClass: (params) =>
                    params?.colDef?.cellEditor && params?.data?.id != 'totalRow' && ['ag-cell-editable'],
                autoHeight: true,
                autoHeaderHeight: true,
            },
            stopEditingWhenCellsLoseFocus: true,
            singleClickEdit: true,
            // suppressColumnMoveAnimation: false
        },
        onFirstDataRendered: (event) => {
            event.api.sizeColumnsToFit()
        },
        onGridSizeChanged: (event: GridSizeChangedEvent<any, any>) => {
            event.api.sizeColumnsToFit()
        },
        onViewportChanged: (event) => {
            event.api.sizeColumnsToFit()
        },
        onGridColumnsChanged: (event) => {
            event.api.sizeColumnsToFit()
        },
        overlayNoRowsTemplate: 'Чтобы добавить данные, нажмите "+" в заголовке таблицы',
        overlayLoadingTemplate: 'Загрузка...',
        animateRows: true,
        suppressDragLeaveHidesColumns: true,
    } as AgGridReactProps
}

export function AG_GRID_DEFAULT_PARAMS_KSG(location: Location) {
    return {
        rowHeight: 80,
        headerHeight: 40,
        groupHeaderHeight: 40,
        gridOptions: {
            defaultColDef: {
                cellRenderer: COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING.cellRenderer,
                headerComponent: (params: IHeaderGroupParams) => (
                    <OverflowableTypographyWithTooltip
                        maxRows={2}
                        TypographyProps={{
                            textAlign: 'center',
                            color: 'white',
                            width: '100%',
                        }}
                    >
                        {params.displayName}
                    </OverflowableTypographyWithTooltip>
                ),
            },
            defaultColGroupDef: {
                headerGroupComponent: (params: IHeaderGroupParams) => (
                    <OverflowableTypographyWithTooltip
                        maxRows={1}
                        TypographyProps={{
                            textAlign: 'center',
                            color: 'white',
                            width: '100%',
                        }}
                    >
                        {params.displayName}
                    </OverflowableTypographyWithTooltip>
                ),
            },
            singleClickEdit: true,
        },
        loadingOverlayComponent: (props: ILoadingOverlayParams) => <LoadingOverlay open />,
        overlayNoRowsTemplate: 'Нет данных',
        suppressScrollOnNewData: true,
        onFirstDataRendered: (event) => {
            preserveScroll(event, location)
        },
        suppressColumnMoveAnimation: true,
    } as AgGridReactProps
}

export function AG_GRID_RESOURCES_PARAMS(onCellValueChanged?: (params: CellValueChangedEvent) => void) {
    return {
        ...AG_GRID_DEFAULT_PARAMS(),
        onCellValueChanged: (params: CellValueChangedEvent) => {
            // const update = () =>
            if (params.colDef.field != 'nameProf') {
                ResourceAPI.updateItem({
                    resourceType: params.context.resourceType,
                    projectID: params.context.projectID,
                    workID: params.context.workID,
                    resourceID: params.data.id,
                    body: params.data,
                }).then(({ data }) =>
                    params.api.applyTransaction({
                        update: [data?.data],
                    })
                )
            }
        },
        onAsyncTransactionsFlushed: (params) => {
            if (params.results[0].update) {
                const data = params.results[0].update[0].data || {}
                ResourceAPI.updateItem({
                    resourceType: params.context.resourceType,
                    projectID: params.context.projectID,
                    workID: params.context.workID,
                    resourceID: data.id,
                    body: data,
                })
            }
        },
    } as AgGridReactProps
}

export function AG_GRID_ESTIMATE_PARAMS() {
    return {
        ...AG_GRID_DEFAULT_PARAMS(),
        onCellValueChanged: (params: any) => {
            EstimateAPI.updateItem({
                estimateType: params.context.estimateType,
                projectID: params.context.projectID,
                workID: params.context.workID,
                estimateItemID: params.data.id,
                body: params.data,
            })
        },
    }
}

export function disableAddAndDelete(isDisabled: boolean, AgGrid?: AgGridReact) {
    if (isDisabled) {
        return {
            onGridReady: (params: GridReadyEvent<any>) => {
                params?.api?.setColumnDefs(
                    (params?.api?.getColumnDefs() as any[]).map((colDef) => {
                        if (colDef.headerName == 'addNewRow' || colDef.headerName == 'deleteRow') {
                            return {
                                ...colDef,
                                headerName: '',
                                headerComponent: undefined,
                                ...(colDef.headerName == 'deleteRow' && {
                                    cellRenderer: null,
                                }),
                            }
                        } else {
                            return colDef
                        }
                    })
                )
                params.api?.refreshHeader()
            },
            overlayNoRowsTemplate: 'Данные отсутствуют',
        } as AgGridReactProps
    } else {
        return {}
    }
}

const IS_EDITABLE = (params: CellClassParams) => {
    if (params?.colDef?.editable !== undefined) {
        if (typeof params?.colDef?.editable == 'boolean') {
            return params?.colDef?.editable
        }
        if (typeof params?.colDef?.editable == 'function') {
            return params?.colDef?.editable(params)
        }
    }
}
