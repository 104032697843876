import s from '../NotificationsCenter.module.scss'
import { Button } from '@mui/material'
import React from 'react'

export default function ButtonsNotificationCenter({
    fontSize = '13px',
    border = '1px solid #0044B4',
    padding = '6px',
    increaseVolume,
    increaseTime,
    disabledVolume,
    disabledTime,
}: {
    fontSize?: string
    border?: string
    padding?: string
    increaseVolume: () => void
    increaseTime: () => void
    disabledVolume?: boolean
    disabledTime?: boolean
}) {
    const sxStyles = {
        fontSize,
        border,
        color: '#0044B4',
        backgroundColor: '#edf4fb',
        padding,
        boxShadow: 'unset',
        '&:hover': {
            backgroundColor: '#dbeafe',
        },
    }

    return (
        <div className={s.notifications_buttons}>
            <Button disabled={disabledVolume} onClick={increaseVolume} sx={sxStyles} variant="contained">
                Увеличить выработку
            </Button>
            <div></div>
            <Button disabled={disabledTime} onClick={increaseTime} sx={sxStyles} variant="contained">
                Увеличить срок
            </Button>
        </div>
    )
}
