import { ILibGanttLink, TLocalGanttLinkId } from '../DHTGant.def'
import { GanttStatic } from '../dhtmlxgantt'

type HandlersType = {
    onTaskDblClick: (taskId: number) => void
    onBeforeLinkAdd: (id: TLocalGanttLinkId, link: ILibGanttLink) => void
    onBeforeLinkDelete: (id: TLocalGanttLinkId, link: ILibGanttLink) => void
    onAfterTaskDrag: (taskId: number) => void
}
export function getGanttEventHandlers(gantt: GanttStatic, handlers: HandlersType) {
    if (!gantt) return []

    const events = []

    events.push(
        gantt.attachEvent('onGanttReady', function () {
            let tooltips = gantt.ext.tooltips
            tooltips.tooltip.setViewport(gantt.$task_data)
        })
    )
    events.push(gantt.attachEvent('onBeforeLinkAdd', handlers.onBeforeLinkAdd))
    events.push(gantt.attachEvent('onBeforeLinkDelete', handlers.onBeforeLinkDelete))
    events.push(
        gantt.attachEvent('onAfterTaskDrag', (id, mode) => {
            if (!['move', 'resize'].includes(mode)) return
            handlers.onAfterTaskDrag(Number(id))
        })
    )

    events.push(
        gantt.attachEvent('onGridResizeEnd', function (old_width, new_width) {
            let ganttContainer = document.getElementById('gantt') as HTMLDivElement
            const maxWidth = window.innerWidth * 0.7
            const minWidth = window.innerWidth * 0.2
            const currentWidth = ganttContainer.clientWidth

            function getMouseCursorPosition(e: MouseEvent) {
                let mouseCoordX = e.clientX - ganttContainer.offsetLeft
                if (ganttContainer) {
                    let newWidth = currentWidth - mouseCoordX
                    if (newWidth > maxWidth) {
                        newWidth = maxWidth
                    } else {
                        if (newWidth < minWidth) {
                            newWidth = minWidth
                        }
                    }
                    ganttContainer.style.width = newWidth + 'px'
                    console.log({
                        offsetLeft: ganttContainer.offsetLeft,
                        maxWidth: maxWidth,
                        minWidth: minWidth,
                        currentWidth: currentWidth,
                        mouseCoordX: mouseCoordX,
                        newWidth: newWidth,
                    })
                }
                document.removeEventListener('mousemove', getMouseCursorPosition)
            }

            document.addEventListener('mousemove', getMouseCursorPosition)
            gantt.setSizes()
        })
    )

    events.push(gantt.attachEvent('onTaskDblClick', handlers.onTaskDblClick))

    return events
}
