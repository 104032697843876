import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { newDate } from '../../store/slices/cpgViewSlice'
import { useTypedSelector } from '../../store/store'
import './smd.scss'
import { useInitMsgEquipMutation } from '../../api/msgEquip/msgEquip.api'
import { useInitMSGMutation } from '../../api/msg/msg.api'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { useInitMsgPeopleMutation } from '../../api/msgPeople/msgPeople.api'

type options = 'proportion' | 'zero' | 'nothing'

function ShowMsgDialog({
    people,
    equip,
    setTriggerRerender,
}: {
    people?: boolean
    equip?: boolean
    setTriggerRerender?: Dispatch<SetStateAction<boolean>>
}) {
    const [value, setValue] = useState<options>('proportion')

    const { month, year } = useTypedSelector(monthMsgSelector)

    const [initReqPeople, initPeopleRes] = useInitMsgPeopleMutation()
    const [initReqEquip, initEquipRes] = useInitMsgEquipMutation()
    const [initReqMsg, initMSGRes] = useInitMSGMutation()

    const { projectId } = useParams()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('cpg')

    const objectToSendInit = useMemo(
        () => ({
            id: Number(projectId),
            body: {
                year: year,
                month: month + 1,
                type: value,
            },
        }),
        [month]
    )
    // }

    const success = () => {
        enqueueSnackbar(t('success'), {
            variant: 'success',
        })
        if (setTriggerRerender) {
            setTriggerRerender((p) => !p)
        }
    }

    const error = (e?: any) => {
        enqueueSnackbar('Ошибка', {
            variant: 'error',
        })
        console.error(e)
    }

    function handleSubmit() {
        if (people) {
            initReqPeople(objectToSendInit).unwrap().then(success).catch(error)
        } else if (equip) {
            initReqEquip(objectToSendInit).unwrap().then(success).catch(error)
        } else {
            initReqMsg(objectToSendInit).unwrap().then(success).catch(error)
        }
    }

    if (initPeopleRes.isLoading || initEquipRes.isLoading || initMSGRes.isLoading) {
        return (
            <div style={{ padding: '4rem' }} className="progress">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="smd">
            <div className="smd__top">
                <h4>Создать МСГ</h4>
                <p>
                    Создание месячно-суточного графика из календарно-сетевого графика. Выберите пожалуйста способ
                    создания МСГ:{' '}
                </p>
            </div>
            <div className="smd__middle">
                <FormControl>
                    <RadioGroup value={value} onChange={(event) => setValue(event.target.value as options)}>
                        <FormControlLabel value="proportion" control={<Radio />} label="Пропорционально дням" />
                        <FormControlLabel value="nothing" control={<Radio />} label="Без распределения" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="smd__bottom">
                <p>
                    При пропорциональном способе, весь запланированный объем на месяц будет разнесен пропорционально
                    дням месяца, на каждый день.
                </p>
                <p>В любой момент времени, Вы можете изменить план работ на день.</p>
            </div>
            <div className="smd__buttons">
                <Button sx={{ width: '10rem' }} variant="contained" color="success" onClick={() => handleSubmit()}>
                    создать
                </Button>
                <Link to={`/ksg/${projectId}`}>
                    <Button sx={{ width: '10rem' }} variant="contained">
                        отменить
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default ShowMsgDialog
