import { Work } from '../../../api/ksg/ksg.def'
import ConfirmationModal from '../../../shared/components/ConfirmationModal/ConfirmationModal'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'
import g from '../../../shared/stylesheets/TableGrid.module.scss'
import { agGridListMode } from '../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { profileSelector } from '../../../store/slices/profileSlice'
import { useTypedSelector } from '../../../store/store'
import { stylesLevel } from '../../KsgTable/Ksg.service'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { IconButton } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Updater } from 'use-immer'

export function LevelRenderer({
    setHiddenRowsIds,
    arrowDown,
    onDeleteRow,
    setAddWorkId,
    data,
    isOfferActive,
}: {
    setHiddenRowsIds?: Updater<{ id: number; code: string }[]>
    arrowDown: boolean
    isOfferActive?: boolean
    onDeleteRow: (id: number) => void
    setAddWorkId: () => void
    data: Work | undefined
}) {
    const [openActionBar, setOpenActionBar] = useState(false)
    const [warningModal, setWarningModal] = useState(false)

    const listMode = useTypedSelector(agGridListMode)
    const { profile } = useTypedSelector(profileSelector)

    const ref = useRef<any>(null)

    const showArrows = useMemo(() => {
        if (data?.hasChildren || (data?.workPosition && isOfferActive)) {
            if (!arrowDown) {
                return (
                    <IconButton color="inherit" component="button">
                        <KeyboardArrowDownIcon />
                    </IconButton>
                )
            }
            return (
                <IconButton color="inherit" component="button">
                    <KeyboardArrowUpIcon />
                </IconButton>
            )
        }
        return null
    }, [arrowDown, data?.hasChildren])

    useEffect(() => {
        function handleClickOutside(evt: any) {
            if (ref.current && !ref.current.contains(evt.target)) {
                setOpenActionBar(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    const deleteWork = () => {
        setWarningModal(false)

        onDeleteRow(data?.id || 1)
    }

    const AddActionBarEl = () => {
        return (
            <div
                onClick={() => {
                    setAddWorkId()
                }}
                className={stylesLevel((data?.level || 0) + 1)}
                style={{
                    width: '2.8rem',
                    padding: '0.15rem 0.3rem 0.15rem 0.1rem',
                }}
            >
                <AddIcon
                    sx={{
                        color: '#7890b2',
                        fontSize: '1rem',
                    }}
                />
                УР{(data?.level || 0) + 1}
            </div>
        )
    }

    const DeleteActionBarEl = () => {
        return (
            <div className={data?.level !== 7 ? g.action_bar_delete_icon : ''} onClick={() => setWarningModal(true)}>
                <DeleteOutlineOutlinedIcon
                    sx={{
                        color: '#7890b2',
                        fontSize: '1.2rem',
                        marginTop: '0.1rem',
                    }}
                />
            </div>
        )
    }

    const actionBar = () => {
        const jsxElements = {
            addAndDelete: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                <div ref={ref} className={g.action_bar}>
                    <AddActionBarEl />
                    <DeleteActionBarEl />
                </div>
            ),
            delete: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                <div
                    ref={ref}
                    style={{
                        left: '3rem',
                    }}
                    className={g.action_bar}
                >
                    <DeleteActionBarEl />
                </div>
            ),
        }

        return data?.level === 7 ? jsxElements.delete : jsxElements.addAndDelete
    }

    return (
        <>
            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={deleteWork}
                title="Вы уверены что хотите удалить работу?"
                content={`После удаления работы ${data?.objTitle}, ${data?.level} уровня эти данные будут безвозвратно утеряны.`}
            />
            <div className={profile.role !== 'client' ? g.arrow_level_container : ''}>
                <div
                    onClick={() => {
                        if (setHiddenRowsIds) {
                            setHiddenRowsIds((d) => {
                                const addIndex = d.findIndex((hrId) => hrId.id === data?.id)
                                if (addIndex >= 0) {
                                    d.splice(addIndex, 1)
                                } else {
                                    d.push({ id: data?.id || 1, code: data?.code || '' })
                                }
                            })
                        }
                    }}
                    className={g.arrow_level}
                >
                    {!listMode && showArrows}
                </div>

                <h5
                    onClick={() => {
                        profile.role !== 'client' && setOpenActionBar(true)
                    }}
                    className={stylesLevel(data?.level as 1 | 2 | 3 | 4 | 5 | 6 | 7)}
                >
                    УР {data?.level}
                </h5>
                {openActionBar && actionBar()}
            </div>
        </>
    )
}
