import { Stack } from '@mui/material'
import { IGanttLegendProps } from './GanttLegend.def'
import { Rect, Text } from './GanttLegend.styles'

export const GanttLegend: React.FC<IGanttLegendProps> = () => {
    return (
        <Stack direction={'row'} gap={2.5} justifyContent={'center'}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Rect backgroundColor="#e5e6e9" />
                <Text>Базовый план</Text>
            </Stack>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Rect backgroundColor="#8dd4c8" />
                <Text>Оперативный план</Text>
            </Stack>
        </Stack>
    )
}
