import { AgGridEvent, GridSizeChangedEvent } from "ag-grid-community";

export const BREAKPOINTS = {
    TABLE: 950,
    LAYOUT: 750
}

export function isDesktopView (params: AgGridEvent) {
    const currentGridWidth = params.api.getHorizontalPixelRange().right
    return currentGridWidth >= BREAKPOINTS.TABLE
}

export function switchToMobileView(params: AgGridEvent, hiddenColumns: string[]) {
    params.columnApi.setColumnsVisible(hiddenColumns, isDesktopView(params))
    params.api.sizeColumnsToFit()
    params.api.setHeaderHeight(20)
};