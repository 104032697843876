import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { useSearchParams } from 'react-router-dom'
import { HtmlTooltip } from '../../../shared/components/HtmlTooltip'
import { GanttChartSearchParams } from '../../../shared/constants/gantt'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'
import { profileSelector } from '../../../store/slices/profileSlice'
import { useTypedSelector } from '../../../store/store'
import { GanttChartsModeIconButton } from './styles'

export type GanttChartsMode = 'on' | 'off'

const getGanttChartsMode = (searchParams: URLSearchParams): GanttChartsMode =>
    searchParams.has('g') || searchParams.has('gf') ? 'on' : 'off'

const DATA_BY_MODE = {
    on: {
        tooltipTitle: 'Выйти из режима "Диаграмма Ганта"',
    },
    off: {
        tooltipTitle: 'Включить режим "Диаграмма Ганта"',
    },
}

export default function GanttLinks() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { profile } = useTypedSelector(profileSelector)

    const ganttChartsMode = getGanttChartsMode(searchParams)

    const data = DATA_BY_MODE[ganttChartsMode] ?? DATA_BY_MODE['off']
    const factButtonVariant = searchParams.has(GanttChartSearchParams.Fact) ? 'contained' : 'outlined'

    const handleGanttChartsModeToggle = () => {
        setSearchParams((searchParams) => {
            if (getGanttChartsMode(searchParams) === 'on') {
                Object.values(GanttChartSearchParams).forEach((p) => searchParams.delete(p))
                return searchParams
            }

            searchParams.append(GanttChartSearchParams.Plan, 'on')
            return searchParams
        })
    }

    const handleFactClick = () => {
        setSearchParams((searchParams) => {
            if (searchParams.has(GanttChartSearchParams.Fact)) {
                searchParams.delete(GanttChartSearchParams.Fact)
                return searchParams
            }

            searchParams.set(GanttChartSearchParams.Fact, 'on')
            return searchParams
        })
    }

    return (
        <>
            {/* {ganttChartsMode === 'on' && (
                <Button onClick={handleFactClick} variant={factButtonVariant}>
                    ФАКТ
                </Button>
            )} */}
            {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'VIEW_GANT_SMR') && (
                <HtmlTooltip title={data.tooltipTitle}>
                    <GanttChartsModeIconButton
                        color="primary"
                        onClick={handleGanttChartsModeToggle}
                        mode={ganttChartsMode}
                    >
                        <StackedBarChartIcon />
                    </GanttChartsModeIconButton>
                </HtmlTooltip>
            )}
        </>
    )
}
