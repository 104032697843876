import { Popover } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { Fragment, useState } from 'react'
import { GanttWorkInfoEl } from '../../../components/GanttWorkInfoEl/GantWorkInfoEl'
import { SvgI } from '../../../shared/SVG/Svg'
import { StyledSpan } from './InfoWorkPeriod/InfoWorkPeriod.styles'

export function InfoWorkPeriod(cellRendererParams: ICellRendererParams) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    return (
        <div>
            <p>{cellRendererParams.value?.start}</p>
            <p>{cellRendererParams.value?.end}</p>
            {!cellRendererParams.data.hasChildren && (
                <Fragment>
                    <StyledSpan
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    >
                        <SvgI />
                    </StyledSpan>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        sx={{
                            '.MuiPaper-root': {
                                borderRadius: '1rem',
                            },
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <GanttWorkInfoEl
                            id={cellRendererParams.data?.id}
                            rdCode={cellRendererParams.data?.rdCode}
                            workName={cellRendererParams.data?.workName}
                            title={cellRendererParams.data?.objTitle}
                            object={cellRendererParams.data?.objName}
                        />
                    </Popover>
                </Fragment>
            )}
        </div>
    )
}
