export type tableFakeDataWork = {
    key: number
    workName: string
    accountableEmployee: string
    workPeriod: string
    duration: number
    status: string
    id: string
    rdCode: string
    title: string
    object: string
    stages: {
        key: number
        dateStart: string
        dateEnd: string
        dateEndFact: string
    }[]
}

export const romanize = (num: number) => {
    let digits = String(+num).split(''),
        key = [
            '',
            'C',
            'CC',
            'CCC',
            'CD',
            'D',
            'DC',
            'DCC',
            'DCCC',
            'CM',
            '',
            'X',
            'XX',
            'XXX',
            'XL',
            'L',
            'LX',
            'LXX',
            'LXXX',
            'XC',
            '',
            'I',
            'II',
            'III',
            'IV',
            'V',
            'VI',
            'VII',
            'VIII',
            'IX',
        ],
        roman = '',
        i = 3
    while (i--) roman = (key[+digits.pop()! + i * 10] || '') + roman
    return Array(+digits.join('') + 1).join('M') + roman
}