import { api } from '../api'
import { filtersKsgUnlimitedI, levelsUnlimitedI } from '../../store/slices/filtersSlice'

export type queryTermsType = 'contractorCompany' | 'objName' | 'objTitle' | 'rdCode' | 'rdStatus' | 'workName' | 'workGroup';
export type queryTermsRequestType = 'contractor' | 'obj' | 'title' | 'rd' | 'status' | 'work' | 'group';

export const filtersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFilterListUnlimited: build.query<
            { data: levelsUnlimitedI },
            { id: number; filters?: filtersKsgUnlimitedI & { filter?: string }; term: queryTermsRequestType }
        >({
            query: ({ id, filters, term }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/${term}`,
                method: 'POST',
                body: filters,
            }),
        }),

        getFilterListMsgUnlimited: build.query<
            { data: levelsUnlimitedI },
            {
                id: number
                filters?: filtersKsgUnlimitedI & { filter?: string }
                term: queryTermsRequestType
                year: number
                month: number
            }
        >({
            query: ({ id, filters, term, year, month }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/msg/${term}`,
                method: 'POST',
                params: { year, month },
                body: filters,
            }),
        }),

        getFiltersListPeople: build.query<{ data: string[] }, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/staff/filter/title`,
                method: 'POST',
            }),
        }),

        getFiltersListEquip: build.query<{ data: string[] }, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/tech/filter/title`,
                method: 'POST',
            }),
        }),

        getFiltersListMsgPeople: build.query<{ data: string[] }, { projectId: number; year: number; month: number }>({
            query: ({ projectId, year, month }) => ({
                url: `/projects/${projectId}/staff/filter/msg/title`,
                params: { year, month },
                method: 'POST',
            }),
        }),

        getFiltersListMsgEquip: build.query<{ data: string[] }, { projectId: number; year: number; month: number }>({
            query: ({ projectId, year, month }) => ({
                url: `/projects/${projectId}/tech/filter/msg/title`,
                params: { year, month },
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetFilterListMsgUnlimitedQuery,
    useGetFiltersListEquipQuery,
    useGetFiltersListPeopleQuery,
    useGetFiltersListMsgEquipQuery,
    useGetFiltersListMsgPeopleQuery,
    useGetFilterListUnlimitedQuery,
} = filtersApi
