import { COLORS } from "./PercentStatusValue.style";
import { StatValue } from "../StatValue/StatVallue";
import { IPercentStatusValue } from "./PercentStatusValue.types";

export function PercentStatusValue({ variant, value, TypographyProps, NumericFormatProps  }: IPercentStatusValue) {
    return (
        <StatValue
            value={value}
            NumericFormatProps ={{
                suffix: '%',
                ...NumericFormatProps 
            }}
            TypographyProps={{
                fontSize: 14,
                sx: {
                    width: 70,
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 500
                },
                ...COLORS[variant],
                ...TypographyProps
            }}
        />
    )
}