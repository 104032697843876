import { useEffect, useMemo, useState } from 'react'
import { Work, getHeadersRes } from '../../api/ksg/ksg.def'
import { TGanttLink } from '../../api/relations/relations.def'
import { req } from '../WorkManagment/api/api'
import { ILocalGanttLink } from './DHTGant.def'
import { createTask } from './DHTGantOnlyTable.utils'
import './customStylesOverrides.scss'
import './dhtmlxgantt.css'

export const useGetGanttEntities = (works: Work[] | null, projectId: string) => {
    const [links, setLinks] = useState<ILocalGanttLink[]>([])

    useEffect(() => {
        req.post(`/projects/${projectId}/dependencies/works/ksg/links`, {
            included: [],
        })
            .then(({ data }) => {
                const links = data.links as TGanttLink[]
                setLinks(() => links.map((link, index) => ({ ...link, id: index })))
            })
            .catch((err) => {
                console.log('error', err)
            })
    }, [projectId])

    const tasks = useMemo(() => {
        if (!works) return []
        return works?.map(createTask)
    }, [works])

    return {
        tasks,
        links,
    }
}

export const useGetProjectDates = (projectId: string) => {
    const [headerCPG, setHeaderCPG] = useState<getHeadersRes | null>(null)

    useEffect(() => {
        req.get(`/projects/${projectId}/headers`).then(({ data }) => {
            setHeaderCPG(() => data)
        })
    }, [projectId])

    useEffect(() => {
        return () => {
            //resetLayout()
            setHeaderCPG(null)
        }
    }, [projectId])

    const projectDates = useMemo(() => {
        if (!headerCPG) return null

        const firstHeader = headerCPG?.allHeaders.at(0)
        const lastHeader = headerCPG?.allHeaders.at(-1)
        if (!firstHeader || !lastHeader) return null

        // firstHeader?.month - 2 - добавляю дополнительный месяц в начале проекта, чтобы таски не прилипали к краю таблицы
        const start = new Date(firstHeader?.year, firstHeader?.month - 2, 1)
        const end = new Date(lastHeader?.year, lastHeader?.month + 1, 1)

        return {
            start,
            end,
        }
    }, [headerCPG])

    return projectDates
}
