import { Box, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import styled from 'styled-components'

export const StyledFormControlLabel = styled(FormControlLabel)`
    background-color: #f6f7fb;
    margin: 0;
    border-radius: 6px;
    padding-right: 1rem;
    width: 100%;
    display: flex;
    gap: 1;
    align-items: center;

    & span.MuiTypography-body1 {
        flex: 1;
    }
`

export const StyledButton = styled(Button)`
    background-color: #fe9b3f;
    color: #fff;
    flex-shrink: 0;
    transition: background-color 0.2s;

    &:hover {
        background-color: #e79b54;
    }
`

export const StyledRadioGroup = styled(RadioGroup)`
    gap: 0.75rem;
    font-size: 14px;
    letter-spacing: 0.1px;
    width: 100%;
`

export const StyledContainer = styled(Box)<{ height: number }>`
    overflow: auto;
    height: ${(props) => `calc(100vh - ${props.height}px)`};
    max-width: 367px;
    width: 100%;
`

export const StyledPlaceholder = styled(Box)`
    display: grid;
    place-content: center;
    color: #9aa2b0;
    height: 100%;
`
