import styled from 'styled-components'
import { WorkManagmentSubPageWrapper } from '../Stats/StatsPage.styles'
import { StatProgress } from '../../components/StatProgress/StatProgress'
import { IStatProgressProps } from '../../components/StatProgress/StatProgress.types'
import { Paper } from '@mui/material'

export const ResourcesPageWrapper = styled(WorkManagmentSubPageWrapper)`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    height: calc(100vh - 140px);
    @media screen and (max-width: 1450px) {
        grid-template-columns: 1fr;
        > div {
            grid-column: auto;
            overflow: visible;
            height: 350px;
        }
    }
`

export const StyledLoadingOverlay = styled(Paper)`
    background: white !important;
    position: absolute !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
`

export const StyledStatProgress = styled(StatProgress).attrs((props: Partial<IStatProgressProps>) => ({
    variant: 'row',
    separator: '/',
    NumericFormatProps: {
        suffix: ' ч.ч.',
        ...props.NumericFormatProps,
    },
    currentValue: {
        value: props.currentValue?.value,
        TypographyProps: {
            fontWeight: 500,
            color: 'error',
            ...props.currentValue?.TypographyProps,
        },
    },
    totalValue: {
        value: props.totalValue?.value,
        TypographyProps: {
            fontSize: 12,
            color: 'secondary',
        },
    },
}))``
