import { romanize } from '../../pages/GanttChart/GanttChart.service'
import s from './GanttWorkInfoEl.module.scss'
import EventNoteIcon from '@mui/icons-material/EventNote'
import React, { useState } from 'react'
import { useGetWorkPeriodsQuery } from '../../api/ksg/ksg.api'
import { useParams } from 'react-router-dom'
import { GanttChartIcon } from '../../shared/SVG/Svg'
import { ganttWorkPeriod } from '../../api/ksg/ksg.def'
import { tooltipHelper } from '../../shared/utils'
import { HtmlTooltip } from '../../shared/components/HtmlTooltip'

export const GanttWorkInfoEl = ({
    id,
    rdCode,
    workName,
    title,
    object,
}: {
    id: number
    rdCode: string | null
    workName: string
    title: string | null
    object: string | null
}) => {
    const [periodNum, setPeriodNum] = useState(0)

    const { projectId } = useParams()

    const periods = useGetWorkPeriodsQuery({
        projectId: Number(projectId),
        workId: id,
    })

    const getStatus = (per?: ganttWorkPeriod) => {
        if (!per) return
        if (per.fact === null || !per.plan) return '-'
        if (per.fact > 0) {
            if (per.fact < per.plan) {
                return 'В работе'
            } else {
                return 'Выполнено'
            }
        } else {
            return 'Не в работе'
        }
    }

    return (
        <div className={s.work_info_popover}>
            <div className={s.top_text}>
                <h4>{`ID: ${id}, Шифр РД: ${rdCode || '-'}`}</h4>
            </div>
            <HtmlTooltip title={tooltipHelper(workName)}>
                <h4 className={s.work_name}>{workName}</h4>
            </HtmlTooltip>
            <div className={s.object_title}>
                <p>Титул, подобъект</p>
                <HtmlTooltip title={`${title || '-'}, ${object || '-'}`}>
                    <h4>{`${title || '-'}, ${object || '-'}`}</h4>
                </HtmlTooltip>
            </div>
            <div className={s.roman_numbers_tabs}>
                {periods.data?.periods.map((period) => (
                    <div
                        onClick={() => setPeriodNum(period.num - 1)}
                        key={period.num}
                        className={s.roman_num_tab + ' ' + (period.num === periodNum + 1 ? s.active_tab : '')}
                    >{`${romanize(period.num)} ЭТАП`}</div>
                ))}
            </div>
            <div className={s.work_info_bottom}>
                <div className={s.work_info_dates}>
                    <div className={s.date_item}>
                        <div className={s.date_icon}>
                            <EventNoteIcon
                                sx={{
                                    fontSize: '1rem',
                                    color: '#6D9ADC',
                                }}
                            />
                        </div>
                        <div>
                            <p>Дата окончания</p>
                            <h4>{periods.data?.periods[periodNum]?.end || '-'}</h4>
                        </div>
                    </div>
                    <div className={s.date_item}>
                        <div className={s.date_icon}>
                            <EventNoteIcon
                                sx={{
                                    fontSize: '1rem',
                                    color: '#6D9ADC',
                                }}
                            />
                        </div>

                        <div>
                            <p>Дата начала</p>
                            <h4>{periods.data?.periods[periodNum]?.start || '-'}</h4>
                        </div>
                    </div>
                    <div className={s.date_item}>
                        <div className={s.date_icon}>
                            <GanttChartIcon />
                        </div>

                        <div>
                            <p>План / Факт</p>
                            <h4>{`${periods.data?.periods[periodNum]?.plan?.toFixed(2) || '-'} / ${
                                periods.data?.periods[periodNum]?.fact?.toFixed(2) || '-'
                            }`}</h4>
                        </div>
                    </div>
                </div>
                <div className={s.work_info_bottom_right}>
                    <div>
                        <p>Статус:</p>
                        <h4>{getStatus(periods.data?.periods[periodNum])}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
