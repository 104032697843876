import { Input } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { AiOutlineSearch } from 'react-icons/ai'
import { ButtonsAndWarningEl } from '../../../../shared/components/ButtonsAndWarning/ButtonsAndWarning'
import { syncInput } from '../../Synchronize.service'
import { SyncList } from '../SyncList'
import { ISyncProjectsProps } from './SyncProjects.def'
import { useSyncProjects } from './SyncProjects.model'
import { Description, ProgressContainer, Wrapper } from './SyncProjects.styles'

export const SyncProjects: React.FC<ISyncProjectsProps> = (props) => {
    const {
        project,
        inputValue,
        height,
        isListDataLoading,
        moduleName,
        isFormSubmitLoading,
        projects,
        moduleProjectId,
        onInputChange,
        onSubmit,
        onProjectSelect,
        onClose,
        onUnbind,
    } = useSyncProjects(props)

    const renderList = () => {
        if (isListDataLoading) {
            return (
                <ProgressContainer minHeight={moduleName === 'PragmaOffer' ? 346 : 386}>
                    <CircularProgress />
                </ProgressContainer>
            )
        }

        return (
            <SyncList
                projects={projects!}
                handleChangeProject={onProjectSelect}
                project={project}
                handleUnbind={onUnbind}
                moduleProjectId={moduleProjectId}
                height={height}
            />
        )
    }

    return (
        <Wrapper>
            <Description>
                Выберете проект, в рамках которого Вы сможете получать актуальные данные через запросы.
            </Description>

            <Input
                disableUnderline={true}
                sx={syncInput}
                value={inputValue}
                onChange={onInputChange}
                placeholder="Поиск"
                startAdornment={<AiOutlineSearch fontSize={'1.5rem'} color="rgb(92, 110, 140)" />}
            />

            {renderList()}

            <ButtonsAndWarningEl
                disabled={isFormSubmitLoading}
                onClose={onClose}
                onSubmit={onSubmit}
                submitText={isFormSubmitLoading ? 'Загружается' : 'Отправить'}
                cancelText="Закрыть"
            />
        </Wrapper>
    )
}
