import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { OverflowableTypographyWithTooltip } from "../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip"
import { theme } from "../../../../styles/theme"
import { IMainTableFilterOption } from "./MainTableFilter.def"
import { ICellRendererParams } from "ag-grid-community"

export function FilterOptionCellRenderer(props: ICellRendererParams<IMainTableFilterOption>) {
    return (
        <FormGroup>
            <FormControlLabel
                sx={{
                    '.MuiTypography-root': {
                        textAlign: 'left',
                    },
                    '.MuiCheckbox-root': {
                        pl: 1,
                    },
                }}
                control={
                    <Checkbox
                        disableRipple
                        color={'primary'}
                        checked={props.data?.checked}
                        onChange={(e, checked) => {
                            props.api.applyTransaction({
                                update: [
                                    {
                                        ...(props?.data as IMainTableFilterOption),
                                        checked: checked,
                                    },
                                ],
                            })
                            props.api.redrawRows({
                                rowNodes: [props.node],
                            })
                        }}
                        sx={{
                            svg: {
                                path: {
                                    fill: theme.palette.primary.main,
                                },
                            },
                        }}
                    />
                }
                label={
                    <OverflowableTypographyWithTooltip maxRows={1}>
                        {props.data?.label}
                    </OverflowableTypographyWithTooltip>
                }
            />
        </FormGroup>
    )
}
