import React, { SetStateAction } from 'react'
import s from '../BigEnterPlan.module.scss'

export default function SmallGlassButtonsInputEl({
    inputType,
    setInputType,
}: {
    inputType: 'unit' | 'percent'
    setInputType: React.Dispatch<SetStateAction<'unit' | 'percent'>>
}) {
    return (
        <div className={s.buttons_input}>
            <button
                type={'button'}
                onClick={() => setInputType('unit')}
                className={inputType === 'unit' ? s.act : s.not_act}
            >
                ЕД
            </button>
            <button
                type={'button'}
                onClick={() => setInputType('percent')}
                className={inputType === 'percent' ? s.act : s.not_act}
            >
                %
            </button>
        </div>
    )
}
