import styled from 'styled-components'

export const StyledSpan = styled('span')`
    position: absolute;
    right: 1rem;
    width: 1.2rem;
    height: 1.2rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
`
