import {
    Button,
    CircularProgress,
    Dialog,
    Typography,
} from '@mui/material'
import { FlexColumnWrapper, FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { FaFilter } from 'react-icons/fa'
import { memo, useEffect, useRef, useState } from 'react'
import { IMainTableFilterOption, IMainTableFilterProps } from './MainTableFilter.def'
import { theme } from '../../../../styles/theme'
import { StyledBtn } from '../../../WorkManagment/components/components.styles'
import { AgGridReact } from 'ag-grid-react'
import { store } from '../../../../store/store'
import { useMainTableFilterSearch } from './useMainTableFilterSearch'
import { FilterOptionCellRenderer } from './FilterOptionCellRenderer'

export const MainTableFilter = memo(
    ({ title, onFilterOpen, onFilterReset, onFilterApply }: IMainTableFilterProps) => {
        const [openDialog, setOpenDialog] = useState(false)
        const { SearchTextfieldComponent, search } = useMainTableFilterSearch()
        const [options, setOptions] = useState<IMainTableFilterOption[]>([])
        const [isLoading, setIsLoading] = useState(false)
        const gridRef = useRef<AgGridReact>(null)

        useEffect(() => {
            gridRef.current?.api.setQuickFilter(search)
        }, [search])

        function resetFilter() {
            onFilterReset
                ? onFilterReset(setOptions)
                : setOptions((prevState) =>
                      prevState.map((option) => ({
                          ...option,
                          checked: false,
                      }))
                  )
        }

        function openFilterDialog() {
            setIsLoading(true)
            setOpenDialog(() => true)
            onFilterOpen(setOptions, setIsLoading)
        }

        function applyFilter() {
            const checkedOptions: IMainTableFilterOption[] = []
            gridRef?.current?.api.forEachNode((row) => {
                row.data.checked && checkedOptions.push(row.data)
            })
            onFilterApply(checkedOptions)
        }

        return (
            <FlexColumnWrapper width={'100%'}>
                <FlexRowWrapper justifyContent={'space-between'} width={'initial'} alignItems={'center'}>
                    <Typography color="white">{title}</Typography>
                    <FaFilter
                        color={store.getState().filters.filters.brigades.length > 0 ? '#6FCCBC' : 'white'}
                        cursor={'pointer'}
                        onClick={openFilterDialog}
                    />
                </FlexRowWrapper>
                <Dialog open={openDialog} onClose={() => setOpenDialog(() => false)}>
                    <FlexColumnWrapper p={2} minWidth="373px" height={'auto'}>
                        <FlexRowWrapper alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                            <FlexRowWrapper>
                                <FaFilter color={theme.palette.primary.main} />
                                <Typography variant={'body1'} fontWeight={'bold'} color={'primary'}>
                                    {title}
                                </Typography>
                            </FlexRowWrapper>
                            <Button
                                sx={{
                                    color: '#7890B2',
                                    padding: '0.1rem 0.5rem',
                                    fontWeight: '400',
                                }}
                                onClick={resetFilter}
                            >
                                Сбросить фильтр
                            </Button>
                        </FlexRowWrapper>
                        {SearchTextfieldComponent}
                        {isLoading ? (
                            <CircularProgress size={50} sx={{ margin: '0 auto' }} />
                        ) : (
                            <FlexColumnWrapper minHeight={'50px'} height={300}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={options}
                                    headerHeight={0}
                                    rowHeight={42}
                                    getRowId={(params) => {
                                        return params.data.value
                                    }}
                                    columnDefs={[
                                        {
                                            cellStyle: {
                                                placeContent: 'unset',
                                            },
                                            cellClass: 'ag-cell-left',
                                            headerName: 'label',
                                            field: 'label',
                                            flex: 2,
                                            cellRenderer: FilterOptionCellRenderer,
                                        },
                                    ]}
                                />
                            </FlexColumnWrapper>
                        )}
                        <StyledBtn fullWidth variant="contained" onClick={applyFilter}>
                            Применить
                        </StyledBtn>
                    </FlexColumnWrapper>
                </Dialog>
            </FlexColumnWrapper>
        )
    },
    () => true
)
