import { useCallback, useEffect, useState } from 'react'
import { req } from '../../pages/WorkManagment/api/api'
import { useParams } from 'react-router-dom'
import { IActiveUploadTask } from '../def'
import { useSnackbar } from 'notistack'
import { onKSGUpload } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { drawersSelector, toggleUploadProgress } from '../../store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'

export default function useProjectUploadProgress({
    supressOnClose,
    supressWatchBtn,
}: {
    supressOnClose: boolean
    supressWatchBtn: boolean
}) {
    const { projectId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [activeTask, setActiveTask] = useState<IActiveUploadTask | null>(null)
    const [triggerUpdateStatus, setTriggerUpdateStatus] = useState<boolean>(false)
    const [result, setResult] = useState<IActiveUploadTask['result'] | null>(null)
    const dispatch = useAppDispatch()
    const drawers = useTypedSelector(drawersSelector)

    function handleError(e: { response: { data: any } }) {
        enqueueSnackbar(e?.response?.data || 'Ошибка', { variant: 'error' })
    }

    const refreshProgressStatus = useCallback(() => {
        if (!!activeTask?.uuid && !activeTask.result) {
            req.get(`/projects/${projectId}/works/upload-get-active-progress?uuid=${activeTask.uuid}`)
                .then(({ data }) => {
                    setActiveTask(() => data)
                    setResult(() => data?.result)
                })
                .catch((e) => {
                    handleError(e)
                    setActiveTask(null)
                })
        } else {
            req.get(`/projects/${Number(projectId)}/works/get-active-upload`)
                .then(({ data }) => setActiveTask(() => data.task))
                .catch(handleError)
        }
    }, [enqueueSnackbar, projectId, activeTask])

    useEffect(() => {
        if (
            !result &&
            !!activeTask &&
            drawers.uploadProgress.openDialog !== 'wrapped' &&
            !drawers.uploadKsg &&
            !drawers.updateKSG
        ) {
            dispatch(
                toggleUploadProgress({
                    openDialog: !!activeTask,
                    supressOnClose: supressOnClose,
                    supressWatchBtn: supressWatchBtn,
                    activeTask: activeTask,
                })
            )
        }
        if (!!result) {
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            )
            if (!!result?.success) {
                dispatch(onKSGUpload())
            }
        }
    }, [activeTask, result])

    useEffect(() => {
        let interval: NodeJS.Timer
        if (triggerUpdateStatus) {
            interval = setInterval(() => {
                refreshProgressStatus()
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [activeTask?.uuid, refreshProgressStatus, triggerUpdateStatus])

    return {
        activeTask: activeTask,
        setActiveTask: setActiveTask,
        setTriggerUpdateStatus: setTriggerUpdateStatus,
        result: result,
    }
}
