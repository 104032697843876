import s from './NotificationsCenter.module.scss'
import React, { useMemo } from 'react'
import FeedbackIcon from '@mui/icons-material/Feedback'
import {
    useNotificationsIncreasePeriodMutation,
    useNotificationsIncreaseVolumeMutation,
    useNotificationsListQuery,
    useNotificationsLookMutation,
} from '../../api/notificationsCenter/notificationsCenter.api'
import { useParams } from 'react-router-dom'
import { getDateTimeString } from '../../shared/utils'
import { Button, CircularProgress, LinearProgress, Pagination } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import ButtonsNotificationCenter from './components/ButtonsNotificationsCenter'
import AutorenewIcon from '@mui/icons-material/Autorenew'

export default function NotificationsCenter() {
    const { projectId } = useParams()
    const [page, setPage] = React.useState(1)

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const notificationsList = useNotificationsListQuery({
        projectId: Number(projectId),
        limit: 20,
        offset: (page - 1) * 20,
    })
    const [increasePeriodReq, increasePeriodRes] = useNotificationsIncreasePeriodMutation()
    const [increaseVolumeReq, increaseVolumeRes] = useNotificationsIncreaseVolumeMutation()
    const [lookReq] = useNotificationsLookMutation()

    const increaseVolumeFunc = (ids: number[] | undefined) => {
        if (!ids) return
        increaseVolumeReq({ projectId: Number(projectId), body: { alertIds: ids } })
            .unwrap()
            .then((res) => {
                if (res.success) {
                    enqueueSnackbar(t('notifications_volume_increased'), {
                        variant: 'success',
                    })
                } else {
                    enqueueSnackbar(res.description, {
                        variant: 'error',
                    })
                }
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const increasePeriodFunc = (ids: number[] | undefined) => {
        if (!ids) return

        increasePeriodReq({ projectId: Number(projectId), body: { alertIds: ids } })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('notifications_period_increased'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const lookNotifications = () => {
        lookReq({
            projectId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('look_notifications'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    const availableToIncreaseIdsVol = useMemo(() => {
        return notificationsList.data?.data.reduce((acc: number[], item) => {
            if (item.availableInc.volume) {
                return [...acc, item.alertId]
            }
            return acc
        }, [])
    }, [notificationsList.data?.data])

    const availableToIncreaseIdsPer = useMemo(() => {
        return notificationsList.data?.data.reduce((acc: number[], item) => {
            if (item.availableInc.period) {
                return [...acc, item.alertId]
            }
            return acc
        }, [])
    }, [notificationsList.data?.data])

    return (
        <>
            {(increasePeriodRes.isLoading || increaseVolumeRes.isLoading) && (
                <div className={s.progress_abs}>
                    <LinearProgress />
                </div>
            )}
            <div className={s.header}>
                <div></div>
                <p>Центр уведомлений</p>

                <Button
                    size="small"
                    onClick={lookNotifications}
                    variant="text"
                    startIcon={<AutorenewIcon />}
                    sx={{
                        textTransform: 'capitalize',
                        '.MuiButton-startIcon': {
                            marginRight: '0.25rem',
                        },
                    }}
                >
                    Обновить
                </Button>
            </div>
            <div className={s.pagination_container}>
                <Pagination
                    count={Number(Math.ceil((notificationsList.data?.total || 10) / 20).toFixed(0))}
                    page={page}
                    onChange={handleChange}
                />
            </div>
            <div className={s.main}>
                {notificationsList.data ? (
                    notificationsList.data.data.map((alert) => (
                        <div className={s.item_grid_container} key={alert.alertId}>
                            <div className={s.item_container}>
                                <FeedbackIcon
                                    sx={{
                                        color: '#ED6C02',
                                        fontSize: '1.25rem',
                                    }}
                                />
                                <div className={s.right_container}>
                                    <div className={s.title_container}>
                                        <p className={s.blue_title}>Сообщение системы</p>
                                        <p className={s.gray_date}>
                                            {getDateTimeString(alert.createdAt)?.replace(/ в/g, ',')}
                                        </p>
                                    </div>
                                    <p className={s.description}>
                                        {alert.valueMonth
                                            ? `Внимание! Необходимо увеличить месячную выработку по работе “${alert.workName}” 
                                строка “${alert.workNumOrder}” на “${alert.valueMonth}” иначе с текущей выработкой 
                                Вы закончите ${alert.dateDone}`
                                            : `Внимание! С текущей выработкой вы закончите ${alert.dateDone} по работе “${alert.workName}”,
                                             строка “${alert.workNumOrder}” или спланируйте работы на последующие месяца.`}
                                    </p>
                                </div>
                            </div>
                            <ButtonsNotificationCenter
                                disabledVolume={!alert.availableInc.volume}
                                disabledTime={!alert.availableInc.period}
                                increaseVolume={() => increaseVolumeFunc([alert.alertId])}
                                increaseTime={() => increasePeriodFunc([alert.alertId])}
                                border="none"
                            />
                        </div>
                    ))
                ) : (
                    <CircularProgress />
                )}
            </div>

            <div className={s.buttons_bottom_container}>
                <p>Выбрать для всех:</p>
                <ButtonsNotificationCenter
                    disabledVolume={!availableToIncreaseIdsVol?.length}
                    disabledTime={!availableToIncreaseIdsPer?.length}
                    increaseVolume={() => {
                        notificationsList.data && increaseVolumeFunc(availableToIncreaseIdsVol)
                    }}
                    increaseTime={() => {
                        notificationsList.data && increasePeriodFunc(availableToIncreaseIdsPer)
                    }}
                    fontSize="14px"
                    padding="7px"
                />
            </div>
        </>
    )
}
