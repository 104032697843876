import { ICellRendererParams } from 'ag-grid-community'
import s from '../../../shared/stylesheets/Gantt.module.scss'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

export default function ChartCell({
    filled,
    borderRight,
    borderLeft,
    backgroundCss,
    cellRendererParams,
}: // arrowToLeft,
// arrowFromRight,
// workData,
// month,
// filteredWorks
{
    filled: boolean
    borderRight: boolean
    borderLeft: boolean
    backgroundCss: { backgroundColor: string }
    cellRendererParams: ICellRendererParams
    // arrowToLeft: boolean
    // arrowFromRight: boolean,
    // workData?: Work,
    // month: number,
    // filteredWorks: Work[]
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const cellClass = () => {
        switch (true) {
            case borderRight && borderLeft:
                return s.chart_both_borders
            case borderRight:
                return s.chart_right_border
            case borderLeft:
                return s.chart_left_border
            default:
                return s.chart_full
        }
    }

    // const currentCellId = workData?.id + "month" + workData?.monthlyCharts[month]?.month + "year" + workData?.monthlyCharts[month]?.year
    //
    // const targetCellIds = workData?.workBonds?.worksAfter.map(workAfter => {
    //     const workID = workAfter.id
    //     let startMonth = parse(filteredWorks.find(work => workAfter.id == work.id)?.workPeriod?.start as string, "dd.MM.yyyy", new Date()).getMonth() + 1
    //     let startYear = parse(filteredWorks.find(work => workAfter.id == work.id)?.workPeriod?.start as string, "dd.MM.yyyy", new Date()).getFullYear()
    //     return workID + "month" + startMonth + "year" + startYear
    // })

    return filled ? (
        <>
            {/*{targetCellIds?.map(targetId => {*/}
            {/*        if (targetId) {*/}
            {/*            console.log(currentCellId)*/}
            {/*            return (*/}
            {/*            <Xarrow key={targetId} start={currentCellId} end={targetId} path={"grid"} strokeWidth={2} startAnchor={"right"} endAnchor={"left"}/>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    })}*/}
            <div
                // id={currentCellId}
                style={{
                    height: 26,
                    ...backgroundCss,
                    zIndex: 2,
                    ...(cellRendererParams?.data?.critical &&
                        searchParams.get('criticalPath') && {
                            backgroundColor: 'red',
                        }),
                }}
                className={cellClass()}
            ></div>
        </>
    ) : (
        <></>
    )
}

export function ChartCellFact({
    filled,
    borderRight,
    borderLeft,
    backgroundCss,
}: {
    filled: boolean
    borderRight: boolean
    borderLeft: boolean
    backgroundCss: { backgroundColor: string }
}) {
    const cellClass = () => {
        switch (true) {
            case borderRight && borderLeft:
                return s.chart_both_borders_fact
            case borderRight:
                return s.chart_right_border_fact
            case borderLeft:
                return s.chart_left_border_fact
            default:
                return s.chart_full
        }
    }

    return filled ? (
        <div
            style={{
                height: '1rem',
                zIndex: 3,
                ...backgroundCss,
            }}
            className={cellClass()}
        ></div>
    ) : (
        <></>
    )
}

export function ChartCellBasePlan({
    filled,
    borderRight,
    borderLeft,
    backgroundCss,
}: {
    filled: boolean
    borderRight: boolean
    borderLeft: boolean
    backgroundCss: { backgroundColor: string }
}) {
    const cellClass = () => {
        switch (true) {
            case borderRight && borderLeft:
                return s.chart_both_borders_base_plan
            case borderRight:
                return s.chart_right_border_base_plan
            case borderLeft:
                return s.chart_left_border_base_plan
            default:
                return s.chart_full
        }
    }

    return filled ? (
        <div
            style={{
                height: '2.5rem',
                zIndex: 1,
                ...backgroundCss,
            }}
            className={cellClass()}
        ></div>
    ) : (
        <></>
    )
}
