import { Box, BoxProps, Typography } from "@mui/material";
import { FlexColumnWrapper } from "../../../NewExecutorView/components/components.styles";
import { PlugIcon } from "../../../../shared/SVG/Svg";

export function Plug(props: BoxProps) {
    return (
        <Box
            position="relative"
            {...props}
            sx={{
                ">*:not(#plug)": {
                    filter: "grayscale(100%)"
                },
                ...props.sx
            }}
        >
            <FlexColumnWrapper
                id={"plug"}
                p={4}
                gap={1}
                pt={1.25}
                bgcolor={"white"}
                position={"absolute"}
                bottom={0}
                right={0}
                zIndex={2}
                sx={{
                    filter: "none"
                }}
                alignItems={"center"}
            >
                <PlugIcon />
                <Typography variant="button" color="primary">В разработке</Typography>
            </FlexColumnWrapper>
            {props.children}
        </Box>
    )
}