const sameProperties = {
    boxShadow: 'unset',
    height: 30,
    borderRadius: '6px',
    fontSize: '13px',
}

export const sxButtonBottom = {
    backgroundColor: '#FEF1E6',
    color: '#F46B6B',
    ...sameProperties,
    '&:hover': {
        backgroundColor: '#ffedd5',
        color: '#F46B6B',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    },
}

export const sxButtonTop = {
    backgroundColor: '#eef9f7',
    color: '#2E7D32',
    padding: '6px',
    ...sameProperties,
    '&:hover': {
        backgroundColor: '#dcfce7',
        color: '#2E7D32',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    },
}

export const sxDate = {
    '.MuiFormControl-root': {
        width: '154px',
    },
    '.MuiInputBase-input': {
        padding: '12px 8px 8px 12px',
        fontSize: '14px',
    },
    'label[data-shrink=false]': {
        fontSize: '14px',
        transform: 'translate(14px, 10px) scale(1)',
    },
    '.MuiInputLabel-root': {
        color: '#5C6E8C',
    },
    '.MuiButtonBase-root': {
        marginTop: '3px',
        padding: '8px',
    },
    '.MuiSvgIcon-root': {
        fontSize: '1.2rem',
    },
}

export const sxInput = {
    '.MuiOutlinedInput-input': {
        padding: '0.5rem 0.5rem 0.5rem 0.8rem',
    },
    'label[data-shrink=false]': {
        fontSize: '14px',
        transform: 'translate(14px, 10px) scale(1)',
    },
    '.MuiInputLabel-root': {
        color: '#5C6E8C',
    },
}

export const sxIconButtonTop = {
    backgroundColor: '#fdf0e6',
    borderRadius: '6px',
    padding: '0.2rem',
    color: '#F46B6B',
    '&:hover': {
        backgroundColor: '#ffedd5',
        color: '#F46B6B',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    },
}

export const textLabelAndDesc = (variant: 'vol' | 'time') => {
    if (variant === 'vol') {
        return {
            label: 'Объем работ за месяц',
            desc: 'Укажите объем работ за месяц, система автоматически распределит оставшийся объем работ пропорционально месяцам необходимым для выполнения общего объема',
            volTitle: 'Оставшийся объем:',
        }
    } else {
        return {
            label: 'Распределить общий объем',
            desc: 'Укажите объем работ, дату начала и окончания, система автоматически распределит указанный объем работ пропорционально в рамках указанного периода. Оставшийся объем работ Вы сможете распределить позже.',
            volTitle: 'Оставшийся объем:',
        }
    }
}
