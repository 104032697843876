import { Box } from '@mui/material'
import styled from 'styled-components'

export const IconWrapper = styled(Box)`
    cursor: pointer;
    border-radius: 0 0 0 6px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: ${({ theme }) => theme.spacing(0.25)};
`
