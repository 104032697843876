import { Close } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useState } from 'react'

export const useMainTableFilterSearch = () => {
    const [search, setSearch] = useState('')
    const SearchTextfieldComponent = (
        <TextField
            value={search}
            sx={{
                '.MuiOutlinedInput-root': {
                    pl: 1,
                    border: '2px solid rgba(92, 110, 140, 0.1)',
                    background: '#f6f7fb',
                    borderRadius: 2,
                },
                fieldset: {
                    border: 'none',
                },
                input: {
                    pl: 1,
                    color: '#7890B2',
                },
            }}
            size="small"
            InputProps={{
                startAdornment: (
                    <SearchOutlinedIcon
                        sx={{
                            color: '#7890B2',
                        }}
                    />
                ),
                endAdornment: (
                    <FlexRowWrapper alignItems={'center'} onClick={() => setSearch(() => '')}>
                        <Close
                            sx={{
                                cursor: 'pointer',
                                color: '#7890B2',
                            }}
                        />
                    </FlexRowWrapper>
                ),
            }}
            onChange={(e) => setSearch(() => e.target.value)}
        />
    )
    return { SearchTextfieldComponent, search }
}
