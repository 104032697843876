import s from '../AddLevelKsg.module.scss'
import { TextField } from '@mui/material'
import { FormErrorAg, inputStylesAddWorkAg } from '../AddLevel.service'
import React from 'react'

export default function TextLineEl({
    errorTitle,
    touched,
    title,
    required,
    ...props
}: {
    errorTitle?: string
    touched?: boolean
    title: string
    required?: boolean
}) {
    return (
        <div className={s.input_line}>
            <p>
                {title}
                {required && (
                    <>
                        {' '}
                        <span>*</span>
                    </>
                )}
                :
            </p>
            <div>
                <TextField
                    InputLabelProps={{ shrink: false }}
                    placeholder={title}
                    sx={inputStylesAddWorkAg}
                    error={!!errorTitle?.length}
                    {...props}
                />
                <FormErrorAg isTouched={touched} error={errorTitle} />
            </div>
        </div>
    )
}
