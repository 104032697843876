import styled from 'styled-components'
import { StyledAgGrid } from '../../WorkManagment/components/AgGridService/AgGrid.styles'

export const StyledSNZAgGrid = styled(StyledAgGrid)`
    .ag-header-row {
        background-color: ${(props) => props.theme.palette.secondary.gray};
        color: white;
    }

    .ag-header-group-cell,
    .ag-header-cell {
        padding: ${(props) => props.theme.spacing(0, 2)};
        background-color: ${(props) => props.theme.palette.secondary.gray};
        border-right: 1px solid #6e86ab;
    }

    .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
    .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
        border-top: 1px solid #6e86ab;
    }

    .ag-cell {
        padding: ${(props) => props.theme.spacing(1, 2)};
    }
`
