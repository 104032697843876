import { Info } from '@mui/icons-material'
import { StyledTooltip } from '../../../WorkManagment/components/components.styles'
import { Box } from '@mui/material'
import { IconWrapper } from './Notificator.styles'
import { INotificatorProps } from './Notificator.def'

export function Notificator({ title, color }: INotificatorProps) {
    return (
        <StyledTooltip
            title={title}
            PopperProps={{
                disablePortal: false,
            }}
            disableInteractive
        >
            <IconWrapper>
                <Info sx={{ color: color || '#F4CF0C', fontSize: '16px' }}></Info>
            </IconWrapper>
        </StyledTooltip>
    )
}
