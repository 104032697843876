import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton, Menu, Stack } from '@mui/material'
import {
    CellClassParams,
    ColDef,
    ColGroupDef,
    EditableCallbackParams,
    ICellEditorParams,
    ICellRendererParams,
    IHeaderParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Dispatch, Fragment, RefObject, SetStateAction } from 'react'
import { NumericFormat } from 'react-number-format'
import { Link, Location } from 'react-router-dom'
import { Updater } from 'use-immer'
import { Work, WorkOrWorkPosition, monthlyChart } from '../../api/ksg/ksg.def'
import { PublicUserProfile } from '../../api/users/users.def'
import BigEnterPlan from '../../components/BigEnterPlan/BigEnterPlan'
import { OverflowableTypographyWithTooltip } from '../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { RelationsIcon } from '../../shared/SVG/Svg'
import { HtmlTooltip } from '../../shared/components/HtmlTooltip'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { areAllValuesNull, tooltipHelperNumber } from '../../shared/utils'
import { onEditAgKsg } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { AppDispatch } from '../../store/store'
import { applyStylesPercent, hasParam } from '../KsgTable/Ksg.service'
import ChartCell, { ChartCellBasePlan, ChartCellFact } from '../KsgTable/components/ChartCell'
import { UserRolesEn } from '../Users/user'
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { getDefaultValidationRules } from '../WorkManagment/components/CellEditors/CellEditor/CellEditor.service'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import {
    RD_STATUS_OPTIONS,
    VOLUME_TOTAL_CELL_EDITOR_PARAMS,
    ganttColor,
    getHeadersMonths,
    getOfferColDefs,
} from './AgGrid.service'
import { StyledCheckbox } from './StyledCheckbox'
import { HeaderFilter, HeaderNameEl } from './components/HeaderRenderer'
import { InfoWorkPeriod } from './components/InfoWorkPeriod'
import { LevelRenderer } from './components/LevelRenderer'
import MonthRenderer from './components/MonthRenderer/MonthRenderer'
import { Notificator } from './components/Notificator/Notificator'
import RowMonthHeaderRenderer from './components/RowMonthHeaderRenderer'
import { SpreadPlanBtn } from './components/SpreadPlanBtn/SpreadPlanBtn'
import { VolumeTotalNotificator } from './components/VolumeTotalNotificator/VolumeTotalNotificator'
import { WorkPositionLevelRenderer } from './components/WorkPositionLevelRenderer'

interface IGetKSGColDefArgs {
    projectId: string
    rowData: WorkOrWorkPosition[] | null
    setAddLevelData: Dispatch<
        SetStateAction<{
            id: number | null
            index: number
            level: number | null
        }>
    >
    setIsOpenAddLevel: Dispatch<SetStateAction<boolean>>
    location: Location
    profile: PublicUserProfile
    isOfferActive?: boolean
    onDeleteRow: (id: number) => void
    checkTag: (bool: boolean, workId: number | undefined) => void
    hiddenRowsIds: {
        id: number
        code: string
    }[]
    setHiddenRowsIds: Updater<
        {
            id: number
            code: string
        }[]
    >
    gridRef: RefObject<AgGridReact<any>>
    changeableArray:
        | {
              month: number
              year: number
              fill: boolean
              fact: number | null
          }[]
        | undefined
    setChangeableArray: Updater<
        | {
              month: number
              year: number
              fill: boolean
              fact: number | null
          }[]
        | undefined
    >
    setRelationsOpened: Dispatch<SetStateAction<boolean>>
    setRelationData: Dispatch<
        SetStateAction<{
            workName: string
            startDate: string | null
            endDate: string | null
            id: number
        } | null>
    >
    urlSearchParams: URLSearchParams
    anchorElTemplateTable: HTMLElement | null
    setAnchorElTemplateTable: Dispatch<SetStateAction<HTMLElement | null>>
    indexClick: {
        month: number
        work: number
    } | null
    setIndexClick: Updater<{
        month: number
        work: number
    } | null>
    dispatch: AppDispatch
    headersCPG: any
    basicPlan: boolean
    costDoneMode: boolean
    setMutationsLoading: Dispatch<SetStateAction<boolean>>
    setOpenModal: Dispatch<SetStateAction<boolean>>
    handleClickChart: (idx: number, work: Work) => void
}

export const KSGColDefs = ({
    projectId,
    rowData,
    setAddLevelData,
    setIsOpenAddLevel,
    location,
    isOfferActive,
    profile,
    onDeleteRow,
    checkTag,
    hiddenRowsIds,
    setHiddenRowsIds,
    gridRef,
    changeableArray,
    setChangeableArray,
    setRelationsOpened,
    setRelationData,
    urlSearchParams,
    anchorElTemplateTable,
    setAnchorElTemplateTable,
    indexClick,
    setIndexClick,
    dispatch,
    headersCPG,
    basicPlan,
    costDoneMode,
    setMutationsLoading,
    setOpenModal,
    handleClickChart,
}: IGetKSGColDefArgs): ColDef<WorkOrWorkPosition>[] | ColGroupDef<WorkOrWorkPosition>[] => {
    const dataColumn = [
        {
            headerName: 'Уровень',
            field: 'level',
            width: 130,
            colId: 'level',
            //   headerName: '',
            spanHeaderHeight: true,
            headerComponent: () =>
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                    <Box mx="auto">
                        {profile.role !== 'client' && (
                            <IconButton
                                onClick={() => {
                                    setAddLevelData({
                                        id: null,
                                        index: 1,
                                        level: 1,
                                    })
                                    setIsOpenAddLevel(true)
                                }}
                                color="primary"
                                component="label"
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </Box>
                ),
            cellRenderer: (p: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                if (!p.data) return <></>

                if ('isWorkPosition' in p.data) return <WorkPositionLevelRenderer />

                const data = p.data as Work

                return (
                    <LevelRenderer
                        data={data}
                        onDeleteRow={onDeleteRow}
                        isOfferActive={isOfferActive}
                        setHiddenRowsIds={setHiddenRowsIds}
                        setAddWorkId={() => {
                            if (!data) return

                            setAddLevelData({
                                id: data.id,
                                index: p.rowIndex + 1,
                                level: data.level !== null ? data.level + 1 : data.level,
                            })

                            setIsOpenAddLevel(true)
                        }}
                        arrowDown={!!hiddenRowsIds?.find((item) => item.id === p.data?.id)}
                    />
                )
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: 100,
            spanHeaderHeight: true,
            headerName: '№',
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                return <div>{data.value}</div>
            },
        },
        {
            field: 'code',
            colId: 'code',
            width: 130,
            spanHeaderHeight: true,
            headerName: 'ID',
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                if (params.data && 'isWorkPosition' in params.data)
                    return (
                        <OverflowableTypographyWithTooltip maxRows={3} TypographyProps={{ sx: { px: 1 } }}>
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    )

                const data = params.data as Work

                return (
                    <Link
                        to={`/workManagment/${projectId}/${data?.id}/estimate/resources`}
                        state={{ prevPath: location.pathname }}
                    >
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1, cursor: 'pointer' } }}
                        >
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    </Link>
                )
            },
        },
        {
            field: 'tag',
            colId: 'tag',
            width: 100,
            spanHeaderHeight: true,
            headerName: 'Прочие работы',
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                if (params?.data && 'isWorkPosition' in params.data) return null

                const data = params.data as Work

                return (
                    <div>
                        {!data?.hasChildren && (
                            <StyledCheckbox
                                checked={params.value}
                                onChange={(e) =>
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') &&
                                    checkTag(e.target.checked, data?.id)
                                }
                                //   disabled={!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG')}
                            />
                        )}
                    </div>
                )
            },
        },
        {
            field: 'mainGroup',
            colId: 'mainGroup',
            width: 150,
            spanHeaderHeight: true,
            headerName: 'Ключевые объемы',
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            cellClass: (params: CellClassParams) => !params.data?.hasChildren && 'ag-cell-editable',
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('mainGroup'),
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: 260,
            headerName: 'Титул',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="objTitle"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objTitle'),
        },
        {
            field: 'objName',
            colId: 'objName',
            width: 300,
            headerName: 'Объект',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="objName"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objName'),
        },
        {
            field: 'rdGroup',
            colId: 'rdGroup',
            width: 180,
            headerName: 'Раздел РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdGroup'),
        },
        {
            field: 'rdCode',
            colId: 'rdCode',
            width: 160,
            headerName: 'Шифр РД',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="rdCode"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdCode'),
        },
        {
            field: 'rdStatus',
            colId: 'rdStatus',
            width: 225,
            headerName: 'Статус выдачи РД',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="rdStatus"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return params.data?.level !== 0
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
            cellEditorParams: {
                dataType: 'autocomplete',
                options: RD_STATUS_OPTIONS,
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdStatus'),
        },
        {
            field: 'rdDateApproval',
            colId: 'rdDateApproval',
            width: 140,
            headerName: 'Дата согласования РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                field: 'rdDateApproval',
                gridApi: gridRef.current?.api,
            }),
        },
        {
            field: 'rdVersion',
            colId: 'rdVersion',
            width: 180,
            headerName: 'Версия РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('rdVersion'),
            cellEditorParams: {
                NumericFormatProps: {
                    decimalScale: 0,
                },
                noValuePlug: '',
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdVersion'),
        },
        {
            field: 'rdDate',
            colId: 'rdDate',
            width: 140,
            headerName: 'Дата выдачи РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                field: 'rdDate',
                gridApi: gridRef.current?.api,
            }),
        },
        {
            field: 'typeOfWorks',
            colId: 'typeOfWorks',
            width: 150,
            headerName: 'Конструктив (вид работ)',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('typeOfWorks'),
            cellEditorParams: {
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA.cellEditorParams,
                validation: {
                    ...getDefaultValidationRules('text'),
                    required: false,
                },
            },
        },
        {
            field: 'workGroup',
            colId: 'workGroup',
            width: 180,
            headerName: 'Группа работ',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('workGroup'),
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="workGroup"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
        },
        {
            field: 'groupOfWorks',
            colId: 'groupOfWorks',
            width: 180,
            headerName: 'Головная группа работ',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('groupOfWorks'),
        },
        {
            field: 'contractorCompany',
            colId: 'contractorCompany',
            width: 180,
            headerName: 'Подрядчик',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="contractorCompany"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('contractorCompany'),
        },
        {
            field: 'workName',
            colId: 'workName',
            width: 300,
            headerName: 'Наименование работы',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderFilter
                    onFilterClick={() => {
                        setOpenModal(true)
                    }}
                    term="workName"
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return true
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('workName'),
        },
        ...getOfferColDefs('lsrCiphers', isOfferActive),
        {
            field: 'unit',
            colId: 'unit',
            width: 130,
            headerName: 'Единица измерения',
            headerTooltip: 'Единица измерения',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('unit'),
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: 180,
            headerName: 'Общий объем',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params?.data?.hasChildren && (['admin', 'scheduler'] as UserRolesEn[]).includes(profile.role)
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('volumeTotal'),
            cellEditorParams: VOLUME_TOTAL_CELL_EDITOR_PARAMS,
            cellRenderer: (params: ICellRendererParams<Work>) => {
                return (
                    <>
                        {params.data?.volumeDistributeIndicator &&
                            !areAllValuesNull(params.data.volumeDistributeIndicator) && (
                                <VolumeTotalNotificator data={params.data?.volumeDistributeIndicator} />
                            )}
                        {(params.data?.volumeTotal || !params?.data?.hasChildren) && <NumberCellRenderer {...params} />}
                    </>
                )
            },
        },
        {
            headerName: 'Выполненный объём работ',
            colId: 'volumeDone',
            children: [
                {
                    field: 'volumeDonePlan',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: (params: ICellRendererParams<Work>) =>
                        (params.data?.volumeDonePlan || !params?.data?.hasChildren) && (
                            <NumberCellRenderer {...params} />
                        ),
                },
                {
                    field: 'volumeDoneFact',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: (params: ICellRendererParams<Work>) =>
                        (params.data?.volumeDoneFact || !params?.data?.hasChildren) && (
                            <NumberCellRenderer {...params} />
                        ),
                },
            ],
        },
        {
            headerName: 'Базовый план',
            colId: 'basePlanDates',
            children: [
                {
                    field: 'startDate',
                    colId: 'startDate',
                    width: 140,
                    headerName: 'Дата начала',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if ('isWorkPosition' in params.data) return false
                        return !params.data?.hasChildren
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'startDate',
                        compareWithEndDate: 'endDate',
                        gridApi: gridRef.current?.api,
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                {params.data?.basePlanePeriodAlert && (
                                    <Notificator title="Даты не совпадают с чартами КСГ" />
                                )}
                                {params?.data?.startDate}
                            </>
                        )
                    },
                },
                {
                    field: 'endDate',
                    colId: 'endDate',
                    width: 140,
                    headerName: 'Дата окончания',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if ('isWorkPosition' in params.data) return false
                        return !params.data?.hasChildren
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'endDate',
                        compareWithStartDate: 'startDate',
                        gridApi: gridRef.current?.api,
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                {params.data?.basePlanePeriodAlert && (
                                    <Notificator title="Даты не совпадают с чартами КСГ" />
                                )}
                                {params?.api.getValue('endDate', params.node)}
                                {params?.data?.hasChildren === false &&
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_BASE_PLAN') && (
                                        <SpreadPlanBtn typeOfPlan="base" cellRendererParams={params} />
                                    )}
                            </>
                        )
                    },
                },
            ],
        },
        {
            headerName: 'Оперативный план',
            colId: 'operPlanDates',
            children: [
                {
                    field: 'operationalStartDate',
                    colId: 'operationalStartDate',
                    width: 140,
                    headerName: 'Дата начала',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if ('isWorkPosition' in params.data) return false

                        return !params.data?.hasChildren && params.data?.workBonds?.worksBefore.length === 0
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalStartDate',
                        compareWithEndDate: 'operationalEndDate',
                        gridApi: gridRef.current?.api,
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                {params.data?.periodAlert && <Notificator title="Даты не совпадают с чартами КСГ" />}
                                {params?.data?.operationalStartDate}
                            </>
                        )
                    },
                },
                {
                    field: 'operationalEndDate',
                    colId: 'operationalEndDate',
                    width: 140,
                    headerName: 'Дата окончания',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if ('isWorkPosition' in params.data) return false
                        return !params.data?.hasChildren
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalEndDate',
                        compareWithStartDate: 'operationalStartDate',
                        gridApi: gridRef.current?.api,
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <>
                                {params.data?.periodAlert && <Notificator title="Даты не совпадают с чартами КСГ" />}
                                {params?.data?.operationalEndDate}
                                {params?.data?.hasChildren === false &&
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_OPER_PLAN') && (
                                        <SpreadPlanBtn typeOfPlan="operational" cellRendererParams={params} />
                                    )}
                            </>
                        )
                    },
                },
            ],
        },
        {
            field: 'humanHourCost',
            colId: 'humanHourCost',
            width: 180,
            headerName: 'Трудозатраты на ед. изм., чел-час',
            headerTooltip: 'Трудозатраты на ед. изм., чел-час',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Fragment>
                        <NumberCellRenderer {...params} />
                        {params.data?.staffNotSet && (
                            <Notificator
                                title={
                                    <>
                                        В кабинете управления работой <br />
                                        не заполнен раздел «Трудозатраты»
                                    </>
                                }
                            />
                        )}
                    </Fragment>
                )
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('humanHourCost'),
        },
        {
            field: 'humanHoursTotal',
            colId: 'humanHoursTotal',
            width: 180,
            headerName: 'Трудозатраты общие, чел-час',
            headerTooltip: 'Трудозатраты общие, чел-час',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursProgressTZ',
            colId: 'humanHoursProgressTZ',
            width: 180,
            headerName: 'Прогресс ТЗ',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс трудозатрат нарастающим итогом, чел-час',
            colId: 'humanHoursProgress',
            children: [
                {
                    field: 'humanHoursProgressPlan',
                    colId: 'humanHoursProgressPlan',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressFact',
                    colId: 'humanHoursProgressFact',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressPercent',
                    colId: 'humanHoursProgressPercent',
                    width: 180,
                    headerName: 'Процент выполнения',
                    headerTooltip: 'Процент выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'machineHourCost',
            colId: 'machineHourCost',
            width: 180,
            headerName: 'Затраты механизмов на ед.изм., маш-час',
            headerTooltip: 'Затраты механизмов на ед.изм., маш-час',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl width={data.column.getActualWidth()} name={data.displayName} />
            ),
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <Fragment>
                        <NumberCellRenderer {...params} />
                        {params.data?.mimNotSet && (
                            <Notificator
                                title={
                                    <>
                                        В кабинете управления работой <br />
                                        не заполнен раздел <br /> «Механизмы»
                                    </>
                                }
                            />
                        )}
                    </Fragment>
                )
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('machineHourCost'),
        },
        {
            field: 'machineHoursTotal',
            colId: 'machineHoursTotal',
            width: 180,
            headerName: 'Затраты механизмов, маш-час',
            headerTooltip: 'Затраты механизмов, маш-час',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl width={data.column.getActualWidth()} name={data.displayName} />
            ),
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costER',
            colId: 'costER',
            width: 180,
            headerName: 'Стоимость ЕР (Дог.+ДР), руб.',
            headerTooltip: 'Стоимость ЕР (Дог.+ДР), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(undefined, 9999999999.999999),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costER'),
        },
        {
            field: 'costTotal',
            colId: 'costTotal',
            width: 180,
            headerName: 'Общая стоимость (Дог.+ДР), рублей',
            headerTooltip: 'Общая стоимость (Дог.+ДР), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgress',
            colId: 'costTotalProgress',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Дог.+ДР)',
            headerTooltip: 'Общий прогресс по стоимости (Дог.+ДР)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости (Дог.+ДР) нарастающим итогом, руб',
            colId: 'costProgress',
            children: [
                {
                    field: 'costProgressPlan',
                    colId: 'costProgressPlan',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFact',
                    colId: 'costProgressFact',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercent',
                    colId: 'costProgressPercent',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costRemaining',
            colId: 'costRemaining',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Остаток стоимости (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        profile?.futures?.reportEIS && costDoneMode
            ? {
                  field: 'costDone',
                  colId: 'costDone',
                  width: 180,
                  spanHeaderHeight: true,
                  headerName: 'Закрыто по КС2 (Дог.+ДР), рублей',
                  editable: (params: EditableCallbackParams) => {
                      if ('isWorkPosition' in params.data) return false
                      return true
                  },
                  ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(undefined, 9999999.999999),
                  cellRenderer: (params: ICellRendererParams) => {
                      return (
                          <>
                              <NumberCellRenderer
                                  value={
                                      params.data?.costDoneManuallyCalc ||
                                      params.data?.costDoneManually ||
                                      params.data?.costDone
                                  }
                              />
                              {params.data?.costDoneManually != null && (
                                  <Notificator title="Значение было внесено вручную" />
                              )}
                              {/* {params.data?.costDoneManuallyCalc != null && (
                                  <Notificator color={theme.palette.error.main} title="Значение было внесено вручную" />
                              )} */}
                          </>
                      )
                  },
                  cellEditorParams: (params: ICellEditorParams) => {
                      const maxValue = 9999999999.999999
                      return {
                          ...params,
                          value: params.data?.hasChildren ? params.data?.costDoneManually : params.data?.costDone,
                          validation: {
                              ...(params.data?.hasChildren && { required: false }),
                              validate: {
                                  maxValue: (v: string) =>
                                      !v && params.data?.hasChildren
                                          ? true
                                          : parseFloat(v) <= maxValue || `Макс. ${maxValue}`,
                              },
                          },
                      }
                  },
                  valueGetter: (params: ValueGetterParams) => {
                      if (params.data?.hasChildren) {
                          return params.data.costDoneManually
                      } else {
                          return params.data.costDone
                      }
                  },
                  valueSetter: (params: ValueSetterParams) => {
                      if (params.data?.hasChildren) {
                          params.api.applyTransaction({
                              update: [{ ...params.data, costDoneManually: params.newValue }],
                          })
                      } else {
                          params.api.applyTransaction({
                              update: [{ ...params.data, costDone: params.newValue }],
                          })
                      }
                      return true
                  },
              }
            : {
                  field: 'costDone',
                  colId: 'costDone',
                  width: 180,

                  spanHeaderHeight: true,
                  headerName: 'Закрыто по КС2 (Дог.+ДР), рублей',
                  editable: (params: ValueGetterParams) => {
                      const data = params.data
                      if (data && 'isWorkPosition' in data) return false
                      if (data && 'hasChildren' in data) {
                          return !data.hasChildren
                      }
                      return false
                  },
                  ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(undefined, 9999999999.999999),
                  ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costDone'),
              },
        {
            field: 'costNZP',
            colId: 'costNZP',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'НЗП (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costERGK',
            colId: 'costERGK',
            width: 180,
            headerName: 'Стоимость ЕР (Договор), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(undefined, 9999999999.999999),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costERGK'),
        },
        {
            field: 'costTotalGK',
            colId: 'costTotalGK',
            width: 180,
            headerName: 'Общая стоимость (Договор), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressGK',
            colId: 'costTotalProgressGK',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Договор)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости (Договор) нарастающим итогом, руб',
            colId: 'costProgressPlan',
            children: [
                {
                    field: 'costProgressPlanGK',
                    colId: 'costProgressPlanGK',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactGK',
                    colId: 'costProgressFactGK',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentGK',
                    colId: 'costProgressPercentGK',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costNZPGK',
            colId: 'costNZPGK',
            width: 180,

            spanHeaderHeight: true,
            headerName: 'НЗП (Договор), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costERSub',
            colId: 'costERSub',
            width: 180,
            headerName: 'Стоимость ЕР (Расчет), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if ('isWorkPosition' in params.data) return false
                return !params.data?.hasChildren
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(undefined, 9999999999.999999),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costERSub'),
        },
        {
            field: 'costTotalSub',
            colId: 'costTotalSub',
            width: 180,
            headerName: 'Общая стоимость (Расчет), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressSub',
            colId: 'costTotalProgressSub',
            width: 180,
            headerName: 'Общий прогресс по стоимости (Расчет)',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: (params: ICellRendererParams) => {
                return {
                    TypographyProps: { ...applyStylesPercent(params.value) },
                    NumericFormatProps: {
                        ...applyStylesPercent(params.value),
                        suffix: ' %',
                    },
                }
            },
        },
        {
            headerName: 'Прогресс стоимости (Расчет) нарастающим итогом, руб',
            colId: 'costProgressPlan',
            children: [
                {
                    field: 'costProgressPlanSub',
                    colId: 'costProgressPlanSub',
                    width: 180,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactSub',
                    colId: 'costProgressFactSub',
                    width: 180,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentSub',
                    colId: 'costProgressPercentSub',
                    width: 180,
                    headerName: 'Процент текущего выполнения',
                    cellRenderer: NumberCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            TypographyProps: { ...applyStylesPercent(params.value) },
                            NumericFormatProps: {
                                ...applyStylesPercent(params.value),
                                suffix: ' %',
                            },
                        }
                    },
                },
            ],
        },
        {
            field: 'costNZPSub',
            colId: 'costNZPSub',
            width: 180,

            spanHeaderHeight: true,
            headerName: 'НЗП (Расчет), рублей',
            cellRenderer: NumberCellRenderer,
        },
        ...getOfferColDefs('reportingPeriodInfo', isOfferActive),
        {
            field: 'prStart',
            colId: 'prStart',
            width: 160,

            spanHeaderHeight: true,
            headerName: 'Ориентировочная дата начала поставки',
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl width={data.column.getActualWidth()} name={data.displayName} />
            ),
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                if (!data.data) return <></>

                return (
                    <div>
                        <Link
                            style={{
                                textDecoration: 'underline',
                            }}
                            to={`/ksg/${projectId}/mto/${data.data?.id}`}
                        >
                            {data.data.mtoExists && !data.data.prProvision ? data.data.prStart : null}
                        </Link>
                    </div>
                )
            },
        },
        {
            field: 'prEnd',
            colId: 'prEnd',
            width: 160,
            spanHeaderHeight: true,
            headerName: 'Ориентировочная дата завершения поставки',
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                if (!data.data) return <></>
                return (
                    <div>
                        <Link
                            style={{
                                textDecoration: 'underline',
                            }}
                            to={`/ksg/${projectId}/mto/${data.data.id}`}
                        >
                            {data.data.prProvision !== null && data.data.prProvision >= 100 ? null : data.value}
                        </Link>
                    </div>
                )
            },
        },
        {
            field: 'prProvision',
            colId: 'prProvision',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Обеспеченность в %',
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                if (!data.data) return <></>
                return (
                    <div>
                        {data.value !== null ? (
                            <HtmlTooltip title={tooltipHelperNumber(data.value)}>
                                <Link
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                    to={`/ksg/${projectId}/mto/${data.data.id}`}
                                >
                                    <NumericFormat
                                        decimalScale={2}
                                        suffix={'%'}
                                        displayType={'text'}
                                        value={data.value}
                                        thousandSeparator={' '}
                                        decimalSeparator=","
                                    />
                                </Link>
                            </HtmlTooltip>
                        ) : (
                            <>
                                {data.data.mtoExists ? (
                                    <Link
                                        style={{
                                            textDecoration: 'underline',
                                        }}
                                        to={`/ksg/${projectId}/mto/${data.data.id}`}
                                    >
                                        0%
                                    </Link>
                                ) : (
                                    <div></div>
                                )}
                            </>
                        )}
                    </div>
                )
            },
        },
        {
            field: 'workPeriod',
            colId: 'workPeriod',
            width: 140,
            spanHeaderHeight: true,
            headerName: 'Период работ',
            cellRenderer: (cellRendererParams: ICellRendererParams) => {
                if ('isWorkPosition' in cellRendererParams.data) return null
                return <InfoWorkPeriod {...cellRendererParams} />
            },
        },
        // Добавлена колонка для общего показателя верификационного факта
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            headerClass: 'ag-verFact',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'workBonds',
            colId: 'workBonds',
            width: 80,
            spanHeaderHeight: true,
            headerName: 'Связи',
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                if (params?.data && 'isWorkPosition' in params.data) return null

                const data = params.data as Work

                return (
                    <div>
                        {!data?.hasChildren && (
                            <Stack
                                onClick={() => {
                                    if (DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINK_GANT_SMR')) {
                                        setRelationsOpened(true)
                                        setRelationData({
                                            workName: data?.workName!,
                                            startDate: data?.workPeriod?.start || null,
                                            endDate: data?.workPeriod?.end || null,
                                            id: data!.id,
                                        })
                                    }
                                }}
                                direction="row"
                                gap="0.3rem"
                            >
                                <p>{params.value?.count}</p>
                                <RelationsIcon />
                            </Stack>
                        )}
                    </div>
                )
            },
        },
    ]
    return rowData
        ? urlSearchParams.has('g')
            ? dataColumn
            : [
                  ...dataColumn,
                  {
                      width: 120,
                      spanHeaderHeight: true,
                      headerName: 'План/Факт',
                      cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                          if (params?.data && 'isWorkPosition' in params.data) return null

                          const data = params.data as Work

                          return (
                              <RowMonthHeaderRenderer
                                  isGant={hasParam('g', location.search) || hasParam('gf', location.search)}
                                  hasChildren={data?.hasChildren}
                                  cellRendererParams={params}
                              />
                          )
                      },
                  },
                  ...getHeadersMonths(
                      headersCPG.data.allHeaders,
                      (data, index) => {
                          if (!data?.data?.monthlyCharts) return null

                          const cell: monthlyChart = data.data?.monthlyCharts[index]!
                          // console.log(index)
                          return hasParam('g', location.search) ||
                              (hasParam('gf', location.search) &&
                                  DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINE_GANT_SMR')) ? (
                              <HtmlTooltip
                                  key={index}
                                  title={
                                      data.data!.volumeTotal === null &&
                                      profile.role !== 'client' &&
                                      hasParam('g', location.search)
                                          ? 'Нельзя создать диаграмму ганта'
                                          : ''
                                  }
                              >
                                  <div
                                      style={{
                                          overflow: 'unset',
                                      }}
                                      onContextMenu={(e) => {
                                          if (
                                              !cell!.drawPlan &&
                                              DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINE_GANT_SMR')
                                          )
                                              return
                                          e.preventDefault()
                                          setChangeableArray(
                                              data!.data?.monthlyCharts.map(
                                                  ({ drawPlan, drawFact, fact, month, year }: any) => {
                                                      return {
                                                          month,
                                                          year,
                                                          fact,
                                                          fill: urlSearchParams.has('g') ? drawPlan : drawFact,
                                                      }
                                                  }
                                              ) || []
                                          )
                                          setIndexClick({ month: index, work: data.data!.id })
                                          setAnchorElTemplateTable(data.eGridCell)
                                      }}
                                  >
                                      <div onClick={(e) => e.stopPropagation()}>
                                          {indexClick?.work === data.data?.id &&
                                              DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINE_GANT_SMR') && (
                                                  <Menu
                                                      id="basic-menu"
                                                      anchorEl={anchorElTemplateTable}
                                                      open={
                                                          anchorElTemplateTable !== null && index === indexClick?.month
                                                      }
                                                      onClose={() => {
                                                          setAnchorElTemplateTable(null)
                                                          setIndexClick(null)
                                                      }}
                                                      sx={{
                                                          '.MuiPaper-root': {
                                                              borderRadius: '6px',
                                                          },
                                                          '.MuiList-root': {
                                                              padding: 0,
                                                          },
                                                      }}
                                                  >
                                                      <BigEnterPlan
                                                          setAnchorElTemplateTable={setAnchorElTemplateTable}
                                                          onEdit={(res) => {
                                                              DOES_ROLE_HAS_ACCESS_TO_FEATURE(
                                                                  profile.role,
                                                                  'CREATE_LINE_GANT_SMR'
                                                              ) && dispatch(onEditAgKsg(res))
                                                          }}
                                                          changeableArray={changeableArray}
                                                          totalPlan={data!.data!.volumeTotal || 0}
                                                          setIndexClick={() =>
                                                              DOES_ROLE_HAS_ACCESS_TO_FEATURE(
                                                                  profile.role,
                                                                  'CREATE_LINE_GANT_SMR'
                                                              ) && setIndexClick(null)
                                                          }
                                                          id={data!.data!.id}
                                                          year={cell.year}
                                                          month={cell.month}
                                                          unit={data!.data!.unit}
                                                          monthlyCharts={data!.data!.monthlyCharts}
                                                          setMutationsLoading={setMutationsLoading}
                                                      />
                                                  </Menu>
                                              )}
                                      </div>
                                      {hasParam('g', location.search) && (
                                          <ChartCell
                                              cellRendererParams={data}
                                              filled={cell!.drawPlan}
                                              borderLeft={!data.data?.monthlyCharts[index - 1]?.drawPlan}
                                              backgroundCss={ganttColor(data.data)}
                                              borderRight={!data.data?.monthlyCharts[index + 1]?.drawPlan}
                                          />
                                      )}
                                      {hasParam('gf', location.search) && (
                                          <ChartCellFact
                                              filled={cell!.drawFact}
                                              borderLeft={!data.data?.monthlyCharts[index - 1]?.drawFact}
                                              backgroundCss={{ backgroundColor: '#FE9B3F' }}
                                              borderRight={!data.data?.monthlyCharts[index + 1]?.drawFact}
                                          />
                                      )}
                                      {/* Раньше здесь была проверка по строке урала. При наличии показателя "b" показывался данный блок */}
                                      {basicPlan && (
                                          <ChartCellBasePlan
                                              filled={!!data?.data?.monthlyCharts?.[index]?.drawBase}
                                              borderLeft={!data.data?.monthlyCharts[index - 1]?.drawBase}
                                              backgroundCss={{ backgroundColor: '#a8a29e' }}
                                              borderRight={!data.data?.monthlyCharts[index + 1]?.drawBase}
                                          />
                                      )}
                                  </div>
                              </HtmlTooltip>
                          ) : (
                              <MonthRenderer
                                  headers={headersCPG.data}
                                  cellRendererParams={data}
                                  setMutationsLoading={setMutationsLoading}
                                  chartIndex={index}
                              />
                          )
                      },
                      // TODO:
                      // 1. Очистить от старого ганта
                      hasParam('g', location.search) || hasParam('gf', location.search),
                      hasParam('g', location.search) || hasParam('gf', location.search) ? handleClickChart : undefined
                  ),
              ]
        : []
}
