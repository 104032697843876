import { MenuBook, Print } from '@mui/icons-material'
import DownloadMSG from '@mui/icons-material/GetApp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, Switch } from '@mui/material'
import { MouseEvent, useCallback, useState } from 'react'
import { TbLetterS } from 'react-icons/tb'
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { pagesNames } from '../..'
import { useGetModulesListQuery } from '../../api/importingData/importingData.api'
import { useDownloadMSGMutation } from '../../api/msg/msg.api'
import { useGetTemplatesStatusQuery, useSetTemplatesStatusMutation } from '../../api/templates/templates.api'
import { ConfigureAgGridColDefTemplate } from '../../components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate'
import RemainingItemsMenuMsg from '../../components/RemainingItemsMenu/RemainingItemsMenuMsg'
import TableTemplateMenuMsg from '../../components/TableTemplates/TableTemplateMenuMsg'
import { useDidMountEffect } from '../../hooks/useDidMountEffect'
import { FlexRowWrapper } from '../../pages/NewExecutorView/components/components.styles'
import GanttSVG from '../../shared/SVG/GanttSVG'
import { CalendarTime } from '../../shared/SVG/Svg'
import { HtmlTooltip } from '../../shared/components/HtmlTooltip'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { formDownloadLink } from '../../shared/utils'
import { drawersSelector, toggleAutoSendSnz } from '../../store/slices/drawersSlice'
import { filtersSelector } from '../../store/slices/filtersSlice'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { msgGantSelector, setGantLinkChoice } from '../../store/slices/msgGantSlice'
import { profileSelector } from '../../store/slices/profileSlice'
import { projectsSelector } from '../../store/slices/projectsSlice'
import { ISetupViewGrid, getSettings } from '../../store/slices/settings/settingsViewAgGrid'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import s from './TableLayout.module.scss'
import { Option, WrapperButtons, WrapperCell } from './TableLegend.style'
import ChangeMonthTableTop from './components/ChangeMonthTableTop'
import { GantLinkActiveChoise } from './components/GantLinkActiveChoise/GantLinkActiveChoise'
import SyncIconEl from './components/SyncIconEl'
import ViewButtons from './components/ViewButtons/ViewButtons'
import { StyledIconBtn } from './components/styles'

export default function MsgLayout() {
    const [anchorElTemplateTable, setAnchorElTemplateTable] = useState<null | HTMLElement>(null)
    const [anchorElRemaining, setAnchorElRemaining] = useState<null | HTMLElement>(null)
    const navigate = useNavigate()
    const { pageName } = useTypedSelector(projectsSelector)
    const { profile } = useTypedSelector(profileSelector)
    const { month, year } = useTypedSelector(monthMsgSelector)
    const { AgGrid } = useTypedSelector(drawersSelector)
    const { activeGantChoice } = useTypedSelector(msgGantSelector)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const locationSplit = location.pathname.split('/')
    const { filters } = useTypedSelector(filtersSelector)
    const [hiddenRowsIds, setHiddenRowsIds] = useImmer<{ id: number; code: string }[]>([])

    useDidMountEffect(() => {
        return () => {
            dispatch(setGantLinkChoice(null))
        }
    }, [location, month, year])

    const dispatch = useAppDispatch()

    const { projectId } = useParams()

    const isTemplateCreated = useGetTemplatesStatusQuery({
        projectId: Number(projectId),
    })

    const modulesList = useGetModulesListQuery({
        id: Number(projectId),
    })

    const [download] = useDownloadMSGMutation()

    /* Скачивание таблицы мсг */
    const downloadMSG = useCallback(() => {
        download({ id: Number(projectId), year: year, month: month + 1 }).then((r) => {
            let data = ''
            if ('data' in r) {
                data = r.data
            } else if ('data' in r.error) {
                data = r.error.data as string
            }
            formDownloadLink(data, locationSplit, projectId, false)
        })
    }, [projectId, month])

    /* Блок смены данных о состоянии таблицы в сторе */

    /**
     * Функция отправки запроса к базе на обновление вида таблицы
     * а так же, состояние загрузки для выключения свитчеров, защита от спама
     */
    const [updateSettings, { isLoading }] = useSetTemplatesStatusMutation()

    /**
     * данные с стора о состоянии таблицы
     */
    const { verificationPlan, basicPlan, msgForecast } = useTypedSelector(getSettings)

    /**
     * прослушивание события клика по свитчу
     */
    const changeStatus = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const name = event.target.name as keyof ISetupViewGrid
            updateSettings({
                projectId: Number(projectId),
                body: {
                    [name]: checked,
                },
            })
            //eslint-disable-next-line
        },
        [projectId]
    )

    /**
     * установка якоря, если он есть появится выподающее меню
     */
    const [anchorMoreFields, setAnchorMoreFields] = useState<null | HTMLSpanElement>(null)

    /* --------------------------------------------- */

    const loc = location.pathname.split('/')
    const locationLinkSwitch = () => {
        switch (loc[2]) {
            case 'workers':
                return `/ksg/workers/${Number(projectId)}`
            case 'mim':
                return `/ksg/mim/${Number(projectId)}`
            default:
                return `/ksg/${Number(projectId)}`
        }
    }

    const doesViewButtonsHidden = loc[2] === 'workers' || loc[2] === 'mim'

    function switchGantView() {
        setSearchParams((params) => {
            if (params.get('gant')) {
                params.delete('gant')
                dispatch(setGantLinkChoice(null))
            } else {
                params.set('gant', 'true')
            }
            return params
        })
    }

    const goToBrigadesPage = () => {
        navigate(`/brigades/${projectId}`, {
            state: {
                prevRoute: location.pathname,
            },
        })
    }

    return (
        <main className={s.main}>
            <Menu anchorEl={anchorMoreFields} open={!!anchorMoreFields} onClose={() => setAnchorMoreFields(null)}>
                <WrapperButtons>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={basicPlan}
                                    onChange={changeStatus}
                                    name={'basicPlan'}
                                />
                            }
                            label={'Базовый план КСГ'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={verificationPlan}
                                    onChange={changeStatus}
                                    name={'verificationPlan'}
                                />
                            }
                            label={'Верификация факта'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>

                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={msgForecast}
                                    onChange={changeStatus}
                                    name={'msgForecast'}
                                />
                            }
                            label={'Прогноз МСГ'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                </WrapperButtons>
            </Menu>

            <Menu
                id="basic-menu"
                anchorEl={anchorElTemplateTable}
                open={!!anchorElTemplateTable}
                onClose={() => setAnchorElTemplateTable(null)}
            >
                <TableTemplateMenuMsg setAnchorElTemplateTable={setAnchorElTemplateTable} />
            </Menu>

            <Menu
                id="basic-menu"
                anchorEl={anchorElRemaining}
                open={!!anchorElRemaining}
                onClose={() => setAnchorElRemaining(null)}
            >
                <RemainingItemsMenuMsg setAnchorElRemaining={setAnchorElRemaining} />
            </Menu>

            <section className={s.top}>
                <div></div>
                <div className={s.top_right}>
                    {pageName === 'msg' && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'PRINT_MSG_SMR') && (
                        <HtmlTooltip title='Режим "Печать"'>
                            {/* <StyledIconBtn onClick={(e) => navigate(`/msg/${projectId}/print${location.search}`)}> */}
                            <StyledIconBtn
                                onClick={(e) => {
                                    localStorage.setItem('filters', JSON.stringify(filters))
                                    localStorage.setItem('hiddenRowsIds', JSON.stringify(hiddenRowsIds))
                                    localStorage.setItem('month', JSON.stringify(month))
                                    localStorage.setItem('year', JSON.stringify(year))
                                    return window.open(
                                        `/msg/${projectId}/print${location.search}`,
                                        '_blank',
                                        'rel=noopener noreferrer'
                                    )
                                }}
                            >
                                <Print />
                            </StyledIconBtn>
                        </HtmlTooltip>
                    )}

                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'VIEW_BRIGADES') && (
                        <HtmlTooltip title="Справочник бригад">
                            <StyledIconBtn onClick={goToBrigadesPage}>
                                <MenuBook />
                            </StyledIconBtn>
                        </HtmlTooltip>
                    )}

                    <ChangeMonthTableTop />

                    <ConfigureAgGridColDefTemplate
                        key={pageName}
                        type={pageName as pagesNames}
                        AgGrid={AgGrid}
                        excludeColIds={['monthlyCharts']}
                    />

                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_AUTO') && (
                        <HtmlTooltip title="Отправить работы исполнителю для сбора факта">
                            <IconButton
                                sx={{
                                    backgroundColor: '#f6f7fb',
                                    borderRadius: '6px',
                                }}
                                color="primary"
                                component="span"
                                onClick={() => dispatch(toggleAutoSendSnz())}
                            >
                                <CalendarTime />
                            </IconButton>
                        </HtmlTooltip>
                    )}
                    {pageName === 'msg' && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DOWNLOAD_MSG_SMR') && (
                        <HtmlTooltip title="Скачать МСГ">
                            <IconButton
                                sx={{
                                    backgroundColor: '#f6f7fb',
                                    borderRadius: '6px',
                                }}
                                color="primary"
                                component="span"
                                onClick={downloadMSG}
                            >
                                <DownloadMSG />
                            </IconButton>
                        </HtmlTooltip>
                    )}
                    {modulesList.data?.modules.length && profile.role !== 'client' ? (
                        <SyncIconEl modulesList={modulesList.data?.modules} />
                    ) : null}

                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_MANUALLY') && (
                        <HtmlTooltip title="Оставшиеся пункты меню">
                            <IconButton
                                sx={{
                                    backgroundColor: '#f6f7fb',
                                    borderRadius: '6px',
                                }}
                                color="primary"
                                component="span"
                                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                    setAnchorElRemaining(e.currentTarget)
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </HtmlTooltip>
                    )}
                </div>
            </section>

            <FlexRowWrapper justifyContent={'space-between'} pr={1.5}>
                <FlexRowWrapper>
                    <div className={s.glass_buttons}>
                        <NavLink to={locationLinkSwitch()}>КСГ</NavLink>
                        <NavLink
                            className={location.pathname.split('/').includes('msg') ? s.act : ''}
                            to={`/msg/${Number(projectId)}`}
                        >
                            МСГ
                        </NavLink>
                    </div>

                    <div className={s.middle_middle}>
                        <NavLink to={`${Number(projectId)}`}>СМР</NavLink>
                        <NavLink to={`workers/${Number(projectId)}`}>Персонал</NavLink>
                        <NavLink to={`mim/${Number(projectId)}`}>Механизмы</NavLink>
                    </div>
                </FlexRowWrapper>
                <FlexRowWrapper>
                    {activeGantChoice?.cellRendererParams && <GantLinkActiveChoise {...activeGantChoice} />}
                </FlexRowWrapper>
                <div className={s.middle_right}>
                    {pageName === 'msg' && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'VIEW_GANT_BRIGADES') && (
                        <HtmlTooltip title='Диаграмма Ганта "Бригады"'>
                            <StyledIconBtn onClick={switchGantView} isActive={!!searchParams.get('gant')}>
                                <GanttSVG isActive={!!searchParams.get('gant')} />
                            </StyledIconBtn>
                        </HtmlTooltip>
                    )}
                    {!doesViewButtonsHidden && <ViewButtons tableType={'msg'} />}
                    <HtmlTooltip title="Настройки отображения строк">
                        <StyledIconBtn
                            onClick={(event: React.MouseEvent<HTMLSpanElement> | undefined) => {
                                event && setAnchorMoreFields(event.currentTarget)
                            }}
                        >
                            <TbLetterS />
                        </StyledIconBtn>
                    </HtmlTooltip>
                </div>
            </FlexRowWrapper>
            <Outlet {...(pageName === 'msg' && { context: { hiddenRowsIds, setHiddenRowsIds } })} />
        </main>
    )
}
