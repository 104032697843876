import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { queryTermsType } from '../../api/filters/filters.api'

export interface levelsUnlimitedI {
    1?: string[]
    2?: string[]
    3?: string[]
    4?: string[]
    5?: string[]
    6?: string[]
    7?: string[]
}

export interface IMonthPlanFilter {
    date: {
        first: number // year
        second: number // month
    }
    status: 'exist' | 'not_exist'
}

export interface ICritPathFilter {
    enable: boolean
    showParent: boolean
}

export interface filtersKsgUnlimitedI {
    contractorCompany: levelsUnlimitedI
    objName: levelsUnlimitedI
    objTitle: levelsUnlimitedI
    rdCode: levelsUnlimitedI
    rdStatus: levelsUnlimitedI
    workName: levelsUnlimitedI
    workFilter: 'completed' | 'in_process' | 'before_thirty_days' | 'next_month' | null
    planFilter: IMonthPlanFilter[] | []
    workGroup: levelsUnlimitedI
    criticalFilter: ICritPathFilter
    workGroupSpecial: boolean
    brigades: string[]
}

const levelsUnlimited = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
}

const filtersKsg: filtersKsgUnlimitedI = {
    contractorCompany: levelsUnlimited,
    objName: levelsUnlimited,
    objTitle: levelsUnlimited,
    rdCode: levelsUnlimited,
    rdStatus: levelsUnlimited,
    workName: levelsUnlimited,
    workFilter: null,
    planFilter: [],
    workGroup: levelsUnlimited,
    criticalFilter: {
        enable: false,
        showParent: true,
    },
    workGroupSpecial: false,
    brigades: []
}

export enum levels {
    levelOne = '1',
    levelTwo = '2',
    levelThree = '3',
    levelFour = '4',
    levelFive = '5',
    levelSix = '6',
    levelSeven = '7',
}

interface initialStateI {
    filters: filtersKsgUnlimitedI
    queryTerm: queryTermsType
    openedRowsArr: number[]
}

const initialState: initialStateI = {
    filters: filtersKsg,
    queryTerm: 'objTitle',
    openedRowsArr: [],
}

const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        refreshFilters(state) {
            state.filters = filtersKsg
            state.openedRowsArr = []
        },
        handleChangeWorkfilter(state, action: PayloadAction<filtersKsgUnlimitedI['workFilter']>) {
            state.filters.workFilter = action.payload
        },
        handleChangeMonthPlanfilter(state, action: PayloadAction<IMonthPlanFilter>) {
            const alreadySetFilterIndex = state.filters.planFilter.findIndex((monthPlanFilter, index) => {
                return (
                    monthPlanFilter.date.first === action.payload.date.first &&
                    monthPlanFilter.date.second === action.payload.date.second
                )
            })
            if (alreadySetFilterIndex >= 0) {
                const newState = [...state.filters.planFilter]
                newState[alreadySetFilterIndex] = action.payload
                state.filters.planFilter = newState
                return
            } else {
                state.filters.planFilter = [...state.filters.planFilter, ...[action.payload]]
            }
        },
        handleResetMonthPlanfilter(state, action: PayloadAction<IMonthPlanFilter['date']>) {
            state.filters.planFilter = state.filters.planFilter.filter((monthPlanFilter) => {
                if (monthPlanFilter.date.first === action.payload.first) {
                    if (monthPlanFilter.date.second === action.payload.second) {
                        return false
                    }
                }
                return true
            })
        },
        handleChangeCritPathFilter(state, action: PayloadAction<Partial<filtersKsgUnlimitedI['criticalFilter']>>) {
            state.filters.criticalFilter = { ...state.filters.criticalFilter, ...action.payload }
        },
        handleChangeFilters(
            state,
            action: PayloadAction<{
                checked: boolean
                level: levels
                key?: string
                arr?: string[]
            }>
        ) {
            const { checked, level, key, arr } = action.payload
            state.openedRowsArr = []
            if (key) {
                if (checked) {
                    state.filters[state.queryTerm][level]?.push(key)
                } else {
                    const index = (state.filters[state.queryTerm][level] || [])?.indexOf(key)
                    if (index > -1) {
                        state.filters[state.queryTerm][level]?.splice(index, 1)
                    }
                }
            } else {
                if (arr && checked) {
                    state.filters[state.queryTerm][level] = arr
                } else {
                    state.filters[state.queryTerm][level] = []
                }
            }
        },

        setQueryTerm(state, action: PayloadAction<queryTermsType>) {
            state.queryTerm = action.payload
        },

        setOpenedRowsArr(state, action: PayloadAction<number>) {
            if (!state.openedRowsArr.includes(action.payload)) {
                state.openedRowsArr.push(action.payload)
            } else {
                state.openedRowsArr = state.openedRowsArr.filter((e) => e !== action.payload)
            }
        },
        setWorkGroupSpecialFilter(state, action: PayloadAction<boolean>) {
            state.filters.workGroupSpecial = action.payload
            if (action.payload === true) {
                state.filters.workGroup = levelsUnlimited
            }
        },
        setBrigades(state, action: PayloadAction<string[]>) {
            state.filters.brigades = action.payload
        },
    },
})

export const {
    handleChangeFilters,
    setQueryTerm,
    refreshFilters,
    setOpenedRowsArr,
    handleChangeWorkfilter,
    handleChangeMonthPlanfilter,
    handleResetMonthPlanfilter,
    handleChangeCritPathFilter,
    setWorkGroupSpecialFilter,
    setBrigades
} = slice.actions
export default slice.reducer

export const filtersSelector = (state: RootState) => state.filters
