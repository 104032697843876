/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */

import { useCallback, useEffect, useState } from 'react'
import { BodyScrollEndEvent } from 'ag-grid-community'
import { useAppDispatch } from '../store/store'
import { AsyncThunkAction } from '@reduxjs/toolkit'

interface IInitialParams {
    limit: number,
    offset: number,
}

interface IConfigPagination<T> {
    totalCount: number
    sendFn: (params:T&IInitialParams)=>AsyncThunkAction<any, any, any>,
    initialParams: T & IInitialParams,
    thenFn?: () => void
    catchFn?: () => void
    requiredDeps?: any[]
    resetToInitialDeps?: any[]
}

/**
 * Хук для вертикальной динамической пагинации, работает через санки
 *
 * @param sendFn - экшен для диспатча события запроса
 * @param thenFn - необязательная функция, которая вызывается после успешного запроса
 * @param catchFn - необязательная функция, которая вызывается после обрушения запроса
 * @param resetToInitialDeps - массив зависимостей, который должны скинуть параметры запроса к начальным
 * @param initialParams - начальные данные для запроса
 * @param requiredDeps - массив зависимостей без которых не должен происходить запрос
 * @param totalCount - общее количество записей в БД
 *
 * Возвращает функцию для AgGrid
 *
 * @example
 *  const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsKSG>({
 *         initialParams: { limit: LIMIT, offset: 0, id: Number(projectId) },
 *         sendFn: getData,
 *         totalCount: totalRows,
 *         requiredDeps: [Number(projectId), totalRows !== null, token],
 *         resetToInitialDeps: [filters,listModeOn],
 *     })
 *     return (
 *     <AgGridReact
 *               onBodyScrollEnd={scrollHandlerFoAG}
 *               { ...other_props }
 *     ></AgGridReact>
 *     )
 */
export function useVerticalPagination<T>({
                                             sendFn,
                                             thenFn = () => {
                                             },
                                             catchFn = () => {
                                             },
                                             resetToInitialDeps = [],
                                             initialParams,
                                             requiredDeps = [],
                                             totalCount,
                                         }: IConfigPagination<T>) {
    /* локальный стейт для перехвата параметров запроса снаружи, далее управляется в хуке */
    const [localParams, setLocalParams] = useState(initialParams)

    const dispatch = useAppDispatch()

    /* локальный стейт для запуска запроса */
    const [fetch, setFetch] = useState(false)

    /* локальный стейт для определения идет запрос в данный момент или нет */
    const [fetching, setFetcheing] = useState(false)

    /**
     * функция, которая возвращается из хука,
     * прослушивает событие скролла внутри таблицы AgGrid,
     * определяет положения конца таблицы и устанавливает новые параметры запроса,
     * чем тригерит запрос к БД
     */
    const scrollHandlerFoAG = useCallback((e: BodyScrollEndEvent<any, any>) => {
        const bufferRowFromEndCount = 1
        const rowHeight = e.api.getSizesForCurrentTheme().rowHeight
        const bufferHeight = bufferRowFromEndCount * rowHeight
        const gridHeight = e.api.getDisplayedRowCount() * rowHeight
        const gridEdge = gridHeight - bufferHeight
        const scrollPosition = e.api.getVerticalPixelRange().bottom
        const paginationShouldContinue = ((scrollPosition >= gridEdge) && gridEdge > 0) && (localParams.offset <= totalCount)

        if (paginationShouldContinue) {
            setLocalParams(prevState => ({
                ...prevState,
                limit: 1000,
                offset: prevState.offset += prevState.limit,
            }))
        }
    }, [totalCount, localParams])

    /**
     * функция запроса к БД, если индикатор загрузки не активен
     * переключает его в активное состояние и делает загрузку,
     * если вдруг случайно произойдет тригер вызова этой функции, запрос не повторится
     * после завершения запроса выключает индикатор загрузки
     * и готов к новому запросу
     */
    const send = useCallback((indicator: boolean) => {
        if (!indicator) {
            setFetcheing(true)
            setFetch(false)
            dispatch(sendFn(localParams))
                .then(thenFn)
                .catch(catchFn)
                .finally(() => setFetcheing(false))
        }
        //eslint-disable-next-line
    }, [thenFn, catchFn, sendFn, localParams])

    /**
     * Эффект следит за fetch, fetching и requiredDeps
     * сам вызов делается благодаря переключению fetch в активное состояние,
     * далее смотрится массив обязательных для вызова зависимостей,
     * если там все трушное делается запрос и внутрь передается текущее состояние загрузки (описано выше),
     * так как массив не обязателен, идет проверка на его длину, если ее нет, просто вызывается запрос
     */
    useEffect(() => {
        if (fetch) {
            if (requiredDeps?.length) {
                const flag = requiredDeps.every((_) => !!_)
                if (flag) {
                    send(fetching)
                }
            } else {
                send(fetching)
            }
        }
        //eslint-disable-next-line
    }, [fetch, fetching, ...requiredDeps])

    /**
     * Эффект следит за массивом зависимостей для сброса параметров запроса, если вдруг что изменится
     * локальные параметры сбросятся до тех что передавались от родителя
     */
    useEffect(() => {
        setLocalParams(initialParams)
        //eslint-disable-next-line
    }, [...resetToInitialDeps])

    /**
     * Эффект следит за локальными параметрами, когда они поменяются, триггерится вызов
     */
    useEffect(() => {
        setFetch(true)
    }, [localParams])
    return {
        scrollHandlerFoAG,
    }
}