import React, { Fragment, MouseEvent } from 'react'
import s from '../Header.module.scss'
import { Box, Breadcrumbs, Button, Typography } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import IconButtonEl from './IconButtonEl'
import { BreadCrumbTitle } from './BreadCrumbTitle'
import { FlexRowWrapper } from '../../../pages/NewExecutorView/components/components.styles'
import { Link, useParams } from 'react-router-dom'

interface IBreadCrumbTitle {
    title: string
    link?: string
}

export default function ButtonTitleEl({
    titles,
    isProjectTitleNav,
    setOpenPopoverLinks,
    projectTitle,
}: {
    titles: IBreadCrumbTitle[]
    isProjectTitleNav?: boolean
    setOpenPopoverLinks?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
    projectTitle: string
}) {
    const { projectId } = useParams()
    return (
        <FlexRowWrapper sx={{ gap: '0 !important' }}>
            <IconButtonEl />

            <FlexRowWrapper sx={{ gap: '0 !important' }}>
                <Breadcrumbs
                    sx={{
                        '.MuiBreadcrumbs-ol': {
                            display: 'flex !important',
                            flexWrap: 'noWrap',
                            justifyContent: 'flex-start',
                        },
                        color: '#2B3648',
                    }}
                >
                    <Button
                        onClick={(event: MouseEvent<HTMLButtonElement>) => {
                            setOpenPopoverLinks && setOpenPopoverLinks(event.currentTarget)
                        }}
                        sx={{
                            color: '#2B3648',
                            textTransform: 'capitalize',
                            fontSize: 18,
                            fontWeight: 600,
                        }}
                        endIcon={setOpenPopoverLinks ? <ArrowDropDown /> : false}
                    >
                        {isProjectTitleNav ? (
                            <Link to={`/ksg/${projectId}`}>
                                <BreadCrumbTitle>{projectTitle}</BreadCrumbTitle>
                            </Link>
                        ) : (
                            <BreadCrumbTitle>{projectTitle}</BreadCrumbTitle>
                        )}
                    </Button>
                    {titles?.map((title) => {
                        return title.link ? (
                            <Link to={title.link}>
                                <BreadCrumbTitle>{title.title}</BreadCrumbTitle>
                            </Link>
                        ) : (
                            <BreadCrumbTitle>{title.title}</BreadCrumbTitle>
                        )
                    })}
                    {/* <BreadCrumbTitle>{title.title}</BreadCrumbTitle>
                    {secondTitle ? <BreadCrumbTitle>{secondTitle}</BreadCrumbTitle> : null}
                    {thirdTitle ? <BreadCrumbTitle>{thirdTitle}</BreadCrumbTitle> : null} */}
                </Breadcrumbs>
            </FlexRowWrapper>
        </FlexRowWrapper>
    )
}
