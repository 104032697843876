import { Button } from '@mui/material'
import pic from './backgroundStart.png'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import s from './EmptyPlaceholder.module.scss'
import { ReactNode } from 'react'

export default function EmptyPlaceholder({
    onClick,
    text,
    icon,
    buttonText,
    mt,
}: {
    onClick?: () => void
    text: string | ReactNode
    icon?: ReactJSXElement
    buttonText?: string
    mt?: boolean
}) {
    return (
        <section
            className={s.container}
            style={{
                marginTop: mt ? '5rem' : '',
            }}
        >
            <div className={s.content}>
                <p>{text}</p>
                {buttonText && (
                    <Button
                        sx={{
                            marginTop: '1.5rem',
                        }}
                        onClick={onClick}
                        variant="contained"
                        startIcon={icon}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
            <img src={pic} alt="" />
        </section>
    )
}
