import { addMonths, format, isValid, parse, subMonths } from 'date-fns'
import { Work } from '../../api/ksg/ksg.def'
import { ILibGanttLink, ITask, TLocalGanttLinkId } from './DHTGant.def'
import { useGetProjectDates } from './DHTGantOnlyTable.model'
import './customStylesOverrides.scss'
import { GanttStatic } from './dhtmlxgantt'
import './dhtmlxgantt.css'
import { Task } from './dhtmlxgantt.js'

export const parseStringToDate = (date: string | null | undefined, formatValue = 'dd.MM.yyyy') => {
    if (!date) return null
    const parsedDate = parse(date as string, formatValue, new Date())
    const isValidDate = isValid(parsedDate)
    return isValidDate ? parsedDate : null
}

export const formatDateToString = (date: Date | null | undefined, formatValue = 'dd.MM.yyyy') => {
    if (!date) return null
    return format(date, 'dd.MM.yyyy')
}

const calculateTaskProgress = (plan: number | null, fact: number | null) => (plan ? (fact ?? 0) / plan : 0)
const getTaskText = (level: number | null, name: string) => `<b>Ур. ${level}.</b> ${name}`

export const createTask = (work: Work): ITask => {
    const task: ITask = {
        ...work,
        parent: work?.id_parent,
        workName: work?.workName,
        text: getTaskText(work?.level, work?.workName),
        expanded: true,
        progress: calculateTaskProgress(work?.volumeDonePlan, work?.volumeDoneFact),
        type: work.hasChildren ? 'project' : 'task',
    }

    const startDate = parseStringToDate(work.operationalStartDate)
    const endDate = parseStringToDate(work.operationalEndDate)

    if (startDate) {
        task.start_date = startDate
    }

    if (endDate) {
        task.end_date = endDate
    }

    return task
}

type TDeleteLinksBetweenTasksProps = {
    source: number
    target: number
}

export const getGanttLinksBySourceAndTarget = (links: ILibGanttLink[], source: number, target: number) => {
    const isMatchingLink = (link: ILibGanttLink, source: number, target: number) => {
        const linkSource = Number(link.source)
        const linkTarget = Number(link.target)

        if (linkSource === source && linkTarget === target) return true
        if (linkSource === target && linkTarget === source) return true
        return false
    }

    return links.filter((link) => isMatchingLink(link, source, target))
}

export const deleteLinksBetweenTasks =
    (gantt: GanttStatic) =>
    ({ source, target }: TDeleteLinksBetweenTasksProps) => {
        const links = gantt.getLinks() as ILibGanttLink[]
        const filteredLinks = getGanttLinksBySourceAndTarget(links, source, target)
        filteredLinks.forEach((link) => deleteLinkPermanently(gantt, link.id))
    }

export const deleteLinkPermanently = (gantt: GanttStatic, linkId: TLocalGanttLinkId) => {
    const link = gantt.getLink(linkId)
    link.canDelete = true
    gantt.refreshLink(linkId)
    gantt.deleteLink(linkId)
}

export const batchUpdateTasksDates = (gantt: GanttStatic) => (works: Work[]) => {
    gantt.batchUpdate(() => {
        works.forEach((work: Work) => {
            const task = gantt.getTask(work.id)
            const startDate = parseStringToDate(work.operationalStartDate) ?? undefined
            const endDate = parseStringToDate(work.operationalEndDate) ?? undefined

            task.start_date = startDate
            task.end_date = endDate
            gantt.updateTask(task.id)
        })
    })
}

const PROJECT_DATE_LINE_WIDTH = 22

export const addLayers = (gantt: GanttStatic, projectDates: ReturnType<typeof useGetProjectDates>) => {
    // Базовый план
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                if (task.duration) {
                    return drawBasePlan(gantt, task)
                }
                return null
            },
        },
    })

    // Линия старт проекта
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                return drawProjectDateLine(gantt, {
                    task,
                    date: addMonths(projectDates!.start, 1),
                    text: 'Старт проекта',
                    className: 'project_date_line--start',
                    offset: PROJECT_DATE_LINE_WIDTH,
                })
            },
        },
    })

    // Линия конец проекта
    gantt.addTaskLayer({
        renderer: {
            render: (task: Task) => {
                return drawProjectDateLine(gantt, {
                    task,
                    date: subMonths(projectDates!.end, 1),
                    text: 'Конец проекта',
                    className: 'project_date_line--end',
                })
            },
        },
    })
}

const drawProjectDateLine = (
    gantt: GanttStatic,
    {
        task,
        date,
        text,
        className,
        offset = 0,
    }: {
        task: Task
        date: Date
        text: string
        className: string
        offset?: number
    }
) => {
    const element = document.createElement('div')
    element.className = `gantt_task_line project_date_line ${className}`
    element.setAttribute('data-task-id', task.id.toString())
    const sizes = gantt.getTaskPosition(task)

    if (task.level === 0) {
        element.innerHTML = `<span class="project_date_line_text">${text}</span>`
    }

    element.style.left = gantt.posFromDate(date) - offset + 'px'
    element.style.top = sizes.top + 'px'
    element.style.height = sizes.rowHeight + 2 + 'px'
    element.style.width = PROJECT_DATE_LINE_WIDTH + 'px'
    return element
}

const drawBasePlan = (gantt: GanttStatic, task: Task) => {
    if (!task.startDate || !task.endDate) return
    const start_base_plan_date = parseStringToDate(task.startDate) as Date
    const end_base_plan_date = parseStringToDate(task.endDate) as Date
    const basePlanStart = gantt.date.add(new Date(start_base_plan_date), 0, 'day')
    const basePlanEnd = gantt.date.add(new Date(end_base_plan_date), 0, 'day')
    const basePlan = document.createElement('div')
    basePlan.className = 'gantt_task_line base_bar'
    basePlan.setAttribute('data-task-id', task.id.toString())
    const sizes = gantt.getTaskPosition(task, basePlanStart, basePlanEnd)
    basePlan.style.left = sizes.left + 'px'
    basePlan.style.top = sizes.top + sizes.rowHeight / 2 - 18 + 'px'
    basePlan.style.height = sizes.height + 'px'
    basePlan.style.width = gantt.posFromDate(basePlanEnd) - gantt.posFromDate(basePlanStart) + 'px'
    return basePlan
}
