import { useParams } from 'react-router-dom'
import { useGetWorksNamesQuery } from '../../api/relations/relations.api'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import axios, { Canceler } from 'axios'
import { Work } from '../../api/ksg/ksg.def'
import { deployLinks } from '../../shared/config/config'
import { axiosRefetchToken } from '../../shared/utils'
import { authSelector } from '../../store/slices/authSlice'
import s from './AddLevelKsg.module.scss'
import { stylesLevelAg } from '../../pages/AgGrid/AgGrid.service'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ButtonsAndWarningEl } from '../../shared/components/ButtonsAndWarning/ButtonsAndWarning'

import { FormError } from '../../pages/Projects/component/FormikFormC'
import { useFormik } from 'formik'
import { initialFormValuesAg, initialFormValuesAgI, validationSchemaAg } from './AddLevel.service'
import TextLineEl from './components/TextLineEl'
import DateLine from './components/DateLine'
import WideTextLineEl from './components/WideTextLineEl'
import { format, parse } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useAddWorkMutation } from '../../api/works/works.api'
import { workStatuses } from '../../api/works/works.def'
import { onAddWorkKsgAg } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'

type levelsNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7

export default function AddLevelKsg({
    addLevelData,
    setIsOpenAddLevel,
}: {
    addLevelData: {
        id: number | null
        index: number
        level: number | null
    }
    setIsOpenAddLevel: Dispatch<SetStateAction<boolean>>
}) {
    const [workName, setWorkName] = useState('')
    // const [pickedWork, setPickedWork] = useState<Work | null>(null)

    const { token } = useTypedSelector(authSelector)

    const dispatch = useAppDispatch()

    const { projectId } = useParams()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const namesQuery = useGetWorksNamesQuery({
        projectId: Number(projectId),
        limit: 100,
        offset: 0,
    })

    const [worksReq] = useAddWorkMutation()

    const onSubmit = useCallback(
        (values: initialFormValuesAgI) => {
            worksReq({
                id: Number(projectId),
                body: {
                    ...values,
                    id_parent: addLevelData.id,
                    costER: values.costER ? Number(values.costER) : null,
                    humanHourCost: values.humanHourCost ? Number(values.humanHourCost) : null,
                    machineHourCost: values.machineHourCost ? Number(values.machineHourCost) : null,
                    volumeTotal: values.volumeTotal ? Number(values.volumeTotal) : null,
                    rdVersion: values.rdVersion ? Number(values.rdVersion) : null,
                    rdStatus: values.rdStatus ? (values.rdStatus as workStatuses) : null,
                    rdDate: values.rdDate ? format(values.rdDate as Date, 'dd.MM.yyyy') : null,
                    rdDateApproval: values.rdDateApproval ? format(values.rdDateApproval as Date, 'dd.MM.yyyy') : null,
                    startDate: values.startDate ? format(values.startDate as Date, 'dd.MM.yyyy') : null,
                    endDate: values.endDate ? format(values.endDate as Date, 'dd.MM.yyyy') : null,
                },
            })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(t('work_added'), {
                        variant: 'success',
                    })
                    dispatch(onAddWorkKsgAg({ works: res.data, id: addLevelData.index }))
                    setIsOpenAddLevel(false)
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                        variant: 'error',
                    })
                    console.error(e)
                })
        },
        [addLevelData.id, addLevelData.index, dispatch, enqueueSnackbar, projectId, setIsOpenAddLevel, t, worksReq]
    )

    const formik = useFormik<initialFormValuesAgI>({
        initialValues: initialFormValuesAg,
        onSubmit,
        validationSchema: validationSchemaAg,
    })

    const isDisabled = () => {
        return (!formik.isValid && Object.values(formik.touched).every((item) => item)) || formik.isSubmitting
    }

    useEffect(() => {
        let cancel: Canceler
        axios<{ data: Work[]; total: number }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${projectId}/works/cpg-list-next-generation`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                limit: 10,
                offset: 0,
            },
            data: { filter: JSON.parse(workName || '{}')?.workName },
            cancelToken: new axios.CancelToken((c) => {
                cancel = c
            }),
        })
            .then((res) => {
                const parserWork = JSON.parse(workName || '{}')
                const work = res.data.data.filter(
                    (work) => work.workName === parserWork?.workName && work.id === parserWork?.id
                )[0]

                formik.setValues({
                    objTitle: work.objTitle || '',
                    objName: work.objName || '',
                    rdGroup: work.rdGroup || '',
                    rdCode: work.rdCode || '',
                    rdStatus: work.rdStatus || '',
                    rdDateApproval: work.rdDateApproval
                        ? parse(work.rdDateApproval as string, 'dd.MM.yyyy', new Date())
                        : null,
                    rdVersion: work.rdVersion ? String(work.rdVersion) : '',
                    rdDate: work.rdDate ? parse(work.rdDate as string, 'dd.MM.yyyy', new Date()) : null,
                    workGroup: work.workGroup || '',
                    contractorCompany: work.contractorCompany || '',
                    workName: work.workName || '',
                    unit: work.unit || '',
                    volumeTotal: String(work.volumeTotal) || '',
                    startDate: work.startDate ? parse(work.startDate as string, 'dd.MM.yyyy', new Date()) : null,
                    endDate: work.endDate ? parse(work.endDate as string, 'dd.MM.yyyy', new Date()) : null,
                    humanHourCost: work.humanHourCost ? String(work.humanHourCost) : '',
                    machineHourCost: work.machineHourCost ? String(work.machineHourCost) : '',
                    costER: work.machineHourCost ? String(work.costER) : '',
                    costRemaining: work.costRemaining ? String(work.costRemaining) : '',
                })
            })
            .catch((e) => {
                if (axios.isCancel(e)) return
                axiosRefetchToken(e?.response?.status)
            })
        return () => cancel()
    }, [projectId, token, workName])

    const numberProps = { type: 'number', step: 0.000001, min: 0, max: 99999999 }

    return (
        <>
            <div className={s.top}>
                <h4>Добавление подуровня</h4>
                {addLevelData.level !== null && addLevelData.level > 1 ? (
                    <div className={s.levels}>
                        <div className={stylesLevelAg((addLevelData.level - 1) as levelsNumbers)}>
                            УР {addLevelData.level - 1}
                        </div>
                        <ArrowForwardIcon
                            sx={{
                                color: '#9AA2B0',
                                fontSize: '1rem',
                            }}
                        />
                        <div className={stylesLevelAg(addLevelData.level as levelsNumbers)}>
                            УР {addLevelData.level}
                        </div>
                    </div>
                ) : (
                    <div className={stylesLevelAg(addLevelData.level as levelsNumbers)}>УР {addLevelData.level}</div>
                )}
            </div>

            <div className={s.form}>
                <FormControl fullWidth>
                    <FormLabel
                        sx={{
                            color: '#5c6e8c',
                            fontWeight: '500',
                            fontSize: '14px',
                            marginBottom: '0.4rem',
                        }}
                    >
                        Скопировать данные из уровня
                    </FormLabel>
                    <Select
                        data-testid={'status'}
                        sx={{
                            backgroundColor: '#F6F7FB',
                            fontSize: '14px',
                            maxWidth: 360,

                            '.MuiSelect-select': {
                                padding: '0.5rem',
                            },
                        }}
                        fullWidth
                        value={workName}
                        onChange={(e) => setWorkName(e.target.value)}
                    >
                        {namesQuery.data?.data
                            .filter((v) => v.workName)
                            .map((v) => (
                                <MenuItem key={v.id} id={v.id.toString()} value={JSON.stringify(v)}>
                                    {v.workName.length > 59
                                        ? `${v.numOrder}. ${v.workName.substring(0, 60)}...`
                                        : `${v.numOrder}. ${v.workName}`}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>

            <div className={s.main}>
                <h4 className={s.main_title}>Заполните обязательные поля</h4>
                <form onSubmit={formik.handleSubmit} className={s.main_form}>
                    <TextLineEl
                        title="Титул"
                        errorTitle={formik.errors.objTitle}
                        touched={formik.touched.objTitle}
                        {...formik.getFieldProps('objTitle')}
                        required
                    />

                    <TextLineEl
                        title="Объект"
                        errorTitle={formik.errors.objName}
                        touched={formik.touched.objName}
                        {...formik.getFieldProps('objName')}
                        required
                    />

                    <TextLineEl
                        title="Раздел РД"
                        errorTitle={formik.errors.rdGroup}
                        touched={formik.touched.rdGroup}
                        {...formik.getFieldProps('rdGroup')}
                    />

                    <TextLineEl
                        title="Шифр РД"
                        errorTitle={formik.errors.rdCode}
                        touched={formik.touched.rdCode}
                        {...formik.getFieldProps('rdCode')}
                    />

                    <div className={s.input_line}>
                        <p>Статус выдачи РД:</p>

                        <FormControl
                            sx={{
                                minWidth: '11rem',
                            }}
                        >
                            <Select
                                sx={{
                                    backgroundColor: '#F6F7FB',

                                    '.MuiSelect-select': {
                                        padding: '0.14rem .4rem',
                                        borderRadius: '4px',
                                        fontSize: 12,
                                    },
                                }}
                                {...formik.getFieldProps('rdStatus')}
                                error={!!formik.errors.rdStatus?.length}
                            >
                                {[
                                    'Согласовано',
                                    'На согласовании',
                                    'Корректировка',
                                    'Разработка',
                                    'В производстве',
                                    'Не в работе',
                                ].map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v} проект
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormError isTouched={formik.touched.rdStatus} error={formik.errors.rdStatus} right />
                        </FormControl>
                    </div>
                    <DateLine
                        errorTitle={formik.errors.rdDateApproval}
                        touched={formik.touched.rdDateApproval}
                        title="Дата согласования РД"
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDateApproval', newValue)
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDateApproval: true,
                            })
                        }
                        value={formik.values.rdDateApproval}
                    />
                    <TextLineEl
                        title="Версия РД"
                        errorTitle={formik.errors.rdVersion}
                        touched={formik.touched.rdVersion}
                        {...formik.getFieldProps('rdVersion')}
                        {...numberProps}
                    />
                    <DateLine
                        errorTitle={formik.errors.rdDate}
                        touched={formik.touched.rdDate}
                        title="Дата выдачи РД"
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDate', newValue)
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDate: true,
                            })
                        }
                        value={formik.values.rdDate}
                    />
                    <WideTextLineEl
                        title="Группа работ"
                        errorTitle={formik.errors.workGroup}
                        touched={formik.touched.workGroup}
                        {...formik.getFieldProps('workGroup')}
                    />
                    <TextLineEl
                        title="Подрядчик"
                        errorTitle={formik.errors.contractorCompany}
                        touched={formik.touched.contractorCompany}
                        {...formik.getFieldProps('contractorCompany')}
                    />
                    <WideTextLineEl
                        title="Наименование работы"
                        errorTitle={formik.errors.workName}
                        touched={formik.touched.workName}
                        {...formik.getFieldProps('workName')}
                        required
                    />
                    <TextLineEl
                        title="Единица измерения"
                        errorTitle={formik.errors.unit}
                        touched={formik.touched.unit}
                        {...formik.getFieldProps('unit')}
                    />
                    <TextLineEl
                        title="Общий объем"
                        errorTitle={formik.errors.volumeTotal}
                        touched={formik.touched.volumeTotal}
                        {...formik.getFieldProps('volumeTotal')}
                        {...numberProps}
                    />
                    <DateLine
                        errorTitle={formik.errors.startDate}
                        touched={formik.touched.startDate}
                        title="Дата начала"
                        onChange={(newValue) => {
                            formik.setFieldValue('startDate', newValue)
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                startDate: true,
                            })
                        }
                        value={formik.values.startDate}
                    />
                    <DateLine
                        errorTitle={formik.errors.endDate}
                        touched={formik.touched.endDate}
                        title="Дата окончания"
                        onChange={(newValue) => {
                            formik.setFieldValue('endDate', newValue)
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                endDate: true,
                            })
                        }
                        value={formik.values.endDate}
                    />
                    <TextLineEl
                        title="Трудозатраты на ед. изм., чел-час"
                        errorTitle={formik.errors.humanHourCost}
                        touched={formik.touched.humanHourCost}
                        {...formik.getFieldProps('humanHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title="Затраты механизмов на ед.изм., маш-час"
                        errorTitle={formik.errors.machineHourCost}
                        touched={formik.touched.machineHourCost}
                        {...formik.getFieldProps('machineHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title="Стоимость ЕР (Дог.+ДР), руб."
                        errorTitle={formik.errors.costER}
                        touched={formik.touched.costER}
                        {...formik.getFieldProps('costER')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title="Закрыто по КС2, рублей"
                        errorTitle={formik.errors.costRemaining}
                        touched={formik.touched.costRemaining}
                        {...formik.getFieldProps('costRemaining')}
                        {...numberProps}
                    />
                    <button
                        type="submit"
                        style={{
                            display: 'none',
                        }}
                    ></button>
                </form>
            </div>
            <div className={s.buttons}>
                <ButtonsAndWarningEl
                    onClose={() => {
                        setIsOpenAddLevel(false)
                    }}
                    onSubmit={formik.handleSubmit}
                    submitText="сохранить"
                    cancelText="отменить"
                    disabled={isDisabled()}
                />
            </div>
        </>
    )
}
