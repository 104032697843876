import { Stack } from '@mui/material'
import Radio from '@mui/material/Radio'
import { OverflowableTypographyWithTooltip } from '../../../OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { IItemProps } from './SyncList.def'
import { StyledButton, StyledFormControlLabel } from './SyncList.styles'

export const Item: React.FC<IItemProps> = ({ project, hasMatchingProjectId, onUnbind }) => {
    return (
        <StyledFormControlLabel
            value={JSON.stringify({
                projectId: project.id,
                projectName: project.name,
            })}
            control={<Radio />}
            label={
                <Stack width="100%" direction="row" alignItems="center" gap={1} justifyContent="space-between">
                    <OverflowableTypographyWithTooltip
                        TypographyProps={{
                            color: '#2b3648',
                            maxWidth: hasMatchingProjectId ? 216 : 301,
                            variant: 'body1',
                            align: 'left',
                        }}
                        TooltipProps={{
                            placement: 'top',
                            componentsProps: {
                                tooltip: {
                                    sx: {
                                        maxWidth: 500,
                                    },
                                },
                            },
                        }}
                        maxRows={4}
                    >
                        {project.name}
                    </OverflowableTypographyWithTooltip>
                    {hasMatchingProjectId ? (
                        <StyledButton size="small" onClick={onUnbind}>
                            Отвязать
                        </StyledButton>
                    ) : null}
                </Stack>
            }
        />
    )
}
