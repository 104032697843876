import React, { Dispatch, SetStateAction, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import s from './BigEnterPlan.module.scss'
import { Button, FormControl, IconButton, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material'
import { ru } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { sxButtonBottom, sxButtonTop, sxDate, sxIconButtonTop, sxInput, textLabelAndDesc } from './BigEnterPlan.service'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { monthFormFillI } from '../../pages/KsgTable/Ksg.def'
import { useUpdateChartsCPGMutation } from '../../api/ksg/ksg.api'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { monthlyChart, Work } from '../../api/ksg/ksg.def'
import { HtmlTooltip } from '../../shared/components/HtmlTooltip'
import _ from 'lodash'
import SmallGlassButtonsInputEl from './components/SmallGalssButtonsInputEl'

export default function BigEnterPlan({
    setAnchorElTemplateTable,
    setAnchorElPosition,
    onEdit,
    setIndexClick,
    changeableArray,
    totalPlan,
    id,
    year,
    month,
    unit,
    monthlyCharts,
    setMutationsLoading,
}: {
    setAnchorElTemplateTable?: Dispatch<SetStateAction<HTMLElement | null>>
    setAnchorElPosition?: Dispatch<SetStateAction<{ x: number; y: number } | null>>
    onEdit?: (works: Work[]) => void
    setIndexClick: Dispatch<SetStateAction<number | null>>
    changeableArray: { month: number; year: number; fill: boolean; fact: number | null }[] | undefined
    totalPlan: number
    id: number
    year: number
    month: number
    unit: string | null
    monthlyCharts: monthlyChart[]
    setMutationsLoading: Dispatch<SetStateAction<boolean>>
}) {
    const [dateFirst, setDateFirst] = useState<Date | null>(new Date())
    const [dateSecond, setDateSecond] = useState<Date | null>(new Date())
    const [inputValue, setInputValue] = useState('100')
    const [inputType, setInputType] = useState<'unit' | 'percent'>('percent')
    const [pickVolTime, setPickVolTime] = useState<'vol' | 'time'>('vol')

    const [monthsUpdateReq] = useUpdateChartsCPGMutation()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const { projectId } = useParams()

    // данные для отправки на сервер
    const chartsToSend = useMemo(() => {
        const arrangedArray = () => {
            let count = 0
            let arr: {
                id: number
                data: monthFormFillI[]
            }[] = []
            changeableArray?.forEach((value) => {
                if (value.fill) {
                    const exist = arr.find((item) => item.id === count)
                    if (exist) {
                        exist.data.push({
                            fact: value.fact ? String(value.fact) : '',
                            month: value.month,
                            plan: value.fact ? String(value.fact) : '',
                            year: value.year,
                        })
                    } else {
                        arr.push({
                            id: count,
                            data: [
                                {
                                    fact: value.fact ? String(value.fact) : '',
                                    month: value.month,
                                    plan: value.fact ? String(value.fact) : '',
                                    year: value.year,
                                },
                            ],
                        })
                    }
                } else {
                    count += 1
                }
            })
            return arr
        }

        const arrToSend = arrangedArray()?.filter((object) => {
            return object.data?.find((item) => item.month === month && item.year === year)
        })[0]?.data

        setDateFirst(new Date(arrToSend[0]?.year, arrToSend[0]?.month - 1))
        const sDate = new Date(arrToSend[arrToSend.length - 1]?.year, arrToSend[arrToSend.length - 1]?.month)
        setDateSecond(
            new Date(arrToSend[arrToSend.length - 1]?.year, arrToSend[arrToSend.length - 1]?.month, sDate.getDate() - 1)
        )

        return arrToSend.map((v) => ({ ...v, plan: 1 }))
    }, [changeableArray, month, year])

    // индекс первого месяца колбаски из массива monthlyCharts
    const startIndex = monthlyCharts?.findIndex(
        (item) => item.month === chartsToSend?.[0].month && item.year === chartsToSend?.[0].year
    )

    // сумма остатка на месяц
    const planTotalSum = useMemo(() => {
        let sumOtherFact = 0
        const filter = monthlyCharts.filter((_) => {
            const item = chartsToSend.find((f) => f.month === _.month && f.year === _.year)
            if (item) {
                if (!_.fact) {
                    sumOtherFact -= _.plan || 0
                } else {
                    sumOtherFact -= Math.abs(_.fact - (_.plan || 0)) || 0
                }
                return true
            } else {
                sumOtherFact += _.fact ? _.fact : _.plan || 0
                return false
            }
        })
        return _.round(_.sumBy(filter, 'plan'), 6) + _.round(sumOtherFact, 6)
        //eslint-disable-next-line
    }, [chartsToSend])

    // общая сумма остатка с вычетом из общего объема
    const topPlanNumber = _.round(totalPlan - planTotalSum, 6)

    // если остаток превышает общий объем, блокируется инпут
    const disabledInput = planTotalSum >= totalPlan

    // определяется текущий месяц
    const qtyRemainingMonths = useCallback(() => {
        return monthlyCharts.length - startIndex
    }, [monthlyCharts.length, startIndex])

    // данные для отправки на сервер если выбрано по объему
    const chartsToSendVolume = useMemo(() => {
        let plan = topPlanNumber
        // monthlyCharts.forEach((item ) => {
        //     if(!item.fact) return
        //     plan -= item.fact
        // })

        const value = inputType === 'percent' ? (plan * Number(inputValue)) / 100 : Number(inputValue)

        //@ts-ignore
        const nullsArray: monthFormFillI[] = monthlyCharts.map((v) => {
            const newItem = chartsToSend?.find((f) => f.month === v.month && f.year === v.year)
            if (newItem) {
                return {
                    fact: String(v.fact),
                    month: v.month,
                    plan: v.fact ? v.fact : v.plan,
                    year: v.year,
                }
            }
            return {
                fact: String(v.fact),
                month: v.month,
                plan: v.fact ? v.fact : v.plan,
                year: v.year,
            }
        })

        if (value > topPlanNumber || value < _.round(topPlanNumber / qtyRemainingMonths(), 6)) {
            return
        }
        const skipMonths = chartsToSend?.filter((_) => _.fact).length || 0
        const monthsCount = Number((topPlanNumber / value || 1).toFixed()) + skipMonths

        let array: monthFormFillI[] = []

        for (let i = startIndex; i <= monthsCount + startIndex - 1; i++) {
            if (plan > 0) {
                // @ts-ignore
                array.push({
                    fact: String(monthlyCharts[i]?.fact),
                    month: monthlyCharts[i]?.month,
                    plan:
                        monthlyCharts && monthlyCharts[i]?.fact
                            ? monthlyCharts[i]?.fact!.toString()
                            : String(plan < value ? plan : value),
                    year: monthlyCharts[i]?.year,
                })
            }
            if (!monthlyCharts[i]?.fact) {
                plan -= monthlyCharts && monthlyCharts[i]?.fact ? monthlyCharts[i]?.fact! : value
            }
        }

        nullsArray.splice(startIndex, array.length, ...array)
        return nullsArray
        //eslint-disable-next-line
    }, [inputType, inputValue, monthlyCharts, qtyRemainingMonths, startIndex, totalPlan, topPlanNumber])

    // всплывашка для инпута, появляется в случае ошибки валидации
    const getTooltipTitle = useCallback(() => {
        if (inputType === 'percent') {
            if (Number(inputValue) > 100) {
                return 'Значение не может быть больше 100%'
            }
            if (
                inputValue &&
                pickVolTime === 'vol' &&
                Number(inputValue) < (topPlanNumber / qtyRemainingMonths() / (totalPlan || 1)) * 100
            ) {
                return `Значение не может быть меньше ${_.ceil(
                    (topPlanNumber / qtyRemainingMonths() / totalPlan) * 100,
                    6
                )}%`
            }
        } else {
            if (pickVolTime === 'vol' && Number(inputValue) > totalPlan) {
                return `Значение не может быть больше ${_.round(totalPlan, 6)} ${unit}`
            }
            if (pickVolTime === 'time' && Number(inputValue) > topPlanNumber) {
                return `Значение не может быть больше ${_.round(topPlanNumber, 6)} ${unit}`
            }

            if (
                inputValue &&
                Number(inputValue) < _.round(topPlanNumber / qtyRemainingMonths(), 6) &&
                pickVolTime === 'vol'
            ) {
                return `Значение не может быть меньше ${_.round(topPlanNumber / qtyRemainingMonths(), 6)}`
            }
        }
        //eslint-disable-next-line
    }, [disabledInput, inputType, inputValue, pickVolTime, qtyRemainingMonths, topPlanNumber, totalPlan, unit])

    // функция удаления этапа
    const onFormMonthsSubmit = useCallback(
        (e: SyntheticEvent, del?: boolean) => {
            e.preventDefault()
            setIndexClick(null)
            setAnchorElTemplateTable && setAnchorElTemplateTable(null)
            setAnchorElPosition && setAnchorElPosition(null)
            if (!chartsToSend || getTooltipTitle()?.length) return
            if (chartsToSend.some((v) => v.plan && Number(v.plan) < 0)) {
                enqueueSnackbar('План превышает общий объем, невозможно распределить', {
                    variant: 'error',
                })
                return
            }

            setMutationsLoading(true)
            monthsUpdateReq({
                id: Number(projectId),
                body: {
                    toUpdate: [
                        {
                            charts: chartsToSend.map((chart) => {
                                return {
                                    fact: chart.fact ? Number(chart.fact) : null,
                                    month: chart.month,
                                    plan: del ? null : chart.plan ? Number(chart.plan) : null,
                                    year: chart.year,
                                }
                            }),
                            workID: id,
                        },
                    ],
                },
            })
                .unwrap()
                .then((res) => {
                    setMutationsLoading(false)
                    if (onEdit) {
                        onEdit(res.data)
                    }
                    enqueueSnackbar(t('saved'), {
                        variant: 'success',
                    })
                })
                .catch((e) => {
                    setMutationsLoading(false)
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    })
                    console.error(e)
                })
        },
        //eslint-disable-next-line
        [
            chartsToSend,
            enqueueSnackbar,
            getTooltipTitle,
            id,
            monthsUpdateReq,
            onEdit,
            projectId,
            setAnchorElTemplateTable,
            setIndexClick,
            setMutationsLoading,
            t,
        ]
    )

    // данные для отправки на сервер, если выбрано по периодам
    const chartsToSendFull = useMemo(() => {
        const devCount =
            monthlyCharts
                .map((v) => {
                    const copy = { ...v }
                    const newItem = chartsToSend?.find((f) => f.month === v.month && f.year === v.year)
                    if (newItem?.plan) copy.plan = 1
                    return copy
                })
                .filter((v) => v.plan).length - monthlyCharts.filter((v) => v.fact).length
        // const planValue = _.round((totalPlan - _.round(_.sumBy(monthlyCharts, 'fact'), 6)) / devCount, 6)
        const planValue = _.round(topPlanNumber / chartsToSend.length, 6)
        const percentValueN = _.round(planValue * (Number(inputValue) / 100), 6)
        // const absValueN = Number(inputValue) / monthlyCharts.filter((v) => v.plan).length
        const absValueN = Number(inputValue) / chartsToSend.length

        let other = 0

        let indexChart = null

        const firstIndex = monthlyCharts.findIndex(
            (_) => _.month === chartsToSend[0].month && _.year === chartsToSend[0].year
        )
        const lastIndex = monthlyCharts.findIndex(
            (_) =>
                _.month === chartsToSend[chartsToSend.length - 1].month &&
                _.year === chartsToSend[chartsToSend.length - 1].year
        )

        const returnValue = monthlyCharts.map((month, index) => {
            const copy = { ...month }
            const newItem = chartsToSend?.find((f) => f.month === month.month && f.year === month.year)

            if (index === firstIndex - 1 || index === lastIndex + 1) {
                copy.plan = null
            }

            let plan = inputType === 'unit' ? absValueN : percentValueN
            if (newItem) {
                if (copy.fact) {
                    copy.plan = Number(copy.fact)
                    other += plan
                } else {
                    copy.plan = plan
                    indexChart = index
                }
            } else {
                copy.plan = copy.fact ? Number(copy.fact) : copy.plan ? Number(copy.plan) : null
            }
            return copy
        })
        if (indexChart) {
            returnValue[indexChart].plan =
                other > 0 && returnValue[indexChart].plan
                    ? returnValue[indexChart].plan! + other
                    : returnValue[indexChart].plan
        }

        return returnValue
        //eslint-disable-next-line
    }, [inputValue, pickVolTime, inputType, topPlanNumber])

    // функция отправки данных, если выбрано по периодам
    const onFormMonthsSubmitFull = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            setIndexClick(null)
            setAnchorElTemplateTable && setAnchorElTemplateTable(null)
            setAnchorElPosition && setAnchorElPosition(null)

            if (!chartsToSend || getTooltipTitle()?.length) return

            setMutationsLoading(true)
            monthsUpdateReq({
                id: Number(projectId),
                body: {
                    toUpdate: [
                        {
                            charts: chartsToSendFull,
                            workID: id,
                        },
                    ],
                },
            })
                .unwrap()
                .then((res) => {
                    setMutationsLoading(false)
                    if (onEdit) {
                        onEdit(res.data)
                    }
                    enqueueSnackbar(t('saved'), {
                        variant: 'success',
                    })
                })
                .catch((e) => {
                    setMutationsLoading(false)
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    })
                    console.error(e)
                })
        },
        [
            chartsToSend,
            chartsToSendFull,
            enqueueSnackbar,
            getTooltipTitle,
            id,
            monthsUpdateReq,
            onEdit,
            projectId,
            setAnchorElTemplateTable,
            setIndexClick,
            setMutationsLoading,
            t,
        ]
    )

    // функция отправки данных, если выбрано по объему
    const onFormMonthsSubmitVol = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            setIndexClick(null)
            setAnchorElTemplateTable && setAnchorElTemplateTable(null)
            setAnchorElPosition && setAnchorElPosition(null)
            if (!chartsToSendVolume || getTooltipTitle()?.length) return
            monthsUpdateReq({
                id: Number(projectId),
                body: {
                    toUpdate: [
                        {
                            charts: chartsToSendVolume.map((chart) => {
                                return {
                                    fact: chart.fact ? Number(chart.fact) : null,
                                    month: chart.month,
                                    plan: chart.plan ? Number(chart.plan) : null,
                                    year: chart.year,
                                }
                            }),
                            workID: id,
                        },
                    ],
                },
            })
                .unwrap()
                .then((res) => {
                    if (onEdit) {
                        onEdit(res.data)
                    }
                    enqueueSnackbar(t('saved'), {
                        variant: 'success',
                    })
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    })
                    console.error(e)
                })
        },
        [
            chartsToSendVolume,
            enqueueSnackbar,
            getTooltipTitle,
            id,
            monthsUpdateReq,
            onEdit,
            projectId,
            setAnchorElTemplateTable,
            setIndexClick,
            t,
        ]
    )

    // итоговая сумма остатка для заголовка окна
    const topSumTotal = () => {
        if (topPlanNumber > 0) {
            return `${textLabelAndDesc(pickVolTime).volTitle} ${String(topPlanNumber).replace('.', ',')} ${unit}`
        } else {
            return 'Весь объем распределен'
        }
    }

    return (
        <section className={s.main}>
            <div className={s.top}>
                <p>{topSumTotal()}.</p>
                <Button
                    onClick={(e) => {
                        if (pickVolTime === 'time') {
                            onFormMonthsSubmitFull(e)
                        } else {
                            onFormMonthsSubmitVol(e)
                        }
                    }}
                    sx={sxButtonTop}
                    variant="contained"
                >
                    готово
                </Button>
                <IconButton
                    sx={sxIconButtonTop}
                    color="primary"
                    component="span"
                    onClick={() => {
                        setAnchorElTemplateTable && setAnchorElTemplateTable(null)
                        setAnchorElPosition && setAnchorElPosition(null)
                    }}
                >
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
            <div className={s.button_tabs}>
                <button onClick={() => setPickVolTime('vol')} className={pickVolTime === 'vol' ? s.act : s.not_act}>
                    По объему
                </button>
                <button onClick={() => setPickVolTime('time')} className={pickVolTime === 'time' ? s.act : s.not_act}>
                    По периодам
                </button>
            </div>
            <HtmlTooltip title={(getTooltipTitle() || '').replace('.', ',')}>
                <form onSubmit={pickVolTime === 'time' ? onFormMonthsSubmitFull : onFormMonthsSubmitVol}>
                    <FormControl fullWidth sx={sxInput} variant="outlined">
                        <InputLabel error={disabledInput || !!getTooltipTitle()?.length} htmlFor="enter_plan_input">
                            {textLabelAndDesc(pickVolTime).label}
                        </InputLabel>
                        <OutlinedInput
                            id="enter_plan_input"
                            onChange={(e) => setInputValue(e.target.value)}
                            value={inputValue}
                            type="number"
                            inputProps={{
                                step: '0.000001',
                            }}
                            error={!!getTooltipTitle()?.length}
                            endAdornment={
                                <SmallGlassButtonsInputEl inputType={inputType} setInputType={setInputType} />
                            }
                            disabled={disabledInput}
                            label={textLabelAndDesc(pickVolTime).label}
                        />
                    </FormControl>
                    {disabledInput && <div className={s.error}>Весь объем уже распределен</div>}
                </form>
            </HtmlTooltip>
            <div className={s.dates_line}>
                <Stack sx={sxDate}>
                    <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={'Дата начала'}
                            value={dateFirst}
                            disabled
                            onChange={(newValue) => {
                                setDateFirst(newValue)
                            }}
                            inputFormat="dd.MM.yyyy"
                            renderInput={(params) => {
                                return <TextField {...params} />
                            }}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack sx={sxDate}>
                    <LocalizationProvider sx={sxDate} adapterLocale={ru} dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={'Дата окончания'}
                            value={dateSecond}
                            disabled
                            onChange={(newValue) => {
                                setDateSecond(newValue)
                            }}
                            inputFormat="dd.MM.yyyy"
                            renderInput={(params) => {
                                return <TextField {...params} />
                            }}
                        />
                    </LocalizationProvider>
                </Stack>
            </div>
            <div className={s.info}>{textLabelAndDesc(pickVolTime).desc}</div>
            <Button onClick={(e) => onFormMonthsSubmit(e, true)} sx={sxButtonBottom} variant="contained" fullWidth>
                Удалить этап
            </Button>
        </section>
    )
}
