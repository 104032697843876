import { Box } from "@mui/material";
import styled from "styled-components";

export const WorkManagmentSubPageWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 12px;
  padding-right: 0;
  height: calc(100vh - 140px);
  overflow-y: auto;
`