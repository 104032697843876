import * as Yup from 'yup'
import s from './AddLevelKsg.module.scss'
import { workStatuses } from '../../api/works/works.def'

export const validationSchemaAg = Yup.object({
    objTitle: Yup.string().required('Введите титул').max(150, 'Не больше 150 символов'),
    objName: Yup.string().required('Введите название объекта').max(150, 'Не больше 150 символов'),
    workName: Yup.string().required('Введите раздел РД').max(150, 'Не больше 150 символов'),
    rdGroup: Yup.string().max(150, 'Не больше 150 символов'),
    rdCode: Yup.string().max(150, 'Не больше 150 символов'),
    startDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    endDate: Yup.date()
        .nullable()
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('startDate'), 'Не раньше начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDateApproval: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
})

export const initialFormValuesAg = {
    objTitle: '',
    objName: '',
    rdGroup: '',
    rdCode: '',
    rdStatus: '',
    rdDateApproval: null,
    rdVersion: '',
    rdDate: null,
    workGroup: '',
    contractorCompany: '',
    workName: '',
    unit: '',
    volumeTotal: '',
    startDate: null,
    endDate: null,
    humanHourCost: '',
    machineHourCost: '',
    costER: '',
    costRemaining: '',
}
export interface initialFormValuesAgI {
    costER: string
    costRemaining: string
    humanHourCost: string
    machineHourCost: string
    unit: string
    volumeTotal: string
    workName: string
    contractorCompany: string
    workGroup: string
    objName: string
    objTitle: string
    rdCode: string
    rdStatus: workStatuses | string
    rdGroup: string
    rdVersion: string
    rdDateApproval: Date | null
    rdDate: Date | null
    startDate: Date | null
    endDate: Date | null
}

export const inputStylesAddWorkAg = {
    width: '11rem',
    backgroundColor: '#F6F7FB',
    '.MuiOutlinedInput-input': {
        padding: '0.35rem',
    },
    '.MuiInputBase-root': {
        border: '1px solid rgba(92, 110, 140, 0.1)',
        borderRadius: '4px',
        fontSize: 12,
    },
}

export const calendarStylesAddWorkAg = {
    backgroundColor: '#F6F7FB',
    border: '1px solid rgba(92, 110, 140, 0.1)',
    borderRadius: '4px',
    width: '11rem',
    '.MuiSvgIcon-root': {
        fontSize: '1.16rem',
    },
    '.MuiInputBase-input': {
        padding: '0.2rem .4rem',

        fontSize: 14,
    },
}

export function FormErrorAg({ isTouched, error }: { isTouched?: boolean; error?: string }) {
    return <div className={s.error}>{error && isTouched && <div>{error}</div>}</div>
}
