import g from '../../../shared/stylesheets/TableGrid.module.scss'
import { stylesLevel } from '../../KsgTable/Ksg.service'
import React from 'react'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Updater } from 'use-immer'
import { useTypedSelector } from '../../../store/store'
import { agGridListMode } from '../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { useSearchParams } from 'react-router-dom'

export default function LevelRendererAgGridMsg({
    hiddenRowsIds,
    setHiddenRowsIds,
    hasChildren,
    id,
    code,
    level,
}: {
    hiddenRowsIds: { id: number; code: string }[]
    setHiddenRowsIds: Updater<{ id: number; code: string }[]>
    hasChildren: boolean
    id: number
    code: string
    level: number | null
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const listMode = useTypedSelector(agGridListMode)
    const showArrows = () => {
        if (hasChildren) {
            if (!hiddenRowsIds?.find((item) => item.id === id))
                return (
                    <IconButton color="inherit" component="button">
                        <KeyboardArrowDownIcon />
                    </IconButton>
                )
            return (
                <IconButton color="inherit" component="button">
                    <KeyboardArrowUpIcon />
                </IconButton>
            )
        }
        return null
    }

    return (
        <div
            onClick={() => {
                // if (setHiddenRowsIds && !searchParams.get('gant')) {
                    setHiddenRowsIds((d) => {
                        const addIndex = d.findIndex((hrId) => hrId.id === id)
                        if (addIndex >= 0) {
                            d.splice(addIndex, 1)
                        } else {
                            d.push({ id: id, code: code })
                        }
                    })
                // }
            }}
        >
            <div>
                {!listMode && <div className={g.arrow_level}>{showArrows()}</div>}
                <h5 className={stylesLevel(level)}>УР {level}</h5>
            </div>
        </div>
    )
}
