import { ICellRendererParams } from 'ag-grid-community'
import { theme } from '../../styles/theme'
import { TooltipProps, TypographyProps } from '@mui/material'
import { format } from 'date-fns'
import { IOnSubmitChartDataArgs } from './EditChartCell.def'
import { updateRowDataMSG } from '../../pages/AgGridMsg/AgGridMsg.service'

export const MONTH_NAME_TO_MONTH_YEAR_ORDER = {
    Январь: 1,
    Февраль: 2,
    Март: 3,
    Апрель: 4,
    Май: 5,
    Июнь: 6,
    Июль: 7,
    Август: 8,
    Сентябрь: 9,
    Октябрь: 10,
    Ноябрь: 11,
    Декабрь: 12,
}

export function parseStringToNumber(string: string) {
    // console.log(string)
    return typeof string === 'string' ? Number(string?.replaceAll(' ', '')?.replaceAll(',', '.')) || 0 : string
}

export function getFactTypographyProps(
    value: string | number | null | undefined,
    cellRendererParams: ICellRendererParams,
    chartIndex: number,
    chartType: 'dailyCharts' | 'monthlyCharts'
): TypographyProps {
    switch (true) {
        case value && (value as number) >= (cellRendererParams.data[chartType][chartIndex]?.plan || 0):
            return { color: theme.palette.success.main }
        case value && (value as number) < (cellRendererParams.data[chartType][chartIndex]?.plan || 0):
            return { color: theme.palette.warning.main }
        case !cellRendererParams.data[chartType][chartIndex]?.plan:
            return { color: theme.palette.text.dark }

        default:
            return {}
    }
}

function getSuccessMessage(args: IOnSubmitChartDataArgs) {
    const { cellRendererParams, translate, chartValueKey, defaultValue, formData } = args
    const valueName = translate(chartValueKey)
    let chartName = ''
    let detailInfo = `успешно изменен c ${(defaultValue as number)?.toString().replaceAll('.', ',') || 0} на
    ${formData[chartValueKey]}`
    if (cellRendererParams?.data?.dailyCharts) {
        chartName = format(
            new Date(
                cellRendererParams.data.year,
                cellRendererParams.data.month - 1,
                cellRendererParams.colDef?.headerName as unknown as number
            ),
            'dd.MM.yyyy'
        )
    } else {
        chartName = `месяца ${cellRendererParams.colDef?.headerName} `
    }
    if (formData[chartValueKey] == null) {
        detailInfo = 'успешно удален'
    }
    return `${valueName} ${chartName} ${detailInfo}`
}

export function onSubmitSuccess(args: IOnSubmitChartDataArgs) {
    const { enqueueSnackbar, setIsFetching, setIsEditMode } = args
    enqueueSnackbar(getSuccessMessage(args), { variant: 'success' })
    setIsFetching(false)
    setIsEditMode(false)
}

export function onSubmitRejected(args: IOnSubmitChartDataArgs) {
    const { enqueueSnackbar, setIsFetching, setIsEditMode, chartValueKey, defaultValue, methods } = args
    methods.reset({
        [chartValueKey]: defaultValue,
    })
    enqueueSnackbar(`Обратитесь к администратору`, { variant: 'error', action: null })
    setIsFetching(false)
    setIsEditMode(false)
}

export function getRemainedVolumeTooltipProps(saldo: number): Partial<TooltipProps> {
    if (saldo < 0) {
        return {
            title: `Остаток объема для распределения: ${Math.abs(saldo).toFixed(6)}`,
        }
    }
    return {}
}

export function isLessThanVolumeTotal(value: any, saldo: number, fieldName: string) {
    console.log(value, saldo)
    if (!value || value === 0) {
        return true
    }
    return saldo <= 0 || `${fieldName} превышает общий объем на ${saldo.toFixed(6).replaceAll('.', ',')}`
}
