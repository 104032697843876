import { format } from 'date-fns'
import React from 'react'
import ReactDOM from 'react-dom'
import { useGetProjectDates } from '../DHTGantOnlyTable.model'
import { GanttStatic, ZoomLevels } from '../dhtmlxgantt'

type TSetGanttConfigOptions = {
    projectDates: ReturnType<typeof useGetProjectDates>
    highlightCriticalPath?: boolean
}

export function setGanttConfig(
    gantt: GanttStatic,
    { projectDates, highlightCriticalPath = false }: TSetGanttConfigOptions
) {
    gantt.plugins({
        undo: true,
        redo: true,
        tooltip: true,
        auto_scheduling: false,
        critical_path: true,
    })

    gantt.templates.tooltip_text = function (start, end, task) {
        return (
            '<b>ID:</b> ' +
            task.id +
            '<br/>' +
            '<b>Задача:</b> ' +
            task.text +
            '<br/>' +
            '<b>Дата начала:</b> ' +
            (task.start_date ? format(task.start_date, 'dd.MM.yyyy') : 'Не установлена') +
            '<br/><b>Дата окончания:</b> ' +
            (task.end_date ? format(task.end_date, 'dd.MM.yyyy') : 'Не установлена')
        )
    }

    gantt.config.drag_progress = false
    gantt.config.scale_height = 81
    gantt.config.undo_types = {
        link: 'link',
        task: 'task',
    }
    gantt.config.scales = [
        { unit: 'month', format: '%F, %Y' },
        {
            unit: 'day',
            step: 1,
        },
    ]
    gantt.config.open_tree_initially = true
    gantt.config.duration_unit = 'day'
    gantt.config.bar_height = 12
    gantt.config.auto_scheduling = true
    gantt.config.auto_scheduling_strict = true
    gantt.config.auto_scheduling_initial = true
    gantt.config.auto_scheduling_project_constraint = true
    gantt.config.undo = true
    gantt.config.show_grid = false
    gantt.config.row_height = 80
    // gantt.config.layout = {
    //     css: "gantt_container",
    //     rows: [
    //       {
    //         cols: [
    //           { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },
    //           { view: "timeline", scrollY: "scrollVer", scrollX: "scrollHor" },
    //           { view: "scrollbar", id: "scrollVer", group: "vertical" }
    //         ],
    //         gravity: 1
    //       },
    //       { view: "scrollbar", id: "scrollHor", group: "horizontal" }
    //     ]
    //   };
    // gantt.config.layout = {
    //     css: "gantt_container",
    //     rows: [
    //       {
    //         cols: [
    //           {view: "grid", scrollX: "gridScroll", scrollY: "scrollVer"},
    //           { resizer: true, width: 10 },
    //           {view: "timeline", scrollY: "scrollVer", scrollX: "scrollHor"},

    //         ],
    //         gravity: 1
    //       },
    //       {view: "scrollbar", id: "scrollHor", group:"horizontal"}
    //     ]
    //   };
    // gantt.config.layout = {
    //     cols: [
    //         {
    //             // width: 400,
    //             // maxWidth: 670,
    //             // minWidth: 560,
    //             // rows: [
    //             //     { view: 'grid', scrollX: 'gridScroll', scrollable: true, scrollY: 'scrollVer' },
    //             //     { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
    //             // ],
    //         },
    //         // { resizer: true, width: 1 },
    //         {
    //             rows: [
    //                 { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
    //                 { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
    //             ],
    //         },
    //         { view: 'scrollbar', id: 'scrollVer', group: 'vertical', scroll: 'y' },
    //     ],
    // }
    // gantt.config.layout = {
    //     cols: [
    //         // {
    //         //     maxWidth: 800,
    //         //     minWidth: 400,
    //         //     // width: 560,
    //         //     rows: [
    //         //         { view: 'grid', scrollX: 'gridScroll', scrollY: 'scrollVer' },
    //         //         { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' },
    //         //     ],
    //         // },
    //         // { resizer: true, width: 1 },
    //         {
    //             rows: [
    //                 { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
    //                 // { view: 'scrollbar', id: 'scrollHor', group: 'horizontal', scrollable: true },
    //             ],
    //         },
    //         { view: 'scrollbar', id: 'scrollVer', group: 'vertical' },
    //     ],
    // }
    gantt.config.min_duration = 24 * 60 * 60 * 1000
    gantt.config.round_dnd_dates = false
    gantt.config.time_step = 24 * 60
    gantt.config.highlight_critical_path = highlightCriticalPath

    let zoomConfig = {
        columnWidth: 80,
        levels: [
            [
                {
                    unit: 'year',
                    format: '%Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
            ],
            [
                {
                    unit: 'year',
                    format: '%Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
                {
                    unit: 'month',
                    format: '%M',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class--small',
                },
            ],
            [
                {
                    unit: 'month',
                    format: '%M %Y',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class',
                },
                {
                    unit: 'day',
                    format: '%d %M',
                    step: 1,
                    css: () => 'gantt_custom_scale_cell_class--small',
                },
            ],
        ] as unknown as ZoomLevels[],
        startDate: projectDates?.start,
        endDate: projectDates?.end,
        useKey: 'ctrlKey',
        trigger: 'wheel',
        activeLevelIndex: 1,
        element: function () {
            return gantt.$root.querySelector('.gantt_task')
        },
        // handler: function () {
        //     console.log('flkdsjflkdsjflks')
        // },
    }

    gantt.ext.zoom.init(zoomConfig)

    // gantt.templates.grid_header_class = function (columnName, column) {
    //     if (!!column.gridColumnName) {
    //         return column.gridColumnName
    //     }
    //     return ''
    // }

    gantt.templates.scale_row_class = function () {
        return 'gantt_custom_scale_row_class'
    }

    gantt.templates.scale_cell_class = function (date) {
        return 'gantt_custom_scale_cell_class'
    }

    gantt.templates.task_class = function (start, end, task) {
        if (task.level === 0) {
            return 'level_zero_bar parent_bar'
        }
        if (task.hasChildren) {
            return 'parent_bar'
        }
        if (!task.hasChildren) {
            return 'child_bar'
        }
        return 'asd'
    }

    gantt.config.external_render = {
        // checks the element is a React element
        isElement: (element: {} | null | undefined) => {
            return React.isValidElement(element)
        },
        // renders the React element into the DOM
        renderElement: (
            element: React.DOMElement<React.DOMAttributes<Element>, Element>,
            container: ReactDOM.Container | null
        ) => {
            ReactDOM.render(element, container)
        },
    }

    gantt.i18n.setLocale('ru')

    // gantt.locale.labels.section_link = 'Links'
    // gantt.locale.labels.section_predecessor = 'Predecessors'

    // var link = gantt.serverList('links', [
    //     { key: 0, label: 'Finish to start' },
    //     { key: 1, label: 'Start to start' },
    //     { key: 2, label: 'Finish to finish' },
    //     { key: 3, label: 'Start to finish' },
    // ])

    // gantt.config.lightbox.sections = [
    //     { name: 'description', height: 38, map_to: 'text', type: 'textarea', focus: true },
    //     {
    //         name: 'link',
    //         label: 'Links',
    //         height: 22,
    //         type: 'select',
    //         map_to: 'link_type',
    //         options: link,
    //         focus: true,
    //         default_value: '',
    //     },
    //     {
    //         name: 'predecessor',
    //         label: 'predecessors',
    //         height: 22,
    //         type: 'select',
    //         map_to: 'predecessor_id',
    //         options: gantt.serverList('tasks'),
    //         focus: false,
    //         default_value: '',
    //     },
    //     { name: 'time', type: 'duration', map_to: 'auto' },
    // ]
}
