import React from 'react'
import { useToggleSearchParam } from '../../../../../hooks/useToggleSearchParam'
import { FlexRowWrapper } from '../../../../../pages/NewExecutorView/components/components.styles'
import { IntegrationWithOfferSearchParam } from '../../../../../shared/constants/integration-with-offer'
import { IControlPanelProps } from './ControlPanel.def'
import { SyncStatus } from './SyncStatus'

export const ControlPanel: React.FC<IControlPanelProps> = () => {
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam)

    return (
        <FlexRowWrapper gap={1}>
            {
                // isActive ? <BasePanel /> :
                <SyncStatus />
            }
        </FlexRowWrapper>
    )
}
