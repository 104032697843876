import { Box, Divider, Tab } from "@mui/material";
import { StatProgress } from "../../components/StatProgress/StatProgress";
import { CardWrapper, CardTitle, TabsContained } from "../../components/components.styles";
import { WorkManagmentSubPageWrapper } from "./StatsPage.styles";
import { theme } from "../../../../styles/theme";
import { StatItem } from "../../components/StatItem/StatItem";
import { PercentStatusValue } from "../../components/PercentStatusValue/PercentStatusValue";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import dynamicLinearDiagramm from '../../static/dynamicLinearDiagramm.svg'
import linksWithOtherJobsTable from '../../static/linksWithOtherJobsTable.svg'
import statsDynamicTable from '../../static/statsDynamicTable.svg'
import { useState } from "react";
import { Plug } from "../../components/Plug/Plug";

export function StatsPage() {

    const [isDynamicTotalStatsShown, setIsDynamicTotalStatsShown] = useState<boolean>(false)

    return (
        <WorkManagmentSubPageWrapper>
            <Plug>
                <CardWrapper
                    alignSelf={'start'}
                >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <CardTitle size="small">
                            Трудозатраты
                        </CardTitle>
                        <StatProgress
                            variant="column"
                            currentValue={{
                                value: 7847457,
                                TypographyProps: {
                                    color: 'primary',
                                    fontWeight: 500
                                }
                            }}
                            totalValue={{
                                value: 15874874,
                                TypographyProps: {
                                    color: theme.palette.secondary.gray,
                                    fontSize: 12
                                }
                            }}
                        />
                    </Box>

                    <StatItem
                        variant={"row"}
                        title={"Прогресс (ч./ч.)"}
                        customValueComponent={
                            <StatProgress
                                variant="row"
                                NumericFormatProps ={{
                                    decimalScale: 0,
                                    suffix: ''
                                }}
                                currentValue={{
                                    value: 5600,
                                    TypographyProps: {
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }}
                                totalValue={{
                                    value: 10600,
                                    TypographyProps: {
                                        color: theme.palette.secondary.gray,
                                        fontSize: 12
                                    }
                                }}
                            />
                        }
                    />
                    <StatItem
                        variant={"row"}
                        title={"Трудозатраты на ед. изм. (ч/ч)"}
                        customValueComponent={
                            <StatProgress
                                variant="row"
                                separator="/"
                                NumericFormatProps ={{
                                    decimalScale: 0,
                                    suffix: ''
                                }}
                                currentValue={{
                                    value: 38.01,
                                    TypographyProps: {
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }}
                                totalValue={{
                                    value: 45.76,
                                    TypographyProps: {
                                        color: theme.palette.secondary.gray,
                                        fontSize: 12
                                    }
                                }}
                            />
                        }
                    />
                    <StatItem
                        variant={"row"}
                        title={"Обеспеченность"}
                        customValueComponent={<PercentStatusValue value={83.33} variant="warning" />}
                    />
                </CardWrapper>
            </Plug>

            <Plug>
                <CardWrapper
                    alignSelf={'start'}
                >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <CardTitle size="small">
                            МиМ
                        </CardTitle>
                        <StatProgress
                            variant="column"
                            currentValue={{
                                value: 2587487,
                                TypographyProps: {
                                    color: 'primary',
                                    fontWeight: 500
                                }
                            }}
                            totalValue={{
                                value: 5877487,
                                TypographyProps: {
                                    color: theme.palette.secondary.gray,
                                    fontSize: 12
                                }
                            }}
                        />
                    </Box>

                    <StatItem
                        variant={"row"}
                        title={"Прогресс (м./ч.)"}
                        customValueComponent={
                            <StatProgress
                                variant="row"
                                NumericFormatProps ={{
                                    decimalScale: 0,
                                    suffix: ''
                                }}
                                currentValue={{
                                    value: 2200,
                                    TypographyProps: {
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }}
                                totalValue={{
                                    value: 5714,
                                    TypographyProps: {
                                        color: theme.palette.secondary.gray,
                                        fontSize: 12
                                    }
                                }}
                            />
                        }
                    />
                    <StatItem
                        variant={"row"}
                        title={"Затраты Механизмов на ед. изм. (м./ч)"}
                        customValueComponent={
                            <StatProgress
                                variant="row"
                                separator="/"
                                NumericFormatProps ={{
                                    decimalScale: 0,
                                    suffix: ''
                                }}
                                currentValue={{
                                    value: 23,
                                    TypographyProps: {
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }}
                                totalValue={{
                                    value: 23,
                                    TypographyProps: {
                                        color: theme.palette.secondary.gray,
                                        fontSize: 12
                                    }
                                }}
                            />
                        }
                    />
                    <StatItem
                        variant={"row"}
                        title={"Обеспеченность"}
                        customValueComponent={<PercentStatusValue value={100} variant="default" />}
                    />
                </CardWrapper>
            </Plug>

            <Plug>
                <CardWrapper
                    alignSelf={'start'}
                >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <CardTitle size="small">
                            Материалы
                        </CardTitle>
                        <StatProgress
                            variant="column"
                            currentValue={{
                                value: 25847,
                                TypographyProps: {
                                    color: 'primary',
                                    fontWeight: 500
                                }
                            }}
                            totalValue={{
                                value: 30900,
                                TypographyProps: {
                                    color: theme.palette.secondary.gray,
                                    fontSize: 12
                                }
                            }}
                        />
                    </Box>

                    <StatItem
                        variant={"row"}
                        title={"Дата начала поставки"}
                        value={"01.12.2023"}
                    />
                    <StatItem
                        variant={"row"}
                        title={"Дата начала поставки"}
                        value={"02.02.2023"}
                    />
                    <StatItem
                        variant={"row"}
                        title={"Обеспеченность"}
                        customValueComponent={<PercentStatusValue value={66} variant="error" />}
                    />
                </CardWrapper>
            </Plug>

            <Plug
                sx={{
                    gridColumn: '1/4'
                }}
            >
                <CardWrapper
                // sx={{
                //     gridColumn: '1/4'
                // }}
                >
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                        <CardTitle size="small">
                            Динамика: Объем работ
                        </CardTitle>
                        <CardTitle size="small" sx={{ opacity: 0.2 }}>
                            Демо данные
                        </CardTitle>
                        <TabsContained
                            value={0}
                        >
                            <Tab value={0} label="МЕСЯЦЫ" />
                            <Tab value={1} label="ГОДЫ" />
                        </TabsContained>
                    </Box>
                    <img src={dynamicLinearDiagramm} alt="График динамики показателей" />
                    <Divider sx={{ marginY: 2 }} />
                    <Box
                        onClick={(e) => setIsDynamicTotalStatsShown(prevState => !prevState)}
                        display={'flex'}
                        gap={2}
                        alignItems={'center'}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        <CardTitle size="small">
                            Показатели
                        </CardTitle>
                        {!isDynamicTotalStatsShown && <ExpandMore color="primary" fontSize={"medium"} />}
                        {isDynamicTotalStatsShown && <ExpandLess color="primary" fontSize={"medium"} />}
                    </Box>
                    {isDynamicTotalStatsShown &&
                        <img src={statsDynamicTable} alt="Динамика итоговых значений показателей" />
                    }
                </CardWrapper>
            </Plug>

            <Plug
                sx={{
                    gridColumn: '1/4'
                }}
            >
                <CardWrapper
                // sx={{
                //     gridColumn: '1/4'
                // }}
                >
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"}>
                        <CardTitle size="small">
                            Связи
                        </CardTitle>
                        <CardTitle size="small" sx={{ opacity: 0.2 }}>
                            Демо данные
                        </CardTitle>
                    </Box>
                    <img src={linksWithOtherJobsTable} alt="Связи работ из диаграммы Ганта" />
                </CardWrapper>
            </Plug>

        </WorkManagmentSubPageWrapper>
    )
}