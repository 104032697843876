import { Checkbox, Dialog, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

import { AgGridReact } from 'ag-grid-react'
import FilterIcon from '../../../../assets/FilterIcon.svg'
import FilterIconOutlined from '../../../../assets/FilterIconOutlined.svg'

export const StyledAgGrid = styled(AgGridReact)`
    .ag-theme-alpine {
        overflow-x: hidden;
    }
    .ag-overlay-loading-wrapper {
        background-color: white;
    }
    .ag-popup-child {
        box-shadow: ${({ theme }) => `2px 2px 10px 1px ${theme.palette.bg.gray}`};
        border-radius: ${({ theme }) => `${theme.spacing(0.5)}`};
    }

    .ag-icon-filter {
        background: ${() => `transparent url(${FilterIcon}) center/contain
            no-repeat;`};
        color: transparent;
        font-size: 24px;
        display: none;
    }

    .ag-header-cell-filtered {
        .ag-header-cell-menu-button {
            background: ${() => `transparent url(${FilterIcon}) center/contain no-repeat`};
        }
    }

    .ag-header-cell-menu-button {
        background: ${() => `transparent url(${FilterIconOutlined}) center/contain
            no-repeat;`};
        color: transparent;
        font-size: 24px;
        display: block !important;
        opacity: 1 !important;
        cursor: pointer;
    }

    .ag-root-wrapper {
        width: 100% !important;
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
    }
    .ag-header {
        height: auto;
    }
    .ag-header-container,
    .ag-header-row-column {
        background-color: #f6f7fb;
        color: #5c6e8c;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-align: left;
    }
    .ag-header-cell {
        border-right: 1px solid #edeff3;
        padding: ${(props) => props.theme.spacing(0, 1.5)};

        &:first-child {
            padding: 0;
        }

        &:last-child {
            border-right: none;
        }
    }

    .ag-header-cell-label {
        justify-content: flex-start;
    }

    .ag-row {
        /* border-bottom: 0.5px solid #dadee5; */
        font-size: 14px;
    }
    .ag-row-total {
        border: none;
    }
    .ag-row-total .ag-cell {
        background-color: #f8f9fb;
        font-weight: 500;
        cursor: default !important;
    }
    .ag-cell {
        /* padding: 0; */
        text-align: left;
        justify-content: flex-start;
    }

    .ag-cell-editable:hover {
    }
    .ag-row:not(.ag-row-total) .ag-cell {
        outline: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
    }

    /* .ag-cell-px {
        padding-inline: ${(props) => props.theme.spacing(1)};
    } */

    .ag-row:not(.ag-row-total) .ag-cell-editable:hover {
        cursor: pointer;
        box-shadow: ${({ theme }) => `inset 0px -1px 0px 0px #1976d2`};
    }

    .ag-cell input {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .ag-cell-wrapper {
        width: inherit;
    }

    .ag-input-field-input {
        text-align: center;
    }
    .ag-cell-not-inline-editing {
        background: white;
    }
    .ag-cell-inline-editing {
        overflow: inherit;
    }
    .ag-cell-focus.ag-cell-inline-editing {
        box-shadow: inset 0px -2px 0px 0px #1976d2 !important;
    }
    .ag-cell-left,
    .ag-cell-left * {
        justify-content: flex-start !important;
        text-align: left !important;
    }

    .ag-cell-left {
        padding: 6px 12px !important;
    }

    .ag-cell-no-padding {
        padding: 0;
        .ag-cell-value {
            padding: 0;
        }
    }

    .ag-cell-right,
    .ag-cell-right * {
        justify-content: flex-end !important;
        text-align: right !important;
    }
    .ag-cell-row-group .ag-cell {
        /* background: ${(props) => props.theme.palette.bg.shades}; */
        font-size: 16px;
        color: ${(props) => props.theme.palette.primary.main};
    }

    .ag-total-row-cell {
        pointer-events: none;
        display: none;
    }
    .ag-pinned-left-cols-container > .ag-row > .ag-cell {
        border-right: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
    }

    .ag-row .ag-cell-no-borders {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }

    .ag-cell-first-column {
        padding-left: 40px;
    }

    /* .ag-cell-test {
        padding: 6px 12px !important;
    } */
`

export const NumberHeaderCellContainer = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
})`
    width: 100%;
`

export const NumberCellCheckbox = styled(Checkbox)`
    color: rgba(43, 54, 72, 0.12);

    &.Mui-checked {
        color: #0044b4;
    }
`

export const NumberHeaderCellText = styled(Typography)<{ isHeader: boolean }>`
    color: #5c6e8c;
    font-weight: ${(props) => (props.isHeader ? '500' : '400')};
    font-size: 14px;
    line-height: 130%;
    text-align: left;
    padding-inline: 6px;
    letter-spacing: ${(props) => (props.isHeader ? '0.03em' : '0')};
    text-transform: ${(props) => (props.isHeader ? 'uppercase' : 'none')};
    flex: 1;
`

export const DialogWindow = styled(Dialog)`
    .MuiDialog-container {
        .MuiPaper-root {
            width: 100%;
            max-width: 880px;
            border: 1px solid #fff;
            border-radius: 10px;
            padding: 20px;
            min-height: 295px;
            margin: 0;
        }
    }
`

export const Container = styled(Stack).attrs({
    direction: 'column',
    width: '100%',
    gap: 5,
})``

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    font-weight: 500;
    font-size: 16px;
    color: #0044b4;
`

export const WorkNameContainer = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
})`
    background: #f6f7fb;
    border: 1px solid rgba(43, 54, 72, 0.12);
    width: '100%';
    min-height: 40px;
    padding: 5px 8px;
    border-radius: 8px;
`

export const WorkName = styled(Typography).attrs({
    variant: 'h4',
})`
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.02em;
    color: #2b3648;
`

const ResetButton = styled('button')`
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
`

export const AddLinkButton = styled(ResetButton)`
    display: inline-flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #0044b4;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.7;
    }
`

export const PlusIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3333 6.76186H6.76186V11.3333H5.23805V6.76186H0.666626V5.23805H5.23805V0.666626H6.76186V5.23805H11.3333V6.76186Z"
                fill="#0044B4"
            />
        </svg>
    )
}

export const BaseButton = styled(ResetButton)`
    font-weight: 500;
    min-height: 40px;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.7;
    }
`

export const RemoveButton = styled(BaseButton)`
    color: #f46b6b;
`
export const CloseButton = styled(BaseButton)`
    color: #0044b4;
`

export const BaseCellText = styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #5c6e8c;
`

export const CellWithPlaceHolder = styled(Typography)`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #9aa2b0;
`
