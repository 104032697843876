import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { IStyledSpreadBtnParams } from './SpreadPlanBtn.def'

export const StyledSpreadBtn = styled(Button)<IStyledSpreadBtnParams>`
    display: flex;
    align-items: center;
    background-color: ${({ active }) => (active ? '#4caf50' : 'gray')};
    position: absolute;
    right: 0;
    bottom: 1px;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    font-weight: 400;
    color: white;
    border-radius: 0px !important;

    ${({ active }) =>
        active
            ? css`
                  &:hover {
                      background-color: #16a34a;
                      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
                  }
              `
            : css`
                  &:hover {
                      background-color: gray;
                      cursor: default;
                  }
              `}
`

StyledSpreadBtn.defaultProps = {
    disableTouchRipple: true,
    disableRipple: true,
    disableFocusRipple: true,
}
