import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'
import { Checkbox, Switch } from '@mui/material'
import { ColDef, ColGroupDef } from 'ag-grid-community'
import { useState } from 'react'
import { FlexColumnWrapper, FlexRowWrapper } from '../../../pages/NewExecutorView/components/components.styles'
import { extractColIds } from '../../../shared/agGrid.utils'
import { isColGroupDef } from '../../../shared/typeGuards'
import {
    IStyledColumnSettingsRowProps,
    TIsColumnState,
    TSetAgGridColumnState,
    TSetColumnState,
} from './StyledColumnSettingsRow.def'
import { StyledRowWrapper } from './StyledColumnSettingsRow.styles'

export function StyledColumnSettingsRow({
    colDef,
    BoxProps,
    AgGrid,
    setParentRefresh,
    setIsFormChanged,
}: IStyledColumnSettingsRowProps) {
    const [_, setRefresh] = useState(false)

    const isGroup = isColGroupDef(colDef)

    const setColumnState: TSetColumnState = (setAgGridColumnState) => (_, checked) => {
        setIsFormChanged(() => true)
        setAgGridColumnState(extractColIds(colDef), checked)
        setParentRefresh && setParentRefresh((prevState) => !prevState)
        setRefresh((prevState) => !prevState)
    }

    const setColumnVisible: TSetAgGridColumnState = (keys, visible) =>
        AgGrid?.columnApi?.setColumnsVisible(keys, visible)
    const setColumnPinned: TSetAgGridColumnState = (keys, pinned) => AgGrid?.columnApi?.setColumnsPinned(keys, pinned)

    const isVisible: TIsColumnState = (colDef) => AgGrid?.columnApi?.getColumn(colDef.colId)?.isVisible() === true
    const isPinned: TIsColumnState = (colDef) => AgGrid?.columnApi?.getColumn(colDef.colId)?.isPinned() === true

    const checkGroup = (colDef: ColGroupDef, checkFn: TIsColumnState) =>
        Boolean(colDef.children?.find((childColDef) => checkFn(childColDef as ColDef)))

    const isChecked = (checkFn: TIsColumnState) =>
        isGroup ? checkGroup(colDef as ColGroupDef, checkFn) : checkFn(colDef as ColDef)

    return (
        <FlexColumnWrapper p={0} width="100%" {...BoxProps}>
            <StyledRowWrapper>
                <Switch size="small" checked={isChecked(isVisible)} onChange={setColumnState(setColumnVisible)} />
                {colDef.headerName}

                <FlexRowWrapper ml={'auto'}>
                    <Checkbox
                        disableRipple
                        checked={isChecked(isPinned)}
                        onChange={setColumnState(setColumnPinned)}
                        sx={{ p: 0 }}
                        icon={<LockOpenOutlined />}
                        checkedIcon={<LockOutlined />}
                    />
                </FlexRowWrapper>
            </StyledRowWrapper>
            {(colDef as ColGroupDef)?.children?.map((childColDef) => {
                return (
                    <StyledColumnSettingsRow
                        setIsFormChanged={setIsFormChanged}
                        key={(childColDef as ColDef).colId}
                        setParentRefresh={setRefresh}
                        parentColDef={colDef}
                        colDef={childColDef}
                        BoxProps={{ pl: 2 }}
                        AgGrid={AgGrid}
                    />
                )
            })}
        </FlexColumnWrapper>
    )
}
