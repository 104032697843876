import s from '../AddLevelKsg.module.scss'
import { TextField } from '@mui/material'
import { FormErrorAg } from '../AddLevel.service'
import React from 'react'

export default function WideTextLineEl({
    errorTitle,
    touched,
    title,
    required,
    ...props
}: {
    errorTitle?: string
    touched?: boolean
    title: string
    required?: boolean
}) {
    return (
        <div className={s.wide_input_line}>
            <p>
                {title}
                {required && (
                    <>
                        <span>*</span>
                    </>
                )}
                :
            </p>
            <div>
                <TextField
                    InputLabelProps={{ shrink: false }}
                    placeholder="Введите текст"
                    fullWidth
                    sx={{
                        backgroundColor: '#F6F7FB',
                        '.MuiOutlinedInput-root': {
                            padding: '0.7rem',
                            fontSize: 14,
                        },
                    }}
                    error={!!errorTitle?.length}
                    {...props}
                    rows={2}
                    multiline
                />
                <FormErrorAg isTouched={touched} error={errorTitle} />
            </div>
        </div>
    )
}
